import { Directive, ElementRef, Input } from '@angular/core';

import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

type MsgType = {
  [prop: string]: string;
};

const CLASS_NAME_MSG = 'inner-msg-error';

@Directive({
  selector: '[innerMsg]',
})
export class InnerMsgDirective {
  private destroy$ = new Subject<void>();

  @Input()
  innerMsg!: MsgType;

  @Input()
  singleKey: boolean = true;

  @Input()
  checkedBox?: number;

  constructor(private el: ElementRef, private ngControl: NgControl) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    if (!this.ngControl) {
      throw new Error('Not Found NgControl');
    }

    this.ngControl.valueChanges?.subscribe((_) => {
      this.detectRenderMsg();
    });

    (this.el.nativeElement as HTMLElement).addEventListener('blur', () => {
      this.detectRenderMsg();
    });
  }

  detectRenderMsg() {
    const isExistInnerMsg = (
      this.el.nativeElement as HTMLElement
    ).parentNode?.parentNode?.querySelectorAll(`.${CLASS_NAME_MSG}`);
    if (isExistInnerMsg) {
      this.removeRenderMsg();
    }

    if (this.ngControl.invalid && (this.ngControl.dirty || this.ngControl.touched)) {
      if (!!this.ngControl.errors) {
        const keysErrors = Object.keys(this.ngControl.errors);
        if (keysErrors.length === 0) {
          return;
        }
        // Có lỗi - Bài này mặc định là single key
        if (this.singleKey) {
          const msg = this.innerMsg[keysErrors[0]] ?? keysErrors[0];
          this.createElementByMsg(msg);
        } else {
          // Chưa hiểu lắm
        }
      }
    }
  }
  createElementByMsg(msg: string) {
    const div = document.createElement('div');
    div.classList.add(CLASS_NAME_MSG);
    div.innerHTML = `
      <span>${msg}</span>
    `;
    (
      this.el.nativeElement as HTMLElement
    ).parentElement?.parentElement?.appendChild(div);
  }

  removeRenderMsg() {
    const parent = (this.el.nativeElement as HTMLElement)?.parentElement
      ?.parentElement;
    const elMsg = parent?.getElementsByClassName(CLASS_NAME_MSG);
    if (elMsg && elMsg.length > 0) {
      parent?.removeChild(elMsg.item(0) as Element);
    }
  }
}
