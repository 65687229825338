import {
  BehaviorSubject,
  Observable,
  map,
  Subject,
  of,
  debounceTime,
  tap,
  take,
} from 'rxjs';

import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PermissionGroup } from '../models/permissionGroup.model';
import { AppConstant } from '../utilities/app.constant';
import { WebSocketService } from './web-socket.service';
import { typeUser } from '../enum';
import { AppHelper } from '../utilities/app.helper';
import { UserPermission } from '../interface/userPermissions';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService extends BaseService {
  constructor(httpClient: HttpClient, private _io: WebSocketService) {
    super(httpClient);
  }

  // Storage User data;
  public userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userSubject$ = this.userSubject.asObservable();

  private userPermissions: UserPermission[] = [];

  public setUserInfor(userInfo: User) {
    this.setUserPermissions(userInfo.permissionList);
    this.userSubject.next(userInfo);
  }

  private _refreshRequired = new Subject<void>();

  // Setting status of loading component When have any request is created
  private showUserPanel: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  isShowUserPanel = this.showUserPanel.asObservable();

  changeShowUserPanelStatus(status: boolean) {
    this.showUserPanel.next(status);
  }

  // Setting status if we update data in User Panel and in Team Management
  // We need request to reload page.
  public requestReloadUserManagement: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  reloadUserManagement$ = new Subject<boolean>();

  public requestReloadUserPermission: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  reloadUserPermission$ = new Subject<boolean>();

  public getUserInfoInitiate(userId?: string): Observable<any> {
    const uri = `user/${userId}`;
    return this.get<any>(uri).pipe(
      map((item) => {
        return item.data;
      })
    );
  }

  public getUserInfoSubject(): any {
    if (this.userSubject.getValue()) return this.userSubject.getValue();
    return {};
  }

  public getUserInfoById(userId?: string): any {
    const uri = `user/${userId}`;
    return this.get<any>(uri);
  }

  public getUsers(): Observable<any> {
    const uri = `user`;
    return this.get<any>(uri);
  }

  public checkPermissionWithRig(message: string, rig: any): Observable<any> {
    return this.userSubject.pipe(
      take(1),
      map((info) => {
        const currentUser = info;
        let response = false;

        if (message?.includes('There is no')) {
          return response;
        }

        if (rig) {
          const listRemoteAvailable = rig?.remoteCenterList;
          const listmailboxAvailable = rig?.mailboxList;

          let allowRemote = false;
          let allowMailbox = false;

          listRemoteAvailable?.some((remoteCenter: any) => {
            allowRemote = currentUser?.remoteCenterList?.some(
              (userRemoteCenter: any) => userRemoteCenter?.remoteCenterId === remoteCenter.remoteCenterId
            );
            if (allowRemote) return true;
          });
          listmailboxAvailable?.some((mailbox: any) => {
            allowMailbox = currentUser?.mailboxList?.some(
              (userMailbox: any) => userMailbox?.mailboxId === mailbox.mailboxId
            );
            if (allowMailbox) return true;
          });

          switch (currentUser?.role) {
            case typeUser.support:
              response = true;
              break;
            case typeUser.admin:
              response = true;
              break;
            case typeUser.manager:
              if (allowRemote) {
                response = true;
              }
              break;
            case typeUser.viewer:
              response = true;
              break;
            case typeUser.engineer:
              if (allowRemote && allowMailbox) {
                response = true;
              }
              break;
          }
        }
        return response;
      })
    );
  }

  getUsersByStringEDM(
    searchString: string,
    timeDebounce: number
  ): Observable<any[]> {
    const uri = `user`;

    searchString = searchString && searchString.trim();

    return this.get<any>(uri).pipe(
      debounceTime(timeDebounce),
      map((item) => {
        return item.data;
      }),
      // tap((d) =>
      map((item) => {
        if (!searchString) {
          return item;
        }
        // Filter by String.
        searchString = searchString.replace(
          /([!@#$%^&*()+=\[\]\\';,./{}|":<>?~_-])/g,
          '\\$1'
        );

        const regex = new RegExp(searchString, 'i');

        return item.filter((i: any) => {
          return regex.test(i.displayName) || regex.test(i.mail);
        });
      })
      // tap((d) =>
    );
  }

  getAllUsers(): Observable<any[]> {
    const uri = `user/all`;
    return this.get<any>(uri).pipe(
      map((response: any) => {
        if (response?.data && response.data.length > 0)
          return response.data
        else return [];
      })
    );
  }

  getUsersByHalID(HalId: string | object): Observable<any[]> {
    if (typeof HalId === 'object') {
      HalId as any;
      let queryString = (HalId as any).HalId;
      // const uri = `user/hal-id/${queryString}`;
      const uri = `user/query/select?queryString=${queryString}`;

      return this.get<any>(uri).pipe();
    } else {
      if (HalId.charAt(0) === ' ') {
        return of([]);
      } else {
        if (HalId !== '') HalId = HalId.trim();
        let queryString = HalId;
        // const uri = `user/hal-id/${queryString}`;
        const uri = `user/query/select?queryString=${queryString}`;

        return this.get<any>(uri).pipe();
      }
    }
  }

  getDiscipline(): Observable<any> {
    const uri = `discipline`;
    return this.get<any>(uri).pipe();
  }

  getRemoteCenter(): Observable<any> {
    const uri = `remote-center`;
    return this.get<any>(uri).pipe();
  }

  getMailBox(): any {
    const uri = `mailbox`;
    return this.get<any>(uri).pipe();
  }

  getDisciplineById(disciplineId: string | null): any {
    let uri = '';
    switch (disciplineId) {
      case null:
        return of([]);
      case AppConstant.ROLES.VIEWER.label:
        uri = `remote-center`;
        return this.get<any>(uri).pipe();
      case AppConstant.ROLES.ADMIN.label:
        return of([]);
      case AppConstant.ROLES.SUPPORT.value.data:
        return of([]);
      case AppConstant.ROLES.MANAGER.label:
        uri = `remote-center`;
        return this.get<any>(uri).pipe();
      default:
        uri = `discipline/${disciplineId}`;
        return this.get<any>(uri).pipe();
    }
  }

  getMailBoxByQuery(disciplineId: string, remoteCenter?: string[]): any {
    let params:any = {};
    disciplineId && (params.disciplineId = disciplineId)
    remoteCenter?.length &&  (params.remoteCenter = remoteCenter)
    let uri = `mailbox/query/select`;
    return this.get<any>(uri, {
      params
    }).pipe();
  }

  addUser(userId: string, payload: any): Observable<any> {
    let uri = `user/add/${userId}`;
    AppHelper.UtileFunctions.encode64Payload(payload, [
      'firstName',
      'lastName',
    ]);

    return this.put<any, any>(uri, payload);
  }

  editUser(userId: string, payload: any): any {
    let uri = `user/edit/${userId}`;
    AppHelper.UtileFunctions.encode64Payload(payload, [
      'firstName',
      'lastName',
    ]);

    return this.put<any, any>(uri, payload).pipe(
      tap((res) => {
        if (res?.data?.id) {
          this._io.emit("update-user-info", res);
          this._refreshRequired.next();
        }
      })
    );
  }

  
  changeRoleTester(payload: any): any {
    const uri = `user/change-role`;

    return this.patch<any, any>(uri, payload).pipe(
      tap((res) => {
        this._refreshRequired.next();
      })
    );
  }


  updatePermission(userId: string, permissionPayload: PermissionGroup) {
    let uri = `user/update-permission-list/${userId}`;
    return this.put<any, any>(uri, permissionPayload).pipe(
      tap((res) => {
        // Add permissions change signal
        this._io.emit('update-user-info', res);
        this._refreshRequired.next();
      })
    );
  }

  removeUser(userId: string): any {
    let uri = `user/delete/${userId}`;
    return this.delete<any>(uri).pipe(
      tap((res) => {
        if (res.message === 'Delete user successfully!') {
          res.isDelete = true;
          res.data = { id: userId };
        }
        this._io.emit('update-user-info', res);
      })
    );
  }

  getDefaultPermission(role: string): any {
    const uri = `permission/default?role=${role}`;
    return this.get<any>(uri).pipe();
  }

  apiAuthGuardAdminOrManager(userId?: string): Observable<boolean> {
    const uri = `user/${userId}`;
    return this.get<any>(uri).pipe(
      map(
        (res) => {
          return res.data.role !== AppConstant.ROLES.ENGINEER.label
            ? true
            : false;
        },
        (error: any) => false
      )
    );
  }

  apiAuthGuardAdmin(userId?: string): Observable<boolean> {
    const uri = `user/${userId}`;
    return this.get<any>(uri).pipe(
      map(
        (res) => {
          return [AppConstant.ROLES.ADMIN.label, AppConstant.ROLES.VIEWER.label].includes(res.data.role)
        },
        (error: any) => false
      )
    );
  }

  apiAuthGuardSupport(userId?: string): Observable<boolean> {
    const uri = `user/${userId}`;
    return this.get<any>(uri).pipe(
      map(
        (res) => {
          return [AppConstant.ROLES.SUPPORT.value.data, AppConstant.ROLES.ADMIN.label].includes(res.data.role)
        },
        (error: any) => false
      )
    );
  }
  updateUserProfile(payload: any): any {
    let uri = `user/update-profile`;

    const formData = new FormData();
    formData.append('phone', payload.phone);
    formData.append('country', payload.country);
    formData.append('timezone', payload.timezone);

    if (!payload.avatar) {
      formData.append('isRmAvatar', 'hasValue');
    } else if (payload.avatar && typeof payload.avatar === 'object') {
      const fileName = AppHelper.UtileFunctions.encodeFileName(payload.avatar.name);
      formData.append('userAvatar', payload.avatar, fileName);
    }

    return this.put<any, any>(uri, formData);
  }

  updateSetting(payload: {
    isNotiTeamsChat: boolean,
    isNotiVideo: boolean,
    isNotiAlert: boolean,
  }): any {
    const uri = `user/update-setting`;
    return this.patch<any, any>(uri, payload).pipe();
  }

  // Function to update the user permissions and notify subscribers
  setUserPermissions(permissions: UserPermission[]): void {
    this.userPermissions = permissions;
  }

  // Function to check if a user has permission for a specific action within a module
  hasPermission(moduleName: string, action: keyof UserPermission): boolean {
    if (this.userPermissions.length) {
      return this.userPermissions.some(
        (permission) =>
          permission.moduleName === moduleName && permission[action]
      );
    }
    return false;
  }
}
