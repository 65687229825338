<div *ngIf="!isTyping" class="message-container" [ngClass]="{'seft': isMine, 'friend': !isMine}">
  <ng-template #defaultTemplate>
    <div *ngIf="!isMine" class="avatar">
      <app-avatar
        [avatar]="message.avatar"
        [displayName]="message.userName"
        [isChatBotAvatar]="message.senderType === 'bot'"
        alt="Avatar"
        [ngClass]="{'hideAvatar': previousMessage?.userId == message.userId && previousMessage?.senderType == message.senderType}"></app-avatar>    
    </div>
    <div class="d-flex flex-column gap-1">
      <div class="d-flex" *ngIf="previousMessage?.senderType !== message.senderType"
        [ngClass]="{'justify-content-end': isMine}"
      >
        <div class="me-2 user-name">
          {{ message.userName }}
        </div>
        <div *ngIf="!isMine" class="sub-text">
          {{ message.title || '' }}
        </div>
      </div>
      <div *ngIf="message.type == 'text'"  class="message-bubble" [ngClass]="{'seft': isMine}">
        <div class="mb-1 wrap-text content">
          {{ message.content }}
        </div>
        <div class="d-flex justify-content-end sub-text">
          <app-chat-message-time [message]="message"></app-chat-message-time>
        </div>
      </div>
      
      <!-- type Attachment -->
      <ng-container *ngIf="listFileAnother.length">
        <div *ngFor="let item of listFileAnother" class="mb-1 wrap-text d-flex flex-row cursor-pointer" (click)='openFile(item)' >
          <div class="file-type text-center">{{item.type}}</div> 
          <div class="file-name text-center" [pTooltip]="item.name" tooltipPosition="top">{{item.name}} </div>
          <div class="text-center">
            <span class="time">
              <app-chat-message-time [message]="message"></app-chat-message-time>
            </span>
          </div>
        </div>
      </ng-container>
      <!-- type Imnage -->
      <ng-container *ngIf="listBlobImg.length">
        <div *ngFor="let item of listBlobImg" class="d-flex">
          <div class="wrapper-image">
            <div class="text-center download" (click)='downloadImage(item.url, item.name)'>
              <i class="pi pi-cloud-download cursor-pointer text-center download"></i>
            </div>
            <p-image 
              class="mb-1 image-attachment"
              [src]="item.url | safe : 'url'"
              [preview]="true"
            ></p-image>
            <div class="text-center image-time time">
              <app-chat-message-time [forImage]="true" [message]="message"></app-chat-message-time>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Loading -->
      <div class="content-progress-bar-notification" *ngIf="(isLoadingAttachment) || false">
        <span class="loader"></span>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="template === 'LOADING'; else defaultTemplate">
    <!-- Loading -->
    <div class="content-progress-bar-notification">
      <span class="loader"></span>
    </div>
  </ng-container>
</div>
