import { Component, Input, OnInit } from '@angular/core';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-chat-message-time',
  templateUrl: './chat-message-time.component.html',
  styleUrls: ['./chat-message-time.component.scss']
})
export class ChatMessageTimeComponent implements OnInit {
  @Input() message: AppConstant.ExtendedMessage;
  @Input() forImage: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}
