import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';

@Component({
  selector: 'app-chat-message-text',
  templateUrl: './chat-message-text.component.html',
  styleUrls: ['./chat-message-text.component.scss']
})
export class ChatMessageTextComponent implements OnInit {
  @Input() message: AppConstant.Message;
  @Input() previousMessage: AppConstant.Message | undefined;

  @Input() isMine: boolean;

  @Input() isTyping: boolean = false;
  @Input() template: 'CONTENT' | 'LOADING' = 'CONTENT';

  listBlobImg: any[] = [];
  listFileAnother: any[] = [];
  isLoadingAttachment: boolean = false;
  
  constructor(
    private _blobService: AzureBlobService,
    private _notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    if (this.message.attachments?.length) {
      let url: Observable<any>[] = [];
      this.listBlobImg = [];
      this.listFileAnother = [];
      let listNameImgTemp: string[] = []

      this.message.attachments.forEach((file: any) => {
        const fileType = (file.name.split('.')[1] as string).toLowerCase();
        const typeAllow = ['jpg', 'jpeg', 'png'];

        if (typeAllow.includes(fileType)) {
          url.push(this._blobService.getFileByUrl(file.url));
          listNameImgTemp.push(file.name);
          this.isLoadingAttachment = true;
        } else 
          this.listFileAnother.push({
            type: fileType,
            name: AppHelper.UtileFunctions.decodeFileNameFromAzureUrl(file.name),
            url: file.url
          })
      });

      forkJoin(url).subscribe((response: any[]) => {
        response.forEach((blob: any, index: number) => {
          this.listBlobImg.push({
            url: URL.createObjectURL(blob),
            name: listNameImgTemp[index]
          });
        });
        this.isLoadingAttachment = false;
      })
    }
  }

  openFile(file: any) { 
    if (file?.url) {
      this._blobService.getFileByUrl(file.url).subscribe({ 
        next: (blob: Blob) => {
          AppHelper.UtileFunctions.clickDownloadDocumentWithName(
            blob, file.name
          );
        },
        error: (error) => {
          console.error('Error: ' + error);
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Loading Document',
            content: 'This document is no longer available, please try again',
          });
        },
      });
    } else {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Read Document',
        content: "Can't find the document in the database",
      });
    }
  }

  downloadImage(url: any, fileName: string) {
    fetch((url)).then(response => response.blob()).then((blob: any) => {
      AppHelper.UtileFunctions.clickDownloadDocumentWithName(
        blob, fileName
      );
    })
  }
}
