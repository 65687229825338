<div class="avatar" [style.width]="size" [style.height]="size">

    <ng-container *ngIf="isChatBotAvatar">
        <div class="icon-chatbot">
            <svg-icon key="chatbot" class="svg-icon-chatbot" size="lg"></svg-icon>
        </div>
    </ng-container>

    <ng-container *ngIf="avatar && !isChatBotAvatar">
        <img appBlobImage [imageUrl]="avatar" class="avatar-img" />
    </ng-container>

    <div *ngIf="!avatar && !isChatBotAvatar" class="avatar-random" [attr.data-label]="getFirstLetter(displayName)"
        [appRandomAvatar]="getFirstLetter(displayName)"></div>
</div>