import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, filter, map, takeUntil } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from 'src/app/shared/services/base.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { find } from 'lodash';
import { WebSocketService, WS_MESSAGE_TYPE } from 'src/app/shared/services/web-socket.service';
import { Ticket } from './live-support-item/live-support-item.component';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LiveSupportService extends BaseService {
  private numberSupportCasesUnread: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private supportCaseSelect: BehaviorSubject<Ticket | null> = new BehaviorSubject<Ticket | null>(null);

  public numberSupportCasesUnread$ = this.numberSupportCasesUnread.asObservable();
  public supportCaseSelect$ = this.supportCaseSelect.asObservable();

  public baseUrl = 'live-support';
  private listSupportCaseUnread: any[] = [];
  private currentUser: any;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    httpClient: HttpClient,
    private _io: WebSocketService,
    private _userInfoService: UserInfoService,
    private _router: Router,
  ) {
    super(httpClient);

    this._userInfoService.userSubject$.pipe(
      filter((userObject: any) => !!userObject),
      filter((userObject: any) => userObject?.id != (this.currentUser?.id)),
      takeUntil(this.destroy$)
    ).subscribe((userObject: any) => {
      this.currentUser = userObject;
      if ([AppConstant.ROLES.ADMIN.value.data, AppConstant.ROLES.SUPPORT.value.data].includes(userObject.role)) {
        this.listenSocket(userObject);
        this.getCountTicketUnread().subscribe((data) => {
          this.setlistSupportCasesUnread(data?.data);
        })
      }
    });

    this._router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    ).subscribe((url: string) => {
      if (!url.includes('/live-support'))
        this.setSupportCaseSelect(null);
    });
  }

  listenSocket(userData: any) {
      this._io.listen('live-support-admin').subscribe((event: any) => {
        const { type, data } = event;
        if (data?.senderType == 'system') return;

        switch (type) {
          case (WS_MESSAGE_TYPE.LIVE_SUPPORT_AGENT__NEW_MESSAGE):
            if (data && this.supportCaseSelect.value?.id != data.supportCaseId
              && data?.senderType == "user"
            ) {
              this.increaselistSupportCasesUnread({ supportCaseId: data.supportCaseId })
            }
            break;
          case (WS_MESSAGE_TYPE.LIVE_SUPPORT_AGENT__NEW_SUPPORT_CASE):
            this.increaselistSupportCasesUnread({ supportCaseId: data.id });
            break;
          case (WS_MESSAGE_TYPE.LIVE_SUPPORT_AGENT__READ_ALL):
            this.decreaselistSupportCasesUnread(data.supportCaseId)
            break;
        }
      });
    
  }

  setSupportCaseSelect(supportCase: Ticket | null = null) {
    this.supportCaseSelect.next(supportCase);
  }

  getCountTicketUnread(): Observable<any> {
    const url = `${this.baseUrl}/support-cases/total-unread`;

    return this.get<any[]>(url).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }

  setlistSupportCasesUnread(data: any[]) {
    this.listSupportCaseUnread = data;
    this.numberSupportCasesUnread.next(this.listSupportCaseUnread.length);
  }

  increaselistSupportCasesUnread(newNoti: any) {
    if (find(this.listSupportCaseUnread, e => e?.id == newNoti?.supportCaseId)) return;

    this.listSupportCaseUnread.push({ id: newNoti?.supportCaseId })
    this.numberSupportCasesUnread.next(this.listSupportCaseUnread.length);
  }

  decreaselistSupportCasesUnread(supportCaseId: string) {
    this.listSupportCaseUnread = this.listSupportCaseUnread.filter((e: any) => e?.id != supportCaseId);
    this.numberSupportCasesUnread.next(this.listSupportCaseUnread.length);
  }

  getData(endpoint: string, params: { [key: string]: any }, method: 'GET' | 'POST' = 'GET', body: any = null): any { // Observable<any>
    let url = endpoint;
    let httpParams = new HttpParams();

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.append(key, params[key]);
      }
    }

    if (method === 'GET') {
      return this.get<any[]>(url, { params: httpParams }).pipe(
        map((res: any) => {
          return res!.data;
        })
      );
    } 
    // feel not used => temporarily comment this code
    // else if (method === 'POST') {
    //   return this.post(url, body, { params: httpParams });
    // }
  }

  public updateStatusTicket(ticketId: number, status: number): Observable<any[]> {
    const url = `${this.baseUrl}/support-cases/${ticketId}/update-status/`;
    const payload = { status: status };

    return this.patch<any, any>(url, payload).pipe(
      map((res: any) => {
        return res?.data;
      })
    );
  }

  public getSupportCaseMessages(ticketId: number, take: number, skip: number): Observable<any[]> {
    return this.get<any[]>(`${this.baseUrl}/support-cases/${ticketId}/messages`, {
      params: {
        take,
        skip,
      }
    }).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }

  public sendMessage(supportCaseId: number, message: Partial<AppConstant.Message>): Observable<any> {
    const payload = new FormData();
    payload.append('content', AppHelper.UtileFunctions.encodeBase64(message.content || ''));
    payload.append('type', message?.type || '');
    payload.append('senderType', message.senderType || '');

    if (message?.attachments)
      message?.attachments.forEach((file: File) => {
        const fileName =  AppHelper.UtileFunctions.encodeFileName(file.name)
        payload.append('supportCaseAttachments', file, fileName);
      });

    return this.post<any, any>(`${this.baseUrl}/support-cases/${supportCaseId}/messages`, payload).pipe(
      map((res: any) => {
        return res!.data;
      })
    )
  }

  public getUnreadSupportCaseMessages(ticketId: number): Observable<any> {
    return this.get<any[]>(`${this.baseUrl}/support-cases/${ticketId}/messages/unread`).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }

  public readAllSupportCaseMessages(ticketId: number): Observable<any[]> {
    return this.patch<any, any>(`${this.baseUrl}/support-cases/${ticketId}/messages/read-all`).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }

  public readLiveMessage(ticketId: number, messageId: number): Observable<any[]> {
    return this.patch<any, any>(`${this.baseUrl}/support-cases/${ticketId}/messages/${messageId}/read`).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }

  public addEvent(supportCaseId: number, eventType: AppConstant.SupportCaseEvents): Observable<any> {
    return this.post<any, any>(`${this.baseUrl}/support-cases/${supportCaseId}/events`, {
      type: eventType,
    }).pipe(
      map((res: any) => {
        return res!.data;
      })
    )
  }

  public resolveSupportCase(supportCaseId: number): Observable<any[]> {
    return this.post<any, any>(`${this.baseUrl}/support-cases/${supportCaseId}/update-status`, {
      status: AppConstant.SupportCaseStatuses.RESOLVED,
    }).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }

  public toggleSupportCaseChatbot(supportCaseId: number, enabled: boolean): Observable<any> {
    return this.post<any, any>(`${this.baseUrl}/support-cases/${supportCaseId}/toggle-chatbot`, {
      enabledChatbot: enabled,
    }).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }
}
