import { Component, HostListener, OnInit, Inject, Input, SimpleChanges} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AzureAdService } from '../../services/azure-ad.service';
import { NotificationService } from '../../services/notification.service';
import { UserInfoService } from '../../services/user-info.service';
import { Location } from '@angular/common';
import {
  filter,
  map,
  Observable,
  take,
} from 'rxjs';
import { LoaderLayOut } from '../../services/loaderLayOut.service';
import { WebSocketService } from '../../services/web-socket.service';
import { DialogActionApp, OptionButtonType } from '../../type';
import { IMessage, IConfirmDialog } from '../../interface/common';
import { AppConstant } from '../../utilities/app.constant';
import { BackPortalService } from '../../services/back-portal.service';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { CollapseLayoutService } from '../../services/collapseLayout.service';
import { DistributionService } from '../../services/distribution.service';
import { DistributionTemplate } from '../../enum';
import { AppHelper } from '../../utilities/app.helper';
import { ApplicationInsightsService } from '../../services/telemetry.service';
import { NotificationEventService } from '../../services/notification-event.service';
import { isUndefined } from 'lodash';

const MESSAGE_ALERT_CHANGE_ROLE =
  'Your role & permission have been changed. The application needs to be refreshed!';
const MESSAGE_ALERT_USER_DELETED =
  'You currently do not have enough permissions to access the system, please contact admin.';

const INACTIVITY_TIMEOUT_LIMIT: number = 2 * 3600 * 1000; // 2 hour
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [MessageService],
})
export class LayoutComponent implements OnInit {
  isLoading: boolean = true;

  takeFirstErrordeploy: boolean = false;

  currentPath: string = '';

  displaySidebarUserInfomation: boolean = true;
  loginUser: any;

  isSupporter: boolean = false;
  isAdmin: boolean = false;
  isCollapse = true;
  isLiveSupportPage: boolean = false;

  notifications: IMessage = {
    type: '',
    header: '',
    content: '',
    time: '',
  };

  // displayRequirePermission: boolean = false;
  displayMaintenance: boolean = false;

  isOneWiget = true;

  // Variable for change temple of Toast
  isWarning = false;

  // Define Default Input of Dialog for this Component
  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  expandMenu: boolean = false;
  notificationPanelExpanded = false;

  tutorialPanelExpanded = false;

  @Input() isCompleteSettingToken: boolean = false;

  private inactivityTimeout: any;

  public isCollapse$: Observable<any>;
  public distributionRender$: Observable<any>;
  public distributionData$: Observable<any>;
  public distributionURL$: Observable<any>;

  public render = {
    isShow: false,
    template: DistributionTemplate.uploadYet,
    reuploadStatus: false,
  };
  public data = {
    rigId: '',
    rigJournalId: '',
    rigOperator: '',
    rigProject: '',
    rigName: '',
    language: '',
  };

  public link = {
    distributionUrl: '',
  };

  constructor(
    private _router: Router,
    private _userInfoService: UserInfoService,
    private _notificationService: NotificationService,
    private _messageService: MessageService,
    private _msalService: MsalService,
    public _azureAdService: AzureAdService,
    private _location: Location,
    private _loaderLayOut: LoaderLayOut,
    private _io: WebSocketService,
    private _backPortalService: BackPortalService,
    private _confirmService: ConfirmDialogService,
    private _collapseLayoutService: CollapseLayoutService,
    private _distributionService: DistributionService,
    @Inject(MSAL_GUARD_CONFIG) private _msalGuardConfig: MsalGuardConfiguration,
    private _applicationInsightsService: ApplicationInsightsService,
    private _notificationEventService: NotificationEventService,
  ) {
    this.isCollapse$ = this._collapseLayoutService.isCollapse$;

    this.distributionRender$ = this._distributionService.distributionRender$;
    this.distributionData$ = this._distributionService.distributionData$;
    this.distributionURL$ = this._distributionService.distributionURL$;

    this.distributionRender$.subscribe(({ render }: any) => {
      if (render) {
        if (render.template === DistributionTemplate.uploadYetReload) {
          render.reuploadStatus = AppHelper.MathFunctions.getRandomNumber();
          render.template = DistributionTemplate.uploaded;
        } else {
          render.reuploadStatus = false;
        }
        this.render = render;
      }
    });

    this.distributionData$.subscribe(({ data }: any) => {
      if (data) {
        this.data = data;
      }
    });

    this.distributionURL$.subscribe(({ link }: any) => {
      if (link) {
        this.link = link;
      }
    });
  }

  // Add the HostListener to the component class
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    // Get the window width
    const windowWidth = window.innerWidth;
    // Check the window width against your specific breakpoint
    if (
      windowWidth <= AppConstant.RESOLUTION_BREAKPOINT.HD_PLUS &&
      !(
        this.hasRoute('login') ||
        this.hasRoute('user/team-management') ||
        this.hasRoute('rig/rig-management') ||
        this.hasRoute('mailbox/mailbox-management') ||
        this.hasRoute('help-center') ||
        this.hasRoute('alerts/alerts-management')
      )
    ) {
      // Adjust the value as needed
      this.isCollapse = false; // Change the value Colapse Notification List
    } else {
      this.isCollapse = true; // Change back to default value Colapse Notification List
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isCompleteSettingToken && changes.isCompleteSettingToken.currentValue) {
      this._io.connectSocket();
    }
  }

  ngOnInit() {    
    this._userInfoService.userSubject$.pipe(
      filter((userObject: any) => !!userObject),
      take(1),      
    ).subscribe((userObject: any) => {
      this._io.listen('notification_events/all/new').subscribe((event: any) => {
        this._notificationEventService.decreaseUnreadCount(-1, event);
      });
  
      this._io.listen(`notification_events/${userObject.id}/new`).subscribe((event: any) => {
        this._notificationEventService.decreaseUnreadCount(-1, event);
      });  

      this._io.listen(`update-user-info-${userObject.id}`).subscribe((res: any) => {
        if (res?.isDelete) {
          this._confirmService.setDialog({
            ...this.confirmDialog,
            isVisible: true,
            header: 'Information',
            haveDialogMessage: true,
            dialogMessage: MESSAGE_ALERT_USER_DELETED,
            havePrimaryButton: true,
            primaryButtonLabel: 'Login',
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
            disableSecondaryButton: true,
            buttonEvent: (event: OptionButtonType) =>
              this.onButtonClickDialog(event, 'UserRemove'),
          });
        }

        if (res.isChangeUserRole) {
          this._confirmService.setDialog({
            ...this.confirmDialog,
            isVisible: true,
            header: 'Information',
            haveDialogMessage: true,
            dialogMessage: MESSAGE_ALERT_CHANGE_ROLE,
            havePrimaryButton: true,
            primaryButtonLabel: 'Yes',
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
            disableSecondaryButton: true,
            buttonEvent: (event: OptionButtonType) =>
              this.onButtonClickDialog(event, 'ChangeRole'),
          });
        }
        this._userInfoService.setUserInfor(res.data);
      })

      this._io.listen(`message/${userObject.id}`).subscribe((event: any) => this._io.handleMessage(event));

      if ([AppConstant.ROLES.ADMIN.value.data, AppConstant.ROLES.SUPPORT.value.data].includes(userObject.role)) {        
        this._io.listen('live-support-admin').subscribe((event: any) => this._io.handleMessage(event));
      }

      this.isSupporter = userObject.role === AppConstant.ROLES.SUPPORT.value.data;;
      this.isAdmin = userObject.role === AppConstant.ROLES.ADMIN.value.data;
    })

    this.getCurrentUrl().subscribe((url: string) => {
      this.isLoading = true;
      if (
        this._loaderLayOut.tasksState.InterventionListAPI === true &&
        this._loaderLayOut.tasksState.ProjectListAPI === false &&
        this._loaderLayOut.tasksState.userInfoAPI === true &&
        (this.currentPath === '/home?view=table' ||
          this.currentPath === '/home?view=card') &&
        url === '/home'
      ) {
        this.isLoading = false;
      }

      this.currentPath = url === '/' ? 'home' : url;
      this.isLiveSupportPage = this.currentPath.includes('live-support');

      this._loaderLayOut.loadingStatus$.subscribe((state) => {
        // Trường hợp tracking loading cho home
        // InterventionListAPI
        // ProjectListAPI
        // userInfoAPI

        if (
          (this.currentPath === '/' ||
            this.currentPath === 'home' ||
            this.currentPath === '/home' ||
            this.currentPath === 'home' ||
            this.currentPath === '/home?view=card' ||
            this.currentPath === '/home?view=table') &&
          state.InterventionListAPI === true &&
          state.ProjectListAPI === true &&
          (state.userInfoAPI === true ||
            this._loaderLayOut.tasksState.userInfoAPI === true)
        ) {
          this._loaderLayOut.tasksState.InterventionListAPI = true; // just in case
          this._loaderLayOut.tasksState.ProjectListAPI = false;
          this._loaderLayOut.tasksState.userInfoAPI = true;

          this.isLoading = false;
        } else if (
          (this.currentPath.includes('/home/rig') ||
            this.currentPath.includes('/home/alert-rig')) &&
          state.InterventionListAPI === true &&
          state.userInfoAPI === true
        ) {
          this._loaderLayOut.interventionFormDone$.subscribe((isDone: any) => {
            if (isDone) {
              this.isLoading = false;
              // Send Signal to layout turn off loading from Initial Form Detail
              this._loaderLayOut.setLoadingInterventionDone(false);
            }
          });

          this._loaderLayOut.alertsDetailDone$.subscribe((isDone: any) => {
            if (isDone) {
              this.isLoading = false;
              // Send Signal to layout turn off loading from Alert Detail
              this._loaderLayOut.setLoadingAlertsDetailDone(false);
            }
          });
        } else if (
          this.currentPath !== '/' &&
          this.currentPath !== 'home' &&
          this.currentPath !== '/home' &&
          this.currentPath !== '/home?view=card' &&
          this.currentPath !== '/home?view=table' &&
          !this.currentPath.includes('/home/rig') &&
          !this.currentPath.includes('/home/alert-rig')
        ) {
          this.isLoading = false;
        } else {
        }
      });

      // Intervention
      // Trường hợp tracking loading cho intervention add/edit
      // Trường hợp tracking không phải 2 thằng trên

      if (
        this.currentPath.includes('/home/rig-journal/') ||
        this.currentPath.includes('/home/rig-list/')
      ) {
        this.isLoading = false;
      }

      // Final case to ensure that loading will off
      setTimeout(() => {
        this.isLoading = false;
      }, 5000);

      // Notification on/off
      this._collapseLayoutService.toggleNotificationPanel$.subscribe(flag => this.notificationPanelExpanded = flag);

      // Tutorial on/off
      this._collapseLayoutService.toggleTutorialPanel$.subscribe(flag => this.tutorialPanelExpanded = flag);
    });

    // set display User Panel
    this._userInfoService.isShowUserPanel.subscribe((status) => {
      const currentAccount = this._msalService.instance.getActiveAccount();
      this.loginUser = currentAccount;
      this.displaySidebarUserInfomation = status;
    });

    // Always running to listen and update notifications
    this._notificationService.messageSubjectAction$.subscribe(
      (message: IMessage) => {

        this.notifications = message;
        const statusCode: number = Number(this.notifications.header);
        
        if (message.type === AppConstant.MESSAGE_TYPE.ERROR) {
          this._applicationInsightsService.trackException({ error: new Error(`[Catched error] ${message?.content}`)});
        }

        switch (statusCode) {
          case 401:
            this.showDialogRequiresLogout();  
            break;
          case 503: 
            this.showDialogMaintenance();
            break;
          default:
            this.showMessage(this.notifications);
            break;
        }

        this.isLoading = false;
      }
    );

    // Listen for collapse intervetion log status
    this._backPortalService.collapseInterventionLog$.subscribe(
      (status: boolean) => {
        this.isCollapse = status;
      }
    );
  }

  onDestroy(): void {
    const currentUser = this._userInfoService.userSubject.getValue();
    if (currentUser) {
      this._io.unlisten(`update-user-info-${currentUser?.id}`).subscribe(() => {});
      this._io.unlisten(`notification_events/${currentUser?.id}/new`).subscribe(() => {});
    }
    this._io.unlisten('notification_events/all/new').subscribe(() => {});
  }

  showDialogRequiresLogout() {
    this._confirmService.setDialog({
      ...this.confirmDialog,
      isVisible: true,
      header: 'Information',
      haveDialogMessage: true,
      dialogMessage: MESSAGE_ALERT_USER_DELETED,
      havePrimaryButton: true,
      primaryButtonLabel: 'Logout',
      isValidPrimaryButton: true,
      disablePrimaryButton: false,
      buttonEvent: (event: OptionButtonType) =>
        this.onButtonClickDialog(event, 'UserRemove'),
    });
  }

  showDialogMaintenance() {
    this._confirmService.setDialog({
      ...this.confirmDialog,
      isVisible: true,
      header: 'Information',
      haveDialogMessage: true,
      dialogMessage: 'Server is under maintenance please try again later. Thanks you',
      havePrimaryButton: true,
      primaryButtonLabel: 'Back',
      isValidPrimaryButton: true,
      disablePrimaryButton: false,
      buttonEvent: (event: OptionButtonType) =>
        this.onButtonClickDialog(event, 'Maintance'),
    });


  }

  defaultImage =
    '../../../../assets/images/backgrounds/VRO-backgound-login.png';
  image = '../../../../assets/images/backgrounds/VRO-backgound-login.png';

  hasRoute(route: string) {
    return this._router.url.includes(route);
  }

  changeSizeOfNotification(value: boolean) {
    this.isCollapse = value;
  }

  showMessage(msgPackage: IMessage) {
    this.isWarning = msgPackage.type === 'warning' ? true : false;

    this._messageService.add({
      // sticky: true,
      severity: msgPackage.type,
      summary: msgPackage.header,
      detail: msgPackage.content + '/br' + msgPackage.time,
    });
    this.sendSystemNotification(msgPackage);
  }

  sendSystemNotification(msgPackage: IMessage) {
    if (msgPackage.header === '' || msgPackage.content === '') {
      return;
    }
    this._io.emit(`notification_events/system`, {
      title: msgPackage.header,
      message: msgPackage.content,
      userId: this.loginUser?.localAccountId || 'Unknown User', 
    });
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogActionApp): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'ChangeRole':
            this._confirmService.clearDialog();
            window.location.reload();
            break;
          case 'UserRemove':
            this.logout_Azure();
            break;
          case 'Maintance':
            this._location.back();
            break;
          case 'Login':
            this.login_Azure();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  login_Azure() {
    this._msalService.loginRedirect();
  }

  logout_Azure() {
    const currentAccount = this._msalService.instance.getActiveAccount();

    this._azureAdService.isUserLoggedIn.next(false);
    this._azureAdService.userNameLoggedIn.next(undefined);
    this._msalService.logoutRedirect({
      account: currentAccount,
      postLogoutRedirectUri: environment.logoutUrl,
    });
  }

  expandMenuEvent(event: any) {
    this.expandMenu = event;
  }

  getCurrentUrl(): any {
    return this._router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    );
  }

  @HostListener('document:mousemove', ['$event'])
  onUserActivity(event: Event): void {
    clearTimeout(this.inactivityTimeout);
    this.resetInactivityTimer();
  }

  private resetInactivityTimer(): void {
    this.inactivityTimeout = setTimeout(() => {
      console.log('User is inactive.');
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Information',
        haveDialogMessage: true,
        dialogMessage: 'Session timeout. Please login again!',
        havePrimaryButton: true,
        primaryButtonLabel: 'Login',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Login'),
      });
    }, INACTIVITY_TIMEOUT_LIMIT);
  }
}
