<p-dialog
  [visible]="true"
  [modal]="true"
  [style]="{ 'max-width': '688px', 'line-height': '12px' }"
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="false"
  maskStyleClass="maskStyleDialog"
  styleClass="style-class-dialog"
  [closable]="false"
  [baseZIndex]="0">
  <ng-template pTemplate="header">
    <div class="header">
      <div>Import from EDM</div>
      <div class="custom-close-icon" (click)="handleCancel()">
        <i class="pi pi-times"></i>
      </div>
    </div>    
  </ng-template>

  <ng-template pTemplate="content">
    <div class="content d-flex flex-column gap-2">
      <app-editable-interval-table 
        [header]="'Available Intervals'" 
        [datas]="intervals$ | async"
        [projectUnits]="projectUnits"
        (onItemSelect)="handleIntervalItemSelect($event)"
        (onItemUnselect)="handleIntervalItemUnselect($event)"
        (onAllItemSelect)="handleIntervalAllSelect()"
        (onDataTableChange)="handleIntervalTableChange($event)"
        (onAllItemUnselect)="handleIntervalAllUnselect()"></app-editable-interval-table>
    </div>
  </ng-template>

  <ng-template pTemplate="footer" class="footer">
    <div class="d-flex justify-content-end gap-2">
      <p-button
        [attr.data-cy]="'cancel-button'"
        label="Cancel"
        (click)="handleCancel()"
        class="custom-p-button custom-p-button--secondary"
      ></p-button>
      <p-button
        [attr.data-cy]="'save-button'"
        label="Import"
        [disabled]="(shouldDisableSaveButton$ | async) || false"
        (click)="handleSave()"
        class="custom-p-button custom-p-button--primary"               
      ></p-button>
    </div>
  </ng-template>

  <!-- Loading -->
  <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>  
</p-dialog>