<div [formGroup]="formGroup">
  <div class="multi-level-wrapper">
    <div class="dropdown-label">
      <div *ngIf="isLabel" [class]="{'disabled-label': isDisabled}">
        <span [class]="{'require-mark': isRequired}"> {{ label }} </span>
      </div>
      <span *ngIf="isCounterSelected">
        ({{ counterSelected }}
        {{ counterSelected === 0 || counterSelected === 1 ? "option" : "options" }} selected)
      </span>
    </div>
    <p-treeSelect
      #treeEvent
      [options]="sourceData"
      [placeholder]="placeholder"
      [showClear]="showClear"
      [scrollHeight]="scrollHeight"
      [propagateSelectionDown]="selectAllWhenChooseParent"
      
      (onNodeSelect)="onOptionsSelected($event, seperateMark)"
      (onNodeExpand)="onNodeExpand($event)"
      (onClear)="onOptionsSelected($event, seperateMark)"
      
      [formControlName]="formControlNamePass"

      [ngClass]="multiType"
      [class]="customClass"
      
      >
      <ng-container *ngIf="isCustomTemplate">
        <ng-template pTemplate="value">
          {{ customLabel }}
        </ng-template>
      </ng-container>
    </p-treeSelect>
    
    <div *ngIf="isInvalidControl(formControlNamePass)">
      <ng-container
        *ngFor="
          let msg of getErrorByField(formControlNamePass)
        "
      >
        <div class="inner-msg-error">
          <span>{{ msg }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>