import * as Joi from 'joi';

const operations = ['Drilling', 'Completion', 'Intervention'];
export const IntervalValidator = Joi.object({
  intervalName: Joi.string().required().max(50)
    .pattern(/^[^~@#$%^&*_+={}[\]|\\<>]*$/, { name: 'no-special-characters' })    
    .label('IntervalName').messages({
      'string.pattern.name': '{{#label}} must not contain special characters: ~@#$%^&*_+=}{[]|\\<>',
    }),
  openHoleDiameter: Joi.number().required().min(0).max(999999999.999).label('OpenHoleDiameter')
    .when(Joi.ref('$unit'), {
      is: 'mm',
      then: Joi.number().integer().required().min(0).max(999999999).label('OpenHoleDiameter (mm)'),
    })
    .when(Joi.ref('$unit'), {
      is: 'in',
      then: Joi.number().required().min(0).max(999999999.999).label('OpenHoleDiameter (in)')
        .custom((value, helpers) => {
          if (value.toString().split('.')[1]?.length > 3) {
            return helpers.error('number.maxDecimals', { limit: 3 });
          }
          return value;
        }).messages({
          'number.maxDecimals': '{{#label}} must have at most {{#limit}} decimal places',
        })      
    }),
  operation: Joi.string().required().label('Operation').custom((value: any, helpers: any) => {
    if (!operations.includes(value)) {
      return helpers.error('string.operation');
    }
    return value;
  }),
}).messages({
  'string.operation': 'Invalid operation found'
}).unknown(true);


export const IntervalOpenHoleDiameterValidator = Joi.object({
  openHoleDiameter: Joi.number().required().min(0).max(999999999.999).label('OpenHoleDiameter')
    .when(Joi.ref('$unit'), {
      is: 'mm',
      then: Joi.number().integer().required().min(0).max(999999999).label('OpenHoleDiameter (mm)'),
    })
    .when(Joi.ref('$unit'), {
      is: 'in',
      then: Joi.number().required().min(0).max(999999999.999).label('OpenHoleDiameter (in)')
        .custom((value, helpers) => {
          if (value.toString().split('.')[1]?.length > 3) {
            return helpers.error('number.maxDecimals', { limit: 3 });
          }
          return value;
        }).messages({
          'number.maxDecimals': '{{#label}} must have at most {{#limit}} decimal places',
        })
    })
}).unknown(true);