<div class="table-header d-flex justify-content-between align-items-center mb-2">
  <div>
    {{ header }}
  </div>
  <div>
    <p-button
        [pTooltip]="!!(shouldDisableResetButton$ | async) ? '' : 'By clicking you reset all changes and go back to the original value from EDM in the table'" 
        tooltipPosition="bottom"
        [attr.data-cy]="'reset-button'"
        label="Reset"
        [disabled]="!!(shouldDisableResetButton$ | async)"      
        (click)="handleReset()"
        class="custom-p-button custom-p-button--secondary"
      ></p-button>
  </div>
</div>
  
<p-table #dt class="interval-table" [value]="datas" editMode="row" [columns]="columns" [scrollable]="true" [resizableColumns]="true" [(selection)]="selectedItems"
  (onRowSelect)="onRowSelect($event)" 
  (onRowUnselect)="onRowUnselect($event)" 
  (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
  dataKey="edmIntervalId" styleClass="p-datatable-striped custom-table custom-table--fit">
  <ng-template pTemplate="header" let-columns>
    <tr style="height: 40px" pEditableRow >
      <th *ngFor="let col of columns; first as isFirst; last as isLast; let i = index" [pSortableColumn]="col.field"
        pResizableColumn
        [ngClass]="isFirst ? 'th-last justify-content-center' : (isLast ? 'th-last justify-content-center' : 'th-default')">
        <ng-container *ngIf="isFirst">
          <p-tableHeaderCheckbox class="custom-p-checkbox"></p-tableHeaderCheckbox>
        </ng-container>

        <ng-container *ngIf="isLast">
        </ng-container>

        <ng-container *ngIf="!isLast && !isFirst">
          <div [pTooltip]="col.header" tooltipPosition="bottom" class="th-content">
            {{ col.header }}
          </div>

          <div class="header-sort-icon">
            <i class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"></i>

            <i class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"></i>
          </div>
        </ng-container>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item let-rowIndex="rowIndex" let-editing="editing">
    <tr (click)="handleRowClick($event, item)" [pEditableRow]="item" #htmlTableRowElement>
      <td class="d-flex align-items-center td-option justify-content-center">
        <p-tableCheckbox class="custom-p-checkbox" [value]="item"></p-tableCheckbox>
      </td>        

      <td class="d-flex align-items-center td-default" [ngClass]="item?.validationError?.intervalName ? 'has-error' : ''">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input 
              pInputText type="text" 
              (blur)="onInputBlur($event, 'intervalName', item)"
              [(ngModel)]="item.intervalName"
              pTooltip="{{ item?.validationError?.intervalName?.join('; ') }}" 
              tooltipPosition="top" 
              [tooltipDisabled]="!item?.validationError?.intervalName"
              />
          </ng-template>
          <ng-template pTemplate="output">
            <span>
              {{ item['intervalName'] || "N/A" }}
            </span>
          </ng-template>
        </p-cellEditor>          
      </td>

      <td class="d-flex align-items-center td-default" [ngClass]="item?.validationError?.openHoleDiameter ? 'has-error' : ''">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input 
              pInputText type="number" 
              (blur)="onInputBlur($event, 'openHoleDiameter', item)"
              [(ngModel)]="item.openHoleDiameter"
              pTooltip="{{ item?.validationError?.openHoleDiameter?.join('; ') }}" 
              tooltipPosition="top" 
              [tooltipDisabled]="!item?.validationError?.openHoleDiameter"
              />
          </ng-template>
          <ng-template pTemplate="output">              
            <span class="text-value">
              {{ item['openHoleDiameter'] ?? "N/A" }} {{ projectUnits?.Diameter || "N/A" }}
            </span>
          </ng-template>
        </p-cellEditor>  
        
      </td>

      <td class="d-flex align-items-center td-default" [ngClass]="item?.validationError?.operation ? 'has-error' : ''"
        pTooltip="{{ item?.validationError?.operation?.join('; ') }}" tooltipPosition="top"
        [tooltipDisabled]="!item?.validationError?.operation">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown 
              placeholder="Select operation"
              [options]="operations" 
              appendTo="body" 
              [(ngModel)]="item.operation" 
              [style]="{'width':'100%'}">
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">                
            <span class="text-value">
              {{ item['operation'] || "N/A" }}
            </span>
          </ng-template>
        </p-cellEditor>
        
      </td>

      <td class="d-flex align-items-center td-option justify-content-center">
        <div class="more-option-table">
          <svg-icon *ngIf="!editing" pInitEditableRow (click)="onRowEditInit(item)" key="edit" size="md"></svg-icon>
          <i *ngIf="editing" (click)="onRowEditSave(item, htmlTableRowElement)" class="pi pi-check"></i>
        </div>
      </td>      
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        Data not found
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- Loading -->
<app-loading [isLoading]="false"></app-loading>
  