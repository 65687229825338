import { Component, OnDestroy, OnInit } from '@angular/core';
import { CollapseLayoutService } from '../../services/collapseLayout.service';
import { BehaviorSubject, Subscription, finalize, switchMap } from 'rxjs';
import { TutorialService } from '../../services/tutorial.service';
import { Router } from '@angular/router';
import { TutorialItem } from './model/tutorial.model';
import { NotificationService } from '../../services/notification.service';
import { AppConstant } from '../../utilities/app.constant';
import { CategoryConfig } from './model/category-config.model';

@Component({
  selector: 'app-tutorial-center',
  templateUrl: './tutorial-center.component.html',
  styleUrls: ['./tutorial-center.component.scss']
})
export class TutorialCenterComponent implements OnInit, OnDestroy {
  tutorials: TutorialItem[] = [];
  restoreTutorials: any[] = [];
  restoreTutorials_1: any[] = [];
  indexTopic: any;

  selectedItem: string = '';

  breadcrumbs: string[] = [];
  tutorialContent: string = '';

  constructor(
    private _tutorialService: TutorialService,
    private _collapseLayoutService: CollapseLayoutService,
    private _notificationService: NotificationService,
    private _router: Router
  ) { }

  loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  breadcrumbsSubject = new BehaviorSubject<string[]>(['Tutorials']);

  private apiGetDetailSubscription: Subscription = new Subscription();
  private apiGetListSubscription: Subscription = new Subscription();

  ngOnInit() {
    // Listen Open/Close Reset
    this._collapseLayoutService.toggleTutorialPanel$.pipe().subscribe((change: boolean) => {
      if (!change) {
        this.resetData();
        this.apiGetListSubscription.unsubscribe();
        this.apiGetDetailSubscription.unsubscribe();
      } else {
        this.getDataByModule();
      }
    });

    // Listen Opening Reset
    this._collapseLayoutService.triggerResetTutorial$.subscribe((change: boolean) => {
      if (change) {
        this.resetData();
        this.getDataByModule();
      }
    })

    this.breadcrumbsSubject.subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    })
  }

  getDataByModule() {
    this.loadingData.next(true);
    let showLevel = 2;
    this.apiGetListSubscription = this._tutorialService.getTutorials(AppConstant.CATEGORY_TUTORIAL_BASE64, 1)
      .pipe(
        switchMap(({ data }: any) => {
          const path = this._tutorialService.getCurrentPathSubject();

          if (path.includes('alerts-management')) {
            const alertModule = data.find((item: any) => item.categoryName.includes('Alerts Management'));

            this.breadcrumbs = ['Tutorials', 'Alerts Management'];
            this.breadcrumbsSubject.next(['Tutorials', 'Alerts Management']);

            return this._tutorialService.getTutorials(alertModule.categoryId, 2);

          } else if (path.includes('team-management')) {
            const alertModule = data.find((item: any) => item.categoryName.includes('Team Management'));

            this.breadcrumbs = ['Tutorials', 'Team Management'];
            this.breadcrumbsSubject.next(['Tutorials', 'Team Management']);

            return this._tutorialService.getTutorials(alertModule.categoryId, 2);

          } else if (path.includes('mailbox-management')) {
            const alertModule = data.find((item: any) => item.categoryName.includes('Mailbox Management'));

            this.breadcrumbs = ['Tutorials', 'Mailbox Management'];
            this.breadcrumbsSubject.next(['Tutorials', 'Mailbox Management']);

            return this._tutorialService.getTutorials(alertModule.categoryId, 2);

          } else if (path.includes('rig-management')) {
            const alertModule = data.find((item: any) => item.categoryName.includes('Rig Management'));

            this.breadcrumbs = ['Tutorials', 'Rig Management'];
            this.breadcrumbsSubject.next(['Tutorials', 'Rig Management']);

            return this._tutorialService.getTutorials(alertModule.categoryId, 2);

          } else if (path.includes('home')) {
            const alertModule = data.find((item: any) => item.categoryName.includes('Home'));

            this.breadcrumbs = ['Tutorials', 'Home'];
            this.breadcrumbsSubject.next(['Tutorials', 'Home']);

            return this._tutorialService.getTutorials(alertModule.categoryId, 2);

          } else if (path.includes('help-center')) {
            const alertModule = data.find((item: any) => item.categoryName.includes('Help Center'));

            this.breadcrumbs = ['Tutorials', 'Help Center'];
            this.breadcrumbsSubject.next(['Tutorials', 'Help Center']);

            return this._tutorialService.getTutorials(alertModule.categoryId, 2);
          } else {
            showLevel = 1;
            this.breadcrumbs = ['Tutorials'];
            this.breadcrumbsSubject.next(['Tutorials']);
            return this._tutorialService.getTutorials(AppConstant.CATEGORY_TUTORIAL_BASE64, 1);
          }
        })
      )
      .subscribe({
        next: (res: any) => {
          let showingChapper = res.data;

          if (showLevel === 1) {
            let categoriesIndex: any = [];
            try {
              categoriesIndex = atob(res.indexModuleArray).split(',');
            } catch (e) {
              categoriesIndex = [];
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Information',
                content: 'Can not find configuration Order Modules',
              });
            }

            if (res.data.length === categoriesIndex.length) {
              const tempArray: any[] = [];
              categoriesIndex.forEach((item: any) => {
                const temp = res.data.find(
                  (element: any) => element.categoryId === item
                );
                if (temp) {
                  tempArray.push(temp);
                }
              });
              // check count data valid second
              if (tempArray.length === res.data.length) {
                showingChapper = tempArray;
              } else {
                showingChapper = res.data;
              }
            } else {
              showingChapper = res.data;
            }

            let tutorialHardList = showingChapper.map((tutorial: any) => {
              return {
                ...tutorial,
                title: tutorial.categoryName,
                svg: tutorial.iconCode,
                sizeIcon: 'md',
                id: tutorial.categoryId,
                level: 1,
              }
            });
            tutorialHardList = tutorialHardList.filter((tutorial: any) => tutorial.isHide === false);

            // Handle by role
            const path = this._tutorialService.getCurrentPathSubject();
            const tutorialList = this._tutorialService.getUserTutorialSubject();

            if (path && tutorialList.length) {
              const contains = tutorialHardList.filter((element: any) => {
                return tutorialList.find((path: any) => path.title.toLowerCase() === element.title.toLowerCase());
              });

              this.tutorials = contains;
              this.restoreTutorials = contains;
            }
          } else if (showLevel === 2) {
            let parseData;
            try {
              parseData = JSON.parse(atob(res.indexTopicArray));
            } catch (error) {
              parseData = {};
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Information',
                content: 'Can not find configuration Order Topics',
              });
            }
            this.indexTopic = parseData;

            if (showingChapper.topicList.length && Object.keys(this.indexTopic).length) {
              showingChapper.topicList.forEach((topic: any) => {
                let tempArrChapter: any = [];

                // Category sort
                if (this.indexTopic[topic.categoryId]) {
                  this.indexTopic[topic.categoryId].forEach(
                    (element: any) => {
                      const itemTemp: any = showingChapper.topicList.find(
                        (elm: any) => elm.topicId === element
                      );
                      if (itemTemp) {
                        tempArrChapter.push(
                          showingChapper.topicList.find(
                            (elm: any) => elm.topicId === element
                          )
                        );
                      }
                    }
                  );
                  // check count
                  if (tempArrChapter.length === showingChapper.topicList.length) {
                    showingChapper.topicList = Object.assign([], tempArrChapter);
                  }
                }
              });
            }
            let tutorialHardList = showingChapper.topicList.map((tutorial: any) => {
              return {
                ...tutorial,
                title: tutorial.topicName,
                svg: tutorial.iconCode,
                sizeIcon: 'md',
                id: tutorial.topicId,
                level: 2,
              }
            });
            this.tutorials = tutorialHardList;
            this.restoreTutorials_1 = tutorialHardList;
            this.selectedItem = '';
          } 

          this.loadingData.next(false);
        }
      })
  }

  resetData() {
    this.tutorials = this.restoreTutorials;
    this.selectedItem = '';
    this.breadcrumbs = ['Tutorials'];
    this.breadcrumbsSubject.next(['Tutorials']),
    this.tutorialContent = '';
    this.loadingData.next(false);
  }

  initData(tutorialCategoryId: string, level: number): void {
    this.tutorials = [];
    this.loadingData.next(true);

    this.apiGetListSubscription = this._tutorialService.getTutorials(tutorialCategoryId, level)
      .pipe(
        finalize(() => {
          this.loadingData.next(false);
        })
      )
      .subscribe({
        next: (response: CategoryConfig) => {
          const { 
            data, 
            indexChannelArray, 
            indexGuideArray, 
            indexModuleArray, 
            indexSubChapterArray, 
            indexTopicArray, 
            message 
          } = response;

          if (level === 1) {
            let showingChapper: TutorialItem[] = data;

            let categoriesIndex: any = [];
            try {
              categoriesIndex = atob(indexModuleArray).split(',');
            } catch (e) {
              categoriesIndex = [];
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Information',
                content: 'Can not find configuration Order Modules',
              });
            }
            
            if (data.length === categoriesIndex.length) {
              const tempArray: any[] = [];
              categoriesIndex.forEach((item: any) => {
                const temp = data.find(
                  (element: any) => element.categoryId === item
                );
                if (temp) {
                  tempArray.push(temp);
                }
              });
              
              // check count data valid second
              if (tempArray.length === data.length) {
                showingChapper = tempArray;
              } else {
                showingChapper = data;
              }
            } else {
              showingChapper = data;
            }

            let tutorialHardList = showingChapper.map((tutorial: any) => {
              return {
                ...tutorial,
                title: tutorial.categoryName,
                svg: tutorial.iconCode,
                sizeIcon: 'md',
                id: tutorial.categoryId,
                level: 1,
              }
            });
            tutorialHardList = tutorialHardList.filter((tutorial: any) => tutorial.isHide === false || tutorial.isHide === null);

            // Handle by role
            const path = this._tutorialService.getCurrentPathSubject();
            const tutorialList = this._tutorialService.getUserTutorialSubject();
            
            if (path && tutorialList.length) {
              const contains = tutorialHardList.filter((element: any) => {
                return tutorialList.find((path: any) => path.title.toLowerCase() === element.title.toLowerCase());
              });

              this.tutorials = contains;
              this.restoreTutorials = contains;
            }
            
          } else if (level === 2) {
            let showingChapper: TutorialItem = data;
            let parseData;

            try {
              parseData = JSON.parse(atob(indexTopicArray));
            } catch (error) {
              parseData = {};
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Information',
                content: 'Can not find configuration Order Topics',
              });
            }
            this.indexTopic = parseData;

            if (showingChapper.topicList.length && Object.keys(this.indexTopic).length) {
              showingChapper.topicList.forEach((topic: any) => {
                let tempArrChapter: any = [];

                // Category sort
                if (this.indexTopic[topic.categoryId]) {
                  this.indexTopic[topic.categoryId].forEach(
                    (element: any) => {
                      const itemTemp: any = showingChapper.topicList.find(
                        (elm: any) => elm.topicId === element
                      );
                      if (itemTemp) {
                        tempArrChapter.push(
                          showingChapper.topicList.find(
                            (elm: any) => elm.topicId === element
                          )
                        );
                      }
                    }
                  );
                  // check count
                  if (tempArrChapter.length === showingChapper.topicList.length) {
                    showingChapper.topicList = Object.assign([], tempArrChapter);
                  }
                }
              });
            }
            let tutorialHardList = showingChapper.topicList.map((tutorial: any) => {
              return {
                ...tutorial,
                title: tutorial.topicName,
                svg: tutorial.iconCode,
                sizeIcon: 'md',
                id: tutorial.topicId,
                level: 2,
              }
            });
            this.tutorials = tutorialHardList;
            this.restoreTutorials_1 = tutorialHardList;
            this.selectedItem = '';
          }

          this.loadingData.next(false);
        },
        error: (error: any) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Information',
            content: error?.message || error,
          });

          this.loadingData.next(false);
        },
      })
  }

  showDetails(id: string, title: string, level: number): void {
    switch (level) {
      case 0:
        this.initData(AppConstant.CATEGORY_TUTORIAL_BASE64, 1);
        break;
      case 1:
        this.breadcrumbsSubject.next(['Tutorials', title]);
        this.initData(id, 2);
        this.selectedItem = id;

        break;
      case 2:
        this.selectedItem = id;
        let currentBreadCrumb: string[] = this.breadcrumbsSubject.getValue();
        currentBreadCrumb.push(title);
        this.breadcrumbsSubject.next(currentBreadCrumb);
        this.loadDetails(id);
        break;
      default:
        this.loadDetails(id);
        break;
    }
  }

  loadDetails(id: string): void {
    this.loadingData.next(true);
    this.apiGetDetailSubscription = this._tutorialService.getDetailTutorial(id)
      .subscribe({
        next: (res: any) => {
          this.tutorialContent = res.content || '<h1>This topic has not been updated yet</h1><br/><h2>please update the content at Help Center management</h2>';
          this.loadingData.next(false);
        },
        error: (error: any) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Information',
            content: error?.message || error,
          });

          this.loadingData.next(false);
        },
      })
  }

  closePanel(): void {
    this._collapseLayoutService.toggleTutorialPanel(false);
  }

  navigateToLevel(level: number): void {
    this.selectedItem = '';
    this.tutorialContent = '';
    const breadcrumbs = this.breadcrumbsSubject.value.slice(0, level + 1);
    this.breadcrumbsSubject.next(breadcrumbs);

    switch (level) {
      case 0:
        this.initData(AppConstant.CATEGORY_TUTORIAL_BASE64, 1);
        break;
      case 1:
        this.tutorials = this.restoreTutorials_1;
        break;
      default:
        this.tutorials = [];
        break;
    }
  }

  goBack(trigger: boolean): void {
    // Show List Tutorials
    if (trigger) {
      this.selectedItem = '';
      this.tutorialContent = '';
      this.tutorials = this.restoreTutorials;
      this.breadcrumbsSubject.next(['Tutorials']);
    }
  }

  redirectTo(destination: string): void {
    this.closePanel();
    this._router.navigate([destination]);
  }

  getVisibleItemCount(data: TutorialItem[]): number {
    if (!data.length) return 0;
    return data.filter(item => !item.isHide).length;
  }

  ngOnDestroy(): void {
    this.apiGetListSubscription.unsubscribe();
    this.apiGetDetailSubscription.unsubscribe();
  }
}
