import { environment } from 'src/environments/environment';
import {
  IConfirmDialog,
  IDropdownSelection,
  IMessageTypeConst,
  IOptionButtonConst,
  IStatusInteract,
} from '../interface/common';
import { IAlertEvent } from '../interface/alert.interface';
import { StatusMSTeamsType } from '../type';
import { extend } from 'lodash';

export namespace AppConstant {
  export const APPLICATION_NAME = 'LOGIX™ Event Capture';
  export const APPLICATION_MAIL = 'WellCare@halliburton.com';
  
  export const APP_VERSION = `${APPLICATION_NAME} Version`;
  export const CATEGORY_GUIDE_BASE64 = 'Y2F0ZWdvcnlfdHlwZT0nZ3VpZGUn';
  export const CATEGORY_TUTORIAL_BASE64 = 'Y2F0ZWdvcnlfdHlwZT0ndHV0b3JpYWwn';  
  export const CATEGORY_RELEASE_NOTE_BASE64 = 'Y2F0ZWdvcnlfdHlwZT0ncmVsZWFzZV9ub3RlJw==';

  export const MAX_SIZE_IMAGE_UPLOAD = 1000000;
  export const MAX_SIZE_PDF_UPLOAD = 5000000;

  export const DELAY_TIME_SEND_STATUS_TYPING = 2000;
  
  export const WARN_SIZE_IMAGE_UPLOAD = 'Please upload files under 1Mb';
  export const WARN_SIZE_PDF_UPLOAD = 'Please upload files under 5Mb';

  // Fetch Data
  export const ROOT_PAGE = 'home';

  // HAL POLICY PAGE
  export const HALLIBURTON_POLICY_PAGE =
    'https://halworld.corp.halliburton.com/en/support-services/legal/global-ethics-and-compliance-main-page/data-privacy/employee-privacy-notice';

  // Power BI Source
  export const POWBER_BI = environment.POWER_BI_LINK

  // Default Home Filter Setting
  export const DEFAULT_HOME_FILTER = {
    code: 'active',
    searchString: '',
  }

  // Default Step Load More Alert Logs
  export const ITEMS_PER_PAGE: number = 20;
  
  // Data Hard Code
  export const TOOLTIP_HELP_CENTER = `${APPLICATION_NAME} enables well construction and intervention experts in remote centers to systematically generate and transmit alerts to local operations teams by offering a streamlined user interface and automating the management of distribution lists and the generation of emails. Better communication improves safety, reduces non-productive time (NPT) and invisible lost time (ILT), and enables better oversight of operations.`;
  export const REGIONS = [
    { name: 'North America (NAM)', code: 'NAM' },
    { name: 'Latin America (LA)', code: 'LA' },
    { name: 'Europe, Eurasia, Sub-Saharan Africa (EESSA)', code: 'EESSA' },
    { name: 'Asia Pacific (AP)', code: 'AP' },
    { name: 'Middle East, North Africa (MENA)', code: 'MENA' },
  ];
  // Remove
  // { name: 'Oceania (OC)', code: 'OC' },
  // { name: 'Antarctica (AN)', code: 'AN' },
  // { name: 'Africa (AF)', code: 'AF' },

  export const DEFAULT_DATA_SOURCES = [
    {
      sourceId: 'idOfHPM',
      sourceName: 'HPM',
      displaySourceName: 'Global HPM',
      databaseSource: 'EDM',
    },
    {
      sourceId: 'idOfIDAHO',
      sourceName: 'IDAHO',
      displaySourceName: 'GOM IDAHO',
      databaseSource: 'EDM',
    },
    {
      sourceId: 'idOfOHIO',
      sourceName: 'OHIO',
      displaySourceName: 'Norway OHIO',
      databaseSource: 'EDM',
      children: [
        {
          sourceId: 'idOfAkerBP',
          sourceName: 'OHIO(AkerBP)',
          displaySourceName: 'Norway OHIO EDM (AkerBP)',
          databaseSource: 'EDM',
        },
        {
          sourceId: 'idOfConocoPhillips',
          sourceName: 'OHIO(ConocoPhillips)',
          displaySourceName: 'Norway OHIO EDM (ConocoPhillips)',
          databaseSource: 'EDM',
        },
        {
          sourceId: 'idOfEquinor',
          sourceName: 'OHIO(Equinor)',
          displaySourceName: 'Norway OHIO EDM (Equinor)',
          databaseSource: 'EDM',
        },
        {
          sourceId: 'idOfWintershall',
          sourceName: 'OHIO(Wintershall)',
          displaySourceName: 'Norway OHIO EDM (Wintershall)',
          databaseSource: 'EDM',
        },
        {
          sourceId: 'idOfVarEnergi',
          sourceName: 'OHIO(VarEnergi)',
          displaySourceName: 'Norway OHIO EDM (VarEnergi)',
          databaseSource: 'EDM',
        },
      ],
    },
  ];

   export const DATA_SOURCES: any | Object = {
     'HPM': {
       sourceName: 'HPM',
       displaySourceName: 'Global HPM',
       displaySourceDropdown: 'HPM Global EDM',
       databaseSource: 'EDM',
     },
     'IDAHO': {
       sourceName: 'IDAHO',
       displaySourceName: 'IDAHO',
       displaySourceDropdown: 'GOM IDAHO EDM',
       databaseSource: 'EDM',
     },
     'OHIO(AkerBP)': {
       sourceName: 'OHIO(AkerBP)',
       displaySourceName: 'Norway OHIO (AkerBP)',
       displaySourceDropdown: 'Norway OHIO EDM (AkerBP)',
       databaseSource: 'EDM',
     },
     'OHIO(ConocoPhillips)': {
       sourceName: 'OHIO(ConocoPhillips)',
       displaySourceName: 'Norway OHIO (ConocoPhillips)',
       displaySourceDropdown: 'Norway OHIO EDM (ConocoPhillips)',
       databaseSource: 'EDM',
     },

     'OHIO(Equinor)': {
       sourceName: 'OHIO(Equinor)',
       displaySourceName: 'Norway OHIO (Equinor)',
       displaySourceDropdown: 'Norway OHIO EDM (Equinor)',
       databaseSource: 'EDM',
     },
     'OHIO(Wintershall)': {
       sourceName: 'OHIO(Wintershall)',
       displaySourceName: 'Norway OHIO (Wintershall)',
       displaySourceDropdown: 'Norway OHIO EDM (Wintershall)',
       databaseSource: 'EDM',
     },
     'OHIO(VarEnergi)': {
       sourceName: 'OHIO(VarEnergi)',
       displaySourceName: 'Norway OHIO (VarEnergi)',
       displaySourceDropdown: 'Norway OHIO EDM (VarEnergi)',
       databaseSource: 'EDM',
     },
   };

  export const DEFAULT_DATA_SOURCES_UPDATE = [
    {
      label: 'HPM Global EDM',
      data: 'HPM',
    },
    {
      label: 'GOM IDAHO EDM',
      data: 'IDAHO',
    },
    {
      label: 'Norway OHIO EDM',
      data: 'OHIO',
      children: [
        {
          label: 'AkerBP',
          data: 'OHIO(AkerBP)',
        },
        {
          label: 'ConocoPhillips',
          data: 'OHIO(ConocoPhillips)',
        }, 
        {
          label: 'Equinor',
          data: 'OHIO(Equinor)',
        },
        {
          label: 'Wintershall',
          data: 'OHIO(Wintershall)',
        },
        {
          label: 'Vår Energi',
          data: 'OHIO(VarEnergi)',
        },
      ],
    }
  ];

  export const ALERT_STATUS = {
    INITIATE: 'INITIATE',
    RESOLVE: 'RESOLVE',
    RESOLUTION: 'RESOLUTION',
  };
  export const STATUS_ALERT = [
    { name: 'Resolution Required' }, //{ name: 'Resolution Required' },
    { name: 'Initiate (Draft)' },
    { name: 'Initiate (Completed)' },
    { name: 'Resolve (Draft)' },
    { name: 'Resolve (Completed)' },
  ];

  export const COUNTRIES = [
    {
      name: 'Afghanistan',
      code: 'AF',
      region: 'MENA',
    },
    {
      name: 'Aland Islands',
      code: 'AX',
      region: 'EESSA',
    },
    {
      name: 'Albania',
      code: 'AL',
      region: 'EESSA',
    },
    {
      name: 'Algeria',
      code: 'DZ',
      region: 'MENA',
    },
    {
      name: 'American Samoa',
      code: 'AS',
      region: 'AP',
    },
    {
      name: 'Andorra',
      code: 'AD',
      region: 'EESSA',
    },
    {
      name: 'Angola',
      code: 'AO',
      region: 'EESSA',
    },
    {
      name: 'Anguilla',
      code: 'AI',
      region: 'LA',
    },
    {
      name: 'Antigua and Barbuda',
      code: 'AG',
      region: 'LA',
    },
    {
      name: 'Argentina',
      code: 'AR',
      region: 'LA',
    },
    {
      name: 'Armenia',
      code: 'AM',
      region: 'EESSA',
    },
    {
      name: 'Aruba',
      code: 'AW',
      region: 'LA',
    },
    {
      name: 'Australia',
      code: 'AU',
      region: 'AP',
    },
    {
      name: 'Austria',
      code: 'AT',
      region: 'EESSA',
    },
    {
      name: 'Azerbaijan',
      code: 'AZ',
      region: 'EESSA',
    },
    {
      name: 'Bahamas',
      code: 'BS',
      region: 'LA',
    },
    {
      name: 'Bahrain',
      code: 'BH',
      region: 'MENA',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
      region: 'AP',
    },
    {
      name: 'Barbados',
      code: 'BB',
      region: 'LA',
    },
    {
      name: 'Belarus',
      code: 'BY',
      region: 'EESSA',
    },
    {
      name: 'Belgium',
      code: 'BE',
      region: 'EESSA',
    },
    {
      name: 'Belize',
      code: 'BZ',
      region: 'LA',
    },
    {
      name: 'Benin',
      code: 'BJ',
      region: 'EESSA',
    },
    {
      name: 'Bermuda',
      code: 'BM',
      region: 'LA',
    },
    {
      name: 'Bhutan',
      code: 'BT',
      region: 'AP',
    },
    {
      name: 'Bolivia',
      code: 'BO',
      region: 'LA',
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BA',
      region: 'EESSA',
    },
    {
      name: 'Botswana',
      code: 'BW',
      region: 'EESSA',
    },
    {
      name: 'Bouvet Island',
      code: 'BV',
      region: 'EESSA',
    },
    {
      name: 'Brazil',
      code: 'BR',
      region: 'LA',
    },
    {
      name: 'British Indian Ocean Territory',
      code: 'IO',
      region: 'AP',
    },
    {
      name: 'Brunei Darussalam',
      code: 'BN',
      region: 'AP',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
      region: 'EESSA',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
      region: 'EESSA',
    },
    {
      name: 'Burundi',
      code: 'BI',
      region: 'EESSA',
    },
    {
      name: 'Cambodia',
      code: 'KH',
      region: 'AP',
    },
    {
      name: 'Cameroon',
      code: 'CM',
      region: 'EESSA',
    },
    {
      name: 'Canada',
      code: 'CA',
      region: 'NAM',
    },
    {
      name: 'Cape Verde',
      code: 'CV',
      region: 'EESSA',
    },
    {
      name: 'Cayman Islands',
      code: 'KY',
      region: 'LA',
    },
    {
      name: 'Central African Republic',
      code: 'CF',
      region: 'EESSA',
    },
    {
      name: 'Chad',
      code: 'TD',
      region: 'EESSA',
    },
    {
      name: 'Chile',
      code: 'CL',
      region: 'LA',
    },
    {
      name: 'China',
      code: 'CN',
      region: 'AP',
    },
    {
      name: 'Christmas Island',
      code: 'CX',
      region: 'AP',
    },
    {
      name: 'Cocos (Keeling) Islands',
      code: 'CC',
      region: 'AP',
    },
    {
      name: 'Colombia',
      code: 'CO',
      region: 'LA',
    },
    {
      name: 'Comoros',
      code: 'KM',
      region: 'EESSA',
    },
    {
      name: 'Congo',
      code: 'CG',
      region: 'EESSA',
    },
    {
      name: 'Congo, The Democratic Republic of the',
      code: 'CD',
      region: 'EESSA',
    },
    {
      name: 'Cook Islands',
      code: 'CK',
      region: 'AP',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
      region: 'LA',
    },
    {
      name: 'Cote D"Ivoire',
      code: 'CI',
      region: 'EESSA',
    },
    {
      name: 'Croatia',
      code: 'HR',
      region: 'EESSA',
    },
    {
      name: 'Cuba',
      code: 'CU',
      region: 'LA',
    },
    {
      name: 'Cyprus',
      code: 'CY',
      region: 'EESSA',
    },
    {
      name: 'Czech Republic',
      code: 'CZ',
      region: 'EESSA',
    },
    {
      name: 'Denmark',
      code: 'DK',
      region: 'EESSA',
    },
    {
      name: 'Djibouti',
      code: 'DJ',
      region: 'MENA',
    },
    {
      name: 'Dominica',
      code: 'DM',
      region: 'LA',
    },
    {
      name: 'Dominican Republic',
      code: 'DO',
      region: 'LA',
    },
    {
      name: 'Ecuador',
      code: 'EC',
      region: 'LA',
    },
    {
      name: 'Egypt',
      code: 'EG',
      region: 'MENA',
    },
    {
      name: 'El Salvador',
      code: 'SV',
      region: 'LA',
    },
    {
      name: 'Equatorial Guinea',
      code: 'GQ',
      region: 'EESSA',
    },
    {
      name: 'Eritrea',
      code: 'ER',
      region: 'EESSA',
    },
    {
      name: 'Estonia',
      code: 'EE',
      region: 'EESSA',
    },
    {
      name: 'Ethiopia',
      code: 'ET',
      region: 'EESSA',
    },
    {
      name: 'Falkland Islands (Malvinas)',
      code: 'FK',
      region: 'LA',
    },
    {
      name: 'Faroe Islands',
      code: 'FO',
      region: 'EESSA',
    },
    {
      name: 'Fiji',
      code: 'FJ',
      region: 'AP',
    },
    {
      name: 'Finland',
      code: 'FI',
      region: 'EESSA',
    },
    {
      name: 'France',
      code: 'FR',
      region: 'EESSA',
    },
    {
      name: 'French Guiana',
      code: 'GF',
      region: 'LA',
    },
    {
      name: 'French Polynesia',
      code: 'PF',
      region: 'AP',
    },
    {
      name: 'French Southern Territories',
      code: 'TF',
      region: 'AP',
    },
    {
      name: 'Gabon',
      code: 'GA',
      region: 'EESSA',
    },
    {
      name: 'Gambia',
      code: 'GM',
      region: 'EESSA',
    },
    {
      name: 'Georgia',
      code: 'GE',
      region: 'EESSA',
    },
    {
      name: 'Germany',
      code: 'DE',
      region: 'EESSA',
    },
    {
      name: 'Ghana',
      code: 'GH',
      region: 'EESSA',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
      region: 'EESSA',
    },
    {
      name: 'Greece',
      code: 'GR',
      region: 'EESSA',
    },
    {
      name: 'Greenland',
      code: 'GL',
      region: 'EESSA',
    },
    {
      name: 'Grenada',
      code: 'GD',
      region: 'LA',
    },
    {
      name: 'Guadeloupe',
      code: 'GP',
      region: 'LA',
    },
    {
      name: 'Guam',
      code: 'GU',
      region: 'AP',
    },
    {
      name: 'Guatemala',
      code: 'GT',
      region: 'LA',
    },
    // {
    //   name: 'Guernsey',
    //   code: 'GG',
    //   region: 'EESSA',
    // },
    {
      name: 'Guinea',
      code: 'GN',
      region: 'EESSA',
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
      region: 'EESSA',
    },
    {
      name: 'Guyana',
      code: 'GY',
      region: 'LA',
    },
    {
      name: 'Haiti',
      code: 'HT',
      region: 'LA',
    },
    {
      name: 'Heard Island and Mcdonald Islands',
      code: 'HM',
      region: 'AP',
    },
    {
      name: 'Holy See (Vatican City State)',
      code: 'VA',
      region: 'EESSA',
    },
    {
      name: 'Honduras',
      code: 'HN',
      region: 'LA',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
      region: 'AP',
    },
    {
      name: 'Hungary',
      code: 'HU',
      region: 'EESSA',
    },
    {
      name: 'Iceland',
      code: 'IS',
      region: 'EESSA',
    },
    {
      name: 'India',
      code: 'IN',
      region: 'AP',
    },
    {
      name: 'Indonesia',
      code: 'ID',
      region: 'AP',
    },
    {
      name: 'Iran, Islamic Republic Of',
      code: 'IR',
      region: 'MENA',
    },
    {
      name: 'Iraq',
      code: 'IQ',
      region: 'MENA',
    },
    {
      name: 'Ireland',
      code: 'IE',
      region: 'EESSA',
    },
    // {
    //   name: 'Isle of Man',
    //   code: 'IM',
    //   region: 'EESSA',
    // },
    {
      name: 'Israel',
      code: 'IL',
      region: 'EESSA',
    },
    {
      name: 'Italy',
      code: 'IT',
      region: 'EESSA',
    },
    {
      name: 'Jamaica',
      code: 'JM',
      region: 'LA',
    },
    {
      name: 'Japan',
      code: 'JP',
      region: 'AP',
    },
    // {
    //   name: 'Jersey',
    //   code: 'JE',
    //   region: 'EESSA',
    // },
    {
      name: 'Jordan',
      code: 'JO',
      region: 'MENA',
    },
    {
      name: 'Kazakhstan',
      code: 'KZ',
      region: 'EESSA',
    },
    {
      name: 'Kenya',
      code: 'KE',
      region: 'EESSA',
    },
    {
      name: 'Kiribati',
      code: 'KI',
      region: 'AP',
    },
    {
      name: 'Korea, Democratic People"S Republic of',
      code: 'KP',
      region: 'AP',
    },
    {
      name: 'Korea, Republic of',
      code: 'KR',
      region: 'AP',
    },
    {
      name: 'Kuwait',
      code: 'KW',
      region: 'MENA',
    },
    {
      name: 'Kyrgyzstan',
      code: 'KG',
      region: 'EESSA',
    },
    {
      name: 'Lao People"S Democratic Republic',
      code: 'LA',
      region: 'AP',
    },
    {
      name: 'Latvia',
      code: 'LV',
      region: 'EESSA',
    },
    {
      name: 'Lebanon',
      code: 'LB',
      region: 'MENA',
    },
    {
      name: 'Lesotho',
      code: 'LS',
      region: 'EESSA',
    },
    {
      name: 'Liberia',
      code: 'LR',
      region: 'EESSA',
    },
    {
      name: 'Libyan Arab Jamahiriya',
      code: 'LY',
      region: 'MENA',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
      region: 'EESSA',
    },
    {
      name: 'Lithuania',
      code: 'LT',
      region: 'EESSA',
    },
    {
      name: 'Luxembourg',
      code: 'LU',
      region: 'EESSA',
    },
    {
      name: 'Macao',
      code: 'MO',
      region: 'AP',
    },
    {
      name: 'Macedonia, The Former Yugoslav Republic of',
      code: 'MK',
      region: 'EESSA',
    },
    {
      name: 'Madagascar',
      code: 'MG',
      region: 'EESSA',
    },
    {
      name: 'Malawi',
      code: 'MW',
      region: 'EESSA',
    },
    {
      name: 'Malaysia',
      code: 'MY',
      region: 'AP',
    },
    {
      name: 'Maldives',
      code: 'MV',
      region: 'AP',
    },
    {
      name: 'Mali',
      code: 'ML',
      region: 'EESSA',
    },
    {
      name: 'Malta',
      code: 'MT',
      region: 'EESSA',
    },
    {
      name: 'Marshall Islands',
      code: 'MH',
      region: 'AP',
    },
    {
      name: 'Martinique',
      code: 'MQ',
      region: 'LA',
    },
    {
      name: 'Mauritania',
      code: 'MR',
      region: 'EESSA',
    },
    {
      name: 'Mauritius',
      code: 'MU',
      region: 'EESSA',
    },
    {
      name: 'Mayotte',
      code: 'YT',
      region: 'EESSA',
    },
    {
      name: 'Mexico',
      code: 'MX',
      region: 'LA',
    },
    {
      name: 'Micronesia',
      code: 'FM',
      region: 'AP',
    },
    {
      name: 'Moldova, Republic of Moldova',
      code: 'MD',
      region: 'EESSA',
    },
    {
      name: 'Monaco',
      code: 'MC',
      region: 'EESSA',
    },
    {
      name: 'Mongolia',
      code: 'MN',
      region: 'AP',
    },
    {
      name: 'Montserrat',
      code: 'MS',
      region: 'LA',
    },
    {
      name: 'Morocco',
      code: 'MA',
      region: 'MENA',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
      region: 'EESSA',
    },
    {
      name: 'Myanmar',
      code: 'MM',
      region: 'AP',
    },
    {
      name: 'Namibia',
      code: 'NA',
      region: 'EESSA',
    },
    {
      name: 'Nauru',
      code: 'NR',
      region: 'AP',
    },
    {
      name: 'Nepal',
      code: 'NP',
      region: 'AP',
    },
    {
      name: 'Netherlands',
      code: 'NL',
      region: 'EESSA',
    },
    {
      name: 'Netherlands Antilles',
      code: 'AN',
      region: 'LA',
    },
    {
      name: 'New Caledonia',
      code: 'NC',
      region: 'AP',
    },
    {
      name: 'New Zealand',
      code: 'NZ',
      region: 'AP',
    },
    {
      name: 'Nicaragua',
      code: 'NI',
      region: 'LA',
    },
    {
      name: 'Niger',
      code: 'NE',
      region: 'EESSA',
    },
    {
      name: 'Nigeria',
      code: 'NG',
      region: 'EESSA',
    },
    {
      name: 'Niue',
      code: 'NU',
      region: 'AP',
    },
    {
      name: 'Norfolk Island',
      code: 'NF',
      region: 'AP',
    },
    {
      name: 'Northern Mariana Islands',
      code: 'MP',
      region: 'AP',
    },
    {
      name: 'Norway',
      code: 'NO',
      region: 'EESSA',
    },
    {
      name: 'Oman',
      code: 'OM',
      region: 'MENA',
    },
    {
      name: 'Pakistan',
      code: 'PK',
      region: 'AP',
    },
    {
      name: 'Palau',
      code: 'PW',
      region: 'AP',
    },
    {
      name: 'Palestinian Territory, Occupied',
      code: 'PS',
      region: 'MENA',
    },
    {
      name: 'Panama',
      code: 'PA',
      region: 'LA',
    },
    {
      name: 'Papua New Guinea',
      code: 'PG',
      region: 'AP',
    },
    {
      name: 'Paraguay',
      code: 'PY',
      region: 'LA',
    },
    {
      name: 'Peru',
      code: 'PE',
      region: 'LA',
    },
    {
      name: 'Philippines',
      code: 'PH',
      region: 'AP',
    },
    {
      name: 'Pitcairn',
      code: 'PN',
      region: 'LA',
    },
    {
      name: 'Poland',
      code: 'PL',
      region: 'EESSA',
    },
    {
      name: 'Portugal',
      code: 'PT',
      region: 'EESSA',
    },
    {
      name: 'Puerto Rico',
      code: 'PR',
      region: 'NAM',
    },
    {
      name: 'Qatar',
      code: 'QA',
      region: 'MENA',
    },
    {
      name: 'Reunion',
      code: 'RE',
      region: 'EESSA',
    },
    {
      name: 'Romania',
      code: 'RO',
      region: 'EESSA',
    },
    {
      name: 'Russian Federation',
      code: 'RU',
      region: 'EESSA',
    },
    {
      name: 'RWANDA',
      code: 'RW',
      region: 'EESSA',
    },
    {
      name: 'Saint Helena',
      code: 'SH',
      region: 'EESSA',
    },
    {
      name: 'Saint Kitts and Nevis',
      code: 'KN',
      region: 'LA',
    },
    {
      name: 'Saint Lucia',
      code: 'LC',
      region: 'LA',
    },
    {
      name: 'Saint Pierre and Miquelon',
      code: 'PM',
      region: 'NAM',
    },
    {
      name: 'Saint Vincent and the Grenadines',
      code: 'VC',
      region: 'LA',
    },
    {
      name: 'Samoa',
      code: 'WS',
      region: 'AP',
    },
    {
      name: 'San Marino',
      code: 'SM',
      region: 'EESSA',
    },
    {
      name: 'Sao Tome and Principe',
      code: 'ST',
      region: 'EESSA',
    },
    {
      name: 'Saudi Arabia',
      code: 'SA',
      region: 'MENA',
    },
    {
      name: 'Senegal',
      code: 'SN',
      region: 'EESSA',
    },
    {
      name: 'Serbia and Montenegro',
      code: 'CS',
      region: 'EESSA',
    },
    {
      name: 'Seychelles',
      code: 'SC',
      region: 'EESSA',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
      region: 'EESSA',
    },
    {
      name: 'Singapore',
      code: 'SG',
      region: 'AP',
    },
    {
      name: 'Slovakia',
      code: 'SK',
      region: 'EESSA',
    },
    {
      name: 'Slovenia',
      code: 'SI',
      region: 'EESSA',
    },
    {
      name: 'Solomon Islands',
      code: 'SB',
      region: 'AP',
    },
    {
      name: 'Somalia',
      code: 'SO',
      region: 'EESSA',
    },
    {
      name: 'South Africa',
      code: 'ZA',
      region: 'EESSA',
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      code: 'GS',
      region: 'LA',
    },
    {
      name: 'Spain',
      code: 'ES',
      region: 'EESSA',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
      region: 'AP',
    },
    {
      name: 'Sudan',
      code: 'SD',
      region: 'EESSA',
    },
    {
      name: 'Suriname',
      code: 'SR',
      region: 'LA',
    },
    {
      name: 'Svalbard and Jan Mayen',
      code: 'SJ',
      region: 'EESSA',
    },
    {
      name: 'Swaziland',
      code: 'SZ',
      region: 'EESSA',
    },
    {
      name: 'Sweden',
      code: 'SE',
      region: 'EESSA',
    },
    {
      name: 'Switzerland',
      code: 'CH',
      region: 'EESSA',
    },
    {
      name: 'Syrian Arab Republic',
      code: 'SY',
      region: 'MENA',
    },
    {
      name: 'Taiwan, Province of China',
      code: 'TW',
      region: 'AP',
    },
    {
      name: 'Tajikistan',
      code: 'TJ',
      region: 'EESSA',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
      region: 'EESSA',
    },
    {
      name: 'Thailand',
      code: 'TH',
      region: 'AP',
    },
    {
      name: 'Timor-Leste',
      code: 'TL',
      region: 'AP',
    },
    {
      name: 'Togo',
      code: 'TG',
      region: 'EESSA',
    },
    {
      name: 'Tokelau',
      code: 'TK',
      region: 'AP',
    },
    {
      name: 'Tonga',
      code: 'TO',
      region: 'AP',
    },
    {
      name: 'Trinidad and Tobago',
      code: 'TT',
      region: 'LA',
    },
    {
      name: 'Tunisia',
      code: 'TN',
      region: 'MENA',
    },
    {
      name: 'Turkey',
      code: 'TR',
      region: 'EESSA',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
      region: 'EESSA',
    },
    {
      name: 'Turks and Caicos Islands',
      code: 'TC',
      region: 'LA',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
      region: 'AP',
    },
    {
      name: 'Uganda',
      code: 'UG',
      region: 'EESSA',
    },
    {
      name: 'Ukraine',
      code: 'UA',
      region: 'EESSA',
    },
    {
      name: 'United Arab Emirates',
      code: 'AE',
      region: 'MENA',
    },
    {
      name: 'United Kingdom',
      code: 'GB',
      region: 'EESSA',
    },
    {
      name: 'United States of America',
      code: 'USA',
      region: 'NAM',
    },
    {
      name: 'United States Minor Outlying Islands',
      code: 'UM',
      region: 'NAM',
    },
    {
      name: 'Uruguay',
      code: 'UY',
      region: 'LA',
    },
    {
      name: 'Uzbekistan',
      code: 'UZ',
      region: 'EESSA',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
      region: 'AP',
    },
    {
      name: 'Venezuela',
      code: 'VE',
      region: 'LA',
    },
    {
      name: 'Viet Nam',
      code: 'VN',
      region: 'AP',
    },
    {
      name: 'Virgin Islands, British',
      code: 'VG',
      region: 'NAM',
    },
    {
      name: 'Virgin Islands, U.S.',
      code: 'VI',
      region: 'NAM',
    },
    {
      name: 'Wallis and Futuna',
      code: 'WF',
      region: 'AP',
    },
    {
      name: 'Western Sahara',
      code: 'EH',
      region: 'MENA',
    },
    {
      name: 'Yemen',
      code: 'YE',
      region: 'MENA',
    },
    {
      name: 'Zambia',
      code: 'ZM',
      region: 'EESSA',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
      region: 'EESSA',
    },
  ];

  export const UNITS = [
    { name: 'API', code: 'API' },
    { name: 'API - US Survey Feet', code: 'API - US Survey Feet' },
    { name: 'Mixed API', code: 'MixedAPI' },
    { name: 'SI', code: 'SI' },
  ];

  export const EVENTS: IAlertEvent[] = [
    {
      label: 'Borehole',
      data: 'Borehole',
      template: [],
      children: [
        {
          label: 'Volumetrics',
          data: 'Volumetrics',
          template: [],
          children: [
            {
              event_id: 'EV01',
              label: 'Flowback Volume',
              data: 'Flowback Volume',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV02',
              label: 'Flow Out',
              data: 'Flow Out',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV03',
              label: 'Active System',
              data: 'Active System',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
          ],
        },
        {
          label: 'Well Control',
          data: 'Well Control',
          template: [],
          children: [
            {
              event_id: 'EV04',
              label: 'Downhole Losses',
              data: 'Downhole Losses',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV05',
              label: 'Influx',
              data: 'Influx',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV06',
              label: 'Ballooning',
              data: 'Ballooning',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV07',
              label: 'Well Shut-In',
              data: 'Well Shut-In',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
          ],
        },
        {
          label: 'Gas',
          data: 'Gas',
          template: [],
          children: [
            {
              event_id: 'EV08',
              label: 'Trip Gas',
              data: 'Trip Gas',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV09',
              label: 'Connection Gas',
              data: 'Connection Gas',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV10',
              label: 'Background Gas',
              data: 'Background Gas',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV11',
              label: 'Pumps Off Gas',
              data: 'Pumps Off Gas',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV12',
              label: 'H2S',
              data: 'H2S',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
          ],
        },
        {
          label: 'Pressure',
          data: 'Pressure',
          template: [],
          children: [
            {
              event_id: 'EV13',
              label: 'Standpipe',
              data: 'Standpipe',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV14',
              label: 'Choke',
              data: 'Choke',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV15',
              label: 'Kill',
              data: 'Kill',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV16',
              label: 'Cementing',
              data: 'Cementing',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
          ],
        },
        {
          label: 'Well Trajectory',
          data: 'Well Trajectory',
          template: [],
          children: [
            {
              event_id: 'EV17',
              label: 'Distance to Plan',
              data: 'Distance to Plan',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV18',
              label: 'Inclination',
              data: 'Inclination',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV19',
              label: 'Azimuth',
              data: 'Azimuth',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV20',
              label: 'Wellpath',
              data: 'Wellpath',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV21',
              label: 'Dogleg Severity',
              data: 'Dogleg Severity',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
          ],
        },
        {
          label: 'Hydraulics',
          data: 'Hydraulics',
          template: [],
          children: [
            {
              event_id: 'EV22',
              label: 'Hydraulics Roadmap',
              data: 'Hydraulics Roadmap',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV23',
              label: 'ECD',
              data: 'ECD',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV24',
              label: 'ESD',
              data: 'ESD',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV25',
              label: 'Surge',
              data: 'Surge',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV26',
              label: 'Swab',
              data: 'Swab',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV27',
              label: 'Density In/Out',
              data: 'Density In/Out',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV28',
              label: 'Rheology',
              data: 'Rheology',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV29',
              label: 'Hole Cleaning',
              data: 'Hole Cleaning',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV30',
              label: 'Annular Velocity',
              data: 'Annular Velocity',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV31',
              label: 'Cuttings Transportation Efficiency',
              data: 'Cuttings Transportation Efficiency',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV32',
              label: 'Cuttings Load',
              data: 'Cuttings Load',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
          ],
        },
        {
          label: 'Hole Condition',
          data: 'Hole Condition',
          template: [],
          children: [
            {
              event_id: 'EV33',
              label: 'Packing Off',
              data: 'Packing Off',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV34',
              label: 'Hole Enlargement',
              data: 'Hole Enlargement',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV35',
              label: 'Cuttings Buildup',
              data: 'Cuttings Buildup',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV36',
              label: 'Cavings',
              data: 'Cavings',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV37',
              label: 'Barite Sag',
              data: 'Barite Sag',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
          ],
        },
        {
          label: 'Torque & Drag',
          data: 'Torque & Drag',
          template: [],
          children: [
            {
              event_id: 'EV38',
              label: 'On-bottom Torque',
              data: 'On-bottom Torque',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV39',
              label: 'Off-bottom Torque',
              data: 'Off-bottom Torque',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV40',
              label: 'Overpull',
              data: 'Overpull',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV41',
              label: 'Tight Spot',
              data: 'Tight Spot',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV42',
              label: 'Hold Up',
              data: 'Hold Up',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV43',
              label: 'Friction Factor',
              data: 'Friction Factor',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
          ],
        },
        {
          label: 'Stuck Pipe',
          data: 'Stuck Pipe',
          template: [],
          children: [
            {
              event_id: 'EV44',
              label: 'Stuck Pipe',
              data: 'Stuck Pipe',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV45',
              label: 'Pack-off',
              data: 'Pack-off',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV46',
              label: 'Differential Sticking',
              data: 'Differential Sticking',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV47',
              label: 'Wellbore Geometry',
              data: 'Wellbore Geometry',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
          ],
        },
        {
          label: 'Geomechanics',
          data: 'Geomechanics',
          template: [],
          children: [
            {
              event_id: 'EV48',
              label: 'Pore Pressure',
              data: 'Pore Pressure',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV49',
              label: 'Fracture Gradient',
              data: 'Fracture Gradient',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV50',
              label: 'Shear Failure Gradient',
              data: 'Shear Failure Gradient',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV51',
              label: 'Wellbore Instability',
              data: 'Wellbore Instability',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
          ],
        },
        {
          event_id: 'EV52',
          label: 'Miscellaneous',
          data: 'Miscellaneous',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
      ],
    },
    {
      label: 'BHA',
      data: 'BHA',
      template: [],
      children: [
        {
          label: 'Operating Limits',
          data: 'Operating Limits',
          template: [],
          children: [
            {
              event_id: 'EV53',
              label: 'Torque',
              data: 'Torque',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV54',
              label: 'Weight on Bit',
              data: 'Weight on Bit',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV55',
              label: 'RPM',
              data: 'RPM',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV56',
              label: 'Flow Rate',
              data: 'Flow Rate',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
          ],
        },
        {
          label: 'Vibration',
          data: 'Vibration',
          template: [],
          children: [
            {
              event_id: 'EV57',
              label: 'Bit Bounce',
              data: 'Bit Bounce',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV58',
              label: 'Whirl',
              data: 'Whirl',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV59',
              label: 'Stick Slip',
              data: 'Stick Slip',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV60',
              label: 'Torsional Resonance',
              data: 'Torsional Resonance',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV61',
              label: 'Lateral Shock',
              data: 'Lateral Shock',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV62',
              label: 'Modal Coupling',
              data: 'Modal Coupling',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
            {
              event_id: 'EV63',
              label: 'Motor Stall',
              data: 'Motor Stall',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default'],
              leaf: false,
            },
          ],
        },
        {
          event_id: 'EV64',
          label: 'Downhole Tool Problems',
          data: 'Downhole Tool Problems',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV65',
          label: 'Downhole Sensor Problems',
          data: 'Downhole Sensor Problems',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV66',
          label: 'Twist Off',
          data: 'Twist Off',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV67',
          label: 'Bit Balling',
          data: 'Bit Balling',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV68',
          label: 'Plugged Bit Nozzle',
          data: 'Plugged Bit Nozzle',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV69',
          label: 'Washout',
          data: 'Washout',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV70',
          label: 'Miscellaneous',
          data: 'Miscellaneous',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
      ],
    },
    {
      label: 'Data Acquisition',
      data: 'Data Acquisition',
      template: [],
      children: [
        {
          event_id: 'EV71',
          label: 'No Data Exchange',
          data: 'No Data Exchange',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV72',
          label: 'Limited Data Exchange',
          data: 'Limited Data Exchange',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV73',
          label: 'No Run Change',
          data: 'No Run Change',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV74',
          label: 'Incorrect Run Number',
          data: 'Incorrect Run Number',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV75',
          label: 'Loss of Communication',
          data: 'Loss of Communication',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV76',
          label: 'Planned Outage',
          data: 'Planned Outage',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV77',
          label: 'Unplanned Outage',
          data: 'Unplanned Outage',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV78',
          label: 'Record/Dataset Missing',
          data: 'Record/Dataset Missing',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV79',
          label: 'Service Inerrupt',
          data: 'Service Inerrupt',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV80',
          label: 'Gas Sensor',
          data: 'Gas Sensor',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV81',
          label: 'Sensor Calibration',
          data: 'Sensor Calibration',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV82',
          label: 'Miscellaneous',
          data: 'Miscellaneous',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
      ],
    },
    {
      label: 'Operational Update',
      data: 'Operational Update',
      template: [],
      children: [
        {
          event_id: 'EV83',
          label: 'Rig Operations',
          data: 'Rig Operations',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV84',
          label: 'Total Depth',
          data: 'Total Depth',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV85',
          label: 'Model Calibration',
          data: 'Model Calibration',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV86',
          label: 'Pit Transfer',
          data: 'Pit Transfer',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV87',
          label: 'Data Quality',
          data: 'Data Quality',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV88',
          label: 'Scheduled Trip',
          data: 'Scheduled Trip',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV89',
          label: 'Unscheduled Trip',
          data: 'Unscheduled Trip',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV90',
          label: 'Short Trip',
          data: 'Short Trip',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV91',
          label: 'Wiper Trip',
          data: 'Wiper Trip',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV92',
          label: 'FIT / LOT Result',
          data: 'FIT / LOT Result',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV93',
          label: 'Miscellaneous',
          data: 'Miscellaneous',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default'],
          leaf: false,
        },
      ],
    },
    {
      label: 'Reports',
      data: 'Reports',
      template: [],
      children: [
        {
          event_id: 'EV94',
          label: 'Daily Report',
          data: 'Daily Report',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'report',
          template: ['default'],
          leaf: false,
        },
        {
          event_id: 'EV95',
          label: 'End of Section Report',
          data: 'End of Section Report',
          children: [],
          styleClass: 'lowestChild',
          template: ['default'],
          eventType: 'report',
          leaf: false,
        },
        {
          event_id: 'EV96',
          label: 'End of Well Report',
          data: 'End of Well Report',
          children: [],
          styleClass: 'lowestChild',
          template: ['default'],
          eventType: 'report',
          leaf: false,
        },
        {
          event_id: 'EV97',
          label: 'Trip Schedule',
          data: 'Trip Schedule',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'roadmap',
          template: ['roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV98',
          label: 'T&D Roadmap',
          data: 'T&D Roadmap',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'roadmap',
          template: ['roadmap'],
          leaf: false,
        },
      ],
    },
    {
      label: 'Flat Time Optimization',
      data: 'Flat Time Optimization',
      template: [],
      children: [
        {
          label: 'Connections',
          data: 'Connections',
          template: [],
          children: [
            {
              event_id: 'EV99',
              label: 'Drill Pipe',
              data: 'Drill Pipe',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV100',
              label: 'Casing Tubulars',
              data: 'Casing Tubulars',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
          ],
        },
        {
          label: 'Tripping',
          data: 'Tripping',
          template: [],
          children: [
            {
              event_id: 'EV101',
              label: 'Optimized Trip Schedule',
              data: 'Optimized Trip Schedule',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV102',
              label: 'Drill Pipe in Cased Hole',
              data: 'Drill Pipe in Cased Hole',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV103',
              label: 'Drill Pipe in Open Hole',
              data: 'Drill Pipe in Open Hole',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV104',
              label: 'Casing in Cased Hole',
              data: 'Casing in Cased Hole',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV105',
              label: 'Casing in Open Hole',
              data: 'Casing in Open Hole',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV106',
              label: 'Make Up BHA',
              data: 'Make Up BHA',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV107',
              label: 'Lay Down BHA',
              data: 'Lay Down BHA',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
          ],
        },
        {
          label: 'BOP',
          data: 'BOP',
          template: [],
          children: [
            {
              event_id: 'EV108',
              label: 'Nipple Up',
              data: 'Nipple Up',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV109',
              label: 'Nipple Down',
              data: 'Nipple Down',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
            {
              event_id: 'EV110',
              label: 'Pressure Test',
              data: 'Pressure Test',
              children: [],
              styleClass: 'lowestChild',
              eventType: 'intervention',
              template: ['default', 'roadmap'],
              leaf: false,
            },
          ],
        },
        {
          event_id: 'EV111',
          label: 'Slip & Cut Drill Line',
          data: 'Slip & Cut Drill Line',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV112',
          label: 'Drill Out Cement / Shoetrack',
          data: 'Drill Out Cement / Shoetrack',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
      ],
    },
    {
      label: 'ROP Optimization',
      data: 'ROP Optimization',
      template: [],
      children: [
        {
          event_id: 'EV113',
          label: 'Weight-to-Weight (W2W)',
          data: 'Weight-to-Weight (W2W)',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV114',
          label: 'Weight-to-Slips (W2S)',
          data: 'Weight-to-Slips (W2S)',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV115',
          label: 'Slips-to-Slips (S2S)',
          data: 'Slips-to-Slips (S2S)',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV116',
          label: 'Slips-to-Weight (S2W)',
          data: 'Slips-to-Weight (S2W)',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV117',
          label: 'Survey',
          data: 'Survey',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV118',
          label: 'Connection Procedure',
          data: 'Connection Procedure',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV119',
          label: 'Reaming',
          data: 'Reaming',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV120',
          label: 'Washing',
          data: 'Washing',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV121',
          label: 'Instantaneous ROP',
          data: 'Instantaneous ROP',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV122',
          label: 'Average ROP',
          data: 'Average ROP',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV123',
          label: 'Drilling Performance',
          data: 'Drilling Performance',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
        {
          event_id: 'EV124',
          label: 'Miscellaneous',
          data: 'Miscellaneous',
          children: [],
          styleClass: 'lowestChild',
          eventType: 'intervention',
          template: ['default', 'roadmap'],
          leaf: false,
        },
      ],
    },
  ];

  export const RISKS = [
    {
      label: 'Borehole Problems',
      data: 'Borehole Problems',
      styleClass: 'parent',
      selectable: false,
      id: 'BOPR',
      children: [
        {
          risk_id: 'BOPR01',
          label: 'Unstable/problematic borehole conditions',
          data: 'Unstable/problematic borehole conditions',
        },
        {
          risk_id: 'BOPR02',
          label: 'Well Trajectory Correction',
          data: 'Well Trajectory Correction',
        },
        {
          risk_id: 'BOPR03',
          label: 'Well Influx -Well Control',
          data: 'Well Influx -Well Control',
        },
        {
          risk_id: 'BOPR04',
          label: 'String running failures',
          data: 'String running failures',
        },
        {
          risk_id: 'BOPR05',
          label: 'Unplanned cement into the String',
          data: 'Unplanned cement into the String',
        },
        {
          risk_id: 'BOPR06',
          label: 'Lost Circulation',
          data: 'Lost Circulation',
        },
        {
          risk_id: 'BOPR07',
          label: 'Bit/BHA Balling',
          data: 'Bit/BHA Balling',
        },
        {
          risk_id: 'BOPR08',
          label: 'Plugged Nozzle/Mud Motor',
          data: 'Plugged Nozzle/Mud Motor',
        },
        {
          risk_id: 'BOPR09',
          label: 'Plugged String',
          data: 'Plugged String',
        },
        { risk_id: 'BOPR10', label: 'Stuck Pipe', data: 'Stuck Pipe' },
        {
          risk_id: 'BOPR11',
          label: 'Stuck/Fish non drilling related equipment',
          data: 'Stuck/Fish non drilling related equipment',
        },
        {
          risk_id: 'BOPR12',
          label: 'Fishing String',
          data: 'Fishing String',
        },
        {
          risk_id: 'BOPR13',
          label: 'Fishing String failed attempt',
          data: 'Fishing String failed attempt',
        },
        {
          risk_id: 'BOPR14',
          label: 'Unplanned hole plug and abandonment',
          data: 'Unplanned hole plug and abandonment',
        },
        {
          risk_id: 'BOPR15',
          label: 'Unplanned Sidetrack Operations',
          data: 'Unplanned Sidetrack Operations',
        },
        { risk_id: 'BOPR16', label: 'Sand Out', data: 'Sand Out' },
        {
          risk_id: 'BOPR17',
          label: 'Completion/Intervention problems',
          data: 'Completion/Intervention problems',
        },
      ],
    },
    {
      label: 'Equipment/Tools/Services',
      data: 'Equipment/Tools/Services',
      styleClass: 'parent',
      selectable: false,
      id: 'ETSE',
      children: [
        {
          risk_id: 'ETSE01',
          label: 'BOP/BOP systems test failure(Rented)',
          data: 'BOP/BOP systems test failure(Rented)',
        },
        {
          risk_id: 'ETSE02',
          label: 'Casing / Tubing Running Equipment/Tools Failures',
          data: 'Casing / Tubing Running Equipment/Tools Failures',
        },
        {
          risk_id: 'ETSE03',
          label: 'Tubulars Related Failures / Damage',
          data: 'Tubulars Related Failures / Damage',
        },
        {
          risk_id: 'ETSE04',
          label: 'Coiled Tubing Failure',
          data: 'Coiled Tubing Failure',
        },
        {
          risk_id: 'ETSE05',
          label: 'Drill String Failure (DP, DC, JARS, STB, NB, ETC)',
          data: 'Drill String Failure (DP, DC, JARS, STB, NB, ETC)',
        },
        {
          risk_id: 'ETSE06',
          label: 'DST (Drill Stem Test) Failure',
          data: 'DST (Drill Stem Test) Failure',
        },
        {
          risk_id: 'ETSE07',
          label: 'Directional Drilling Equipment Failure',
          data: 'Directional Drilling Equipment Failure',
        },
        {
          risk_id: 'ETSE08',
          label: 'Casing / Liner Hangers Failure',
          data: 'Casing / Liner Hangers Failure',
        },
        {
          risk_id: 'ETSE09',
          label: 'Drilling Fluids Service Problem',
          data: 'Drilling Fluids Service Problem',
        },
        {
          risk_id: 'ETSE10',
          label: 'Casing Running Services Failure',
          data: 'Casing Running Services Failure',
        },
        {
          risk_id: 'ETSE11',
          label: 'Packers / Retainers / Plugs Failure (ECP, Top PKRS, etc)',
          data: 'Packers / Retainers / Plugs Failure (ECP, Top PKRS, etc)',
        },
        {
          risk_id: 'ETSE12',
          label: 'Perforating/ Miss-Run (TCP or WL)',
          data: 'Perforating/ Miss-Run (TCP or WL)',
        },
        {
          risk_id: 'ETSE13',
          label: 'Production enhancement Equipment/Services Failure',
          data: 'Production enhancement Equipment/Services Failure',
        },
        {
          risk_id: 'ETSE14',
          label: 'Rotating Head failure',
          data: 'Rotating Head failure',
        },
        {
          risk_id: 'ETSE15',
          label: 'Sidetrack Tool Failure (Whipstok, Window Opener, etc)',
          data: 'Sidetrack Tool Failure (Whipstok, Window Opener, etc)',
        },
        {
          risk_id: 'ETSE16',
          label: 'Slick Line Failure',
          data: 'Slick Line Failure',
        },
        {
          risk_id: 'ETSE17',
          label: 'Wireline/Logging Failure',
          data: 'Wireline/Logging Failure',
        },
        {
          risk_id: 'ETSE18',
          label: 'Cementing Surface Equipment Failure',
          data: 'Cementing Surface Equipment Failure',
        },
        {
          risk_id: 'ETSE19',
          label: 'Unplanned Bit Replacement',
          data: 'Unplanned Bit Replacement',
        },
        {
          risk_id: 'ETSE20',
          label: 'Wellhead Failure/Test Failure',
          data: 'Wellhead Failure/Test Failure',
        },
        {
          risk_id: 'ETSE21',
          label: 'Cementing Downhole Equipment Failure',
          data: 'Cementing Downhole Equipment Failure',
        },
        {
          risk_id: 'ETSE22',
          label: 'Production Enhancement Equipment',
          data: 'Production Enhancement Equipment',
        },
        {
          risk_id: 'ETSE23',
          label: 'Completion Tool/Equipment Damaged',
          data: 'Completion Tool/Equipment Damaged',
        },
        {
          risk_id: 'ETSE24',
          label: 'Completion Tool/Equipment Failure',
          data: 'Completion Tool/Equipment Failure',
        },
        {
          risk_id: 'ETSE25',
          label: 'Inadequate Service Operation',
          data: 'Inadequate Service Operation',
        },
        {
          risk_id: 'ETSE26',
          label: 'Inefficient Operation',
          data: 'Inefficient Operation',
        },
        {
          risk_id: 'ETSE27',
          label: 'Surface Services Failure',
          data: 'Surface Services Failure',
        },
      ],
    },
    {
      label: 'HSE',
      data: 'HSE',
      styleClass: 'parent',
      selectable: false,
      id: 'HSEH',
      children: [
        {
          risk_id: 'HSEH01',
          label: 'Health incident',
          data: 'Health incident',
        },
        {
          risk_id: 'HSEH02',
          label: 'Safety/Environment',
          data: 'Safety/Environment',
        },
        {
          risk_id: 'HSEH03',
          label: 'Security',
          data: 'Security',
        },
      ],
    },
    {
      label: 'Non Operational',
      data: 'Non Operational',
      styleClass: 'parent',
      selectable: false,
      id: 'NOOP',
      children: [
        {
          risk_id: 'NOOP01',
          label: 'Community/Strikes',
          data: 'Community/Strikes',
        },
        {
          risk_id: 'NOOP02',
          label: 'Force Majeure',
          data: 'Force Majeure',
        },
        {
          risk_id: 'NOOP03',
          label: 'Non Compliance to contractual requirements',
          data: 'Non Compliance to contractual requirements',
        },
        {
          risk_id: 'NOOP04',
          label: 'Social unrest',
          data: 'Social unrest',
        },
        {
          risk_id: 'NOOP05',
          label: 'Waiting on Daylight',
          data: 'Waiting on Daylight',
        },
        {
          risk_id: 'NOOP06',
          label: 'Waiting on Weather (WOW)',
          data: 'Waiting on Weather (WOW)',
        },
        {
          risk_id: 'NOOP07',
          label: 'Suspended Operations - Stand By',
          data: 'Suspended Operations - Stand By',
        },
      ],
    },
    {
      label: 'Readiness',
      data: 'Readiness',
      styleClass: 'parent',
      selectable: false,
      id: 'READ',
      children: [
        {
          risk_id: 'READ01',
          label: 'Personnel not Ready',
          data: 'Personnel not Ready',
        },
        {
          risk_id: 'READ02',
          label: 'Materials not Ready',
          data: 'Materials not Ready',
        },
        {
          risk_id: 'READ03',
          label: 'Equipment not Ready',
          data: 'Equipment not Ready',
        },
        {
          risk_id: 'READ04',
          label: 'Service not Ready',
          data: 'Service not Ready',
        },
        {
          risk_id: 'READ05',
          label: 'Program not Ready',
          data: 'Program not Ready',
        },
        {
          risk_id: 'READ07',
          label: 'Location not Ready',
          data: 'Location not Ready',
        },
      ],
    },
    {
      label: 'Rig Related Failures',
      data: 'Rig Related Failures',
      styleClass: 'parent',
      selectable: false,
      id: 'RRFA',
      children: [
        {
          risk_id: 'RRFA01',
          label: 'Circulation System Failure',
          data: 'Circulation System Failure',
        },
        {
          risk_id: 'RRFA02',
          label: 'Derrick and Substructure Failure',
          data: 'Derrick and Substructure Failure',
        },
        {
          risk_id: 'RRFA03',
          label: 'Electronic System Failure',
          data: 'Electronic System Failure',
        },
        {
          risk_id: 'RRFA04',
          label: 'Hoisting System Failure',
          data: 'Hoisting System Failure',
        },
        {
          risk_id: 'RRFA05',
          label: 'Miscellaneous Equipment Failure',
          data: 'Miscellaneous Equipment Failure',
        },
        {
          risk_id: 'RRFA06',
          label: 'Positioning System Failure',
          data: 'Positioning System Failure',
        },
        {
          risk_id: 'RRFA07',
          label: 'Power System Failure',
          data: 'Power System Failure',
        },
        {
          risk_id: 'RRFA08',
          label: 'Rotary System Failure',
          data: 'Rotary System Failure',
        },
        {
          risk_id: 'RRFA09',
          label: 'Safety System Failure',
          data: 'Safety System Failure',
        },
        {
          risk_id: 'RRFA10',
          label: 'Well Control System Failure',
          data: 'Well Control System Failure',
        },
        {
          risk_id: 'RRFA11',
          label: 'Rig Readiness',
          data: 'Rig Readiness',
        },
        {
          risk_id: 'RRFA12',
          label: 'Inadequate Operation',
          data: 'Inadequate Operation',
        },
        {
          risk_id: 'RRFA13',
          label: 'Inefficient Operation',
          data: 'Inefficient Operation',
        },
      ],
    },
    {
      label: 'Well Integrity',
      data: 'Well Integrity',
      styleClass: 'parent',
      selectable: false,
      id: 'WESI',
      children: [
        {
          risk_id: 'WESI01',
          label: 'Casing / Liner Integrity',
          data: 'Casing / Liner Integrity',
        },
        {
          risk_id: 'WESI02',
          label: 'Casing / Liner Setting Depth not as per Well Design / Plan',
          data: 'Casing / Liner Setting Depth not as per Well Design / Plan',
        },
        {
          risk_id: 'WESI03',
          label: 'Casing Depth Selection (Well Design)',
          data: 'Casing Depth Selection (Well Design)',
        },
        {
          risk_id: 'WESI04',
          label: 'Cement Fail to achieve Zonal Isolation',
          data: 'Cement Fail to achieve Zonal Isolation',
        },
        {
          risk_id: 'WESI05',
          label: 'Cement Plug Failure',
          data: 'Cement Plug Failure',
        },
        {
          risk_id: 'WESI06',
          label: 'Insufficient Formation integrity',
          data: 'Insufficient Formation integrity',
        },
        {
          risk_id: 'WESI07',
          label: 'Liner hangers / permanent packers / plugs fail to isolate',
          data: 'Liner hangers / permanent packers / plugs fail to isolate',
        },
        {
          risk_id: 'WESI08',
          label: 'Well Collision ',
          data: 'Well Collision ',
        },
        {
          risk_id: 'WESI09',
          label: 'Wellhead - Xmas Tree Failure',
          data: 'Wellhead - Xmas Tree Failure',
        },
      ],
    },
  ];

  export const OPERATIONS = [
    {
      label: 'Drilling',
      data: 'Drilling',
      children: [
        {
          operation_id: 'OP01',
          label: 'Drilling Ahead',
          data: 'Drilling Ahead',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP02',
          label: 'Drilling Directionally',
          data: 'Drilling Directionally',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP03',
          label: 'Drilling Vertically',
          data: 'Drilling Vertically',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP04',
          label: 'Sliding',
          data: 'Sliding',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP05',
          label: 'Sliding/Rotating',
          data: 'Sliding/Rotating',
          styleClass: 'lowestChild',
          leaf: false,
        },
      ],
    },
    {
      label: 'Tripping in Hole',
      data: 'Tripping in Hole',
      children: [
        {
          operation_id: 'OP06',
          label: 'Tripping in with Drilling Assembly',
          data: 'Tripping in with Drilling Assembly',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP07',
          label: 'Tripping in with Cleanout Assembly',
          data: 'Tripping in with Cleanout Assembly',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP08',
          label: 'Tripping in with Liner Running String',
          data: 'Tripping in with Liner Running String',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP09',
          label: 'Running Riser',
          data: 'Running Riser',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP10',
          label: 'Running Casing',
          data: 'Running Casing',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP11',
          label: 'Running Liner',
          data: 'Running Liner',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP12',
          label: 'Making up BHA',
          data: 'Making up BHA',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP13',
          label: 'Making up Drill Pipe',
          data: 'Making up Drill Pipe',
          styleClass: 'lowestChild',
          leaf: false,
        },
      ],
    },
    {
      label: 'Pulling out of Hole',
      data: 'Pulling out of Hole',
      children: [
        {
          operation_id: 'OP14',
          label: 'Pulling out with Drilling Assembly',
          data: 'Pulling out with Drilling Assembly',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP15',
          label: 'Pulling out with Cleanout Assembly',
          data: 'Pulling out with Cleanout Assembly',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP33',
          label: 'Pulling out with Setting/Landing Tool',
          data: 'Pulling out with Setting/Landing Tool',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP16',
          label: 'Backreaming out of Hole',
          data: 'Backreaming out of Hole',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP17',
          label: 'Pumping out of hole',
          data: 'Pumping out of hole',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP18',
          label: 'Pulling Riser',
          data: 'Pulling Riser',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP19',
          label: 'Pulling Casing',
          data: 'Pulling Casing',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP20',
          label: 'Pulling Liner',
          data: 'Pulling Liner',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP21',
          label: 'Laying Down BHA',
          data: 'Laying Down BHA',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP22',
          label: 'Laying Down Drill Pipe',
          data: 'Laying Down Drill Pipe',
          styleClass: 'lowestChild',
          leaf: false,
        },
      ],
    },
    {
      label: 'Other Operation',
      data: 'Other Operation',
      children: [
        {
          operation_id: 'OP23',
          label: 'Conditioning the Mud',
          data: 'Conditioning the Mud',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP24',
          label: 'Circulating the Hole',
          data: 'Circulating the Hole',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP25',
          label: 'Displacing Mud System',
          data: 'Displacing Mud System',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP26',
          label: 'Weighting Up',
          data: 'Weighting Up',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP27',
          label: 'Wiper Trip',
          data: 'Wiper Trip',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP28',
          label: 'Short Trip',
          data: 'Short Trip',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP29',
          label: 'Measurement-after-Drilling Pass',
          data: 'Measurement-after-Drilling Pass',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP30',
          label: 'Trip due to Tool Failure',
          data: 'Trip due to Tool Failure',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP31',
          label: 'Trip due to Rig Equipment',
          data: 'Trip due to Rig Equipment',
          styleClass: 'lowestChild',
          leaf: false,
        },
        {
          operation_id: 'OP32',
          label: 'Trip due to Bit',
          data: 'Trip due to Bit',
          styleClass: 'lowestChild',
          leaf: false,
        },
      ],
    },
  ];

  export const TYPES = [
    { type_id: 'TYPE01', data: 'Rotary Steerable BHA', type: 'Drilling' },
    { type_id: 'TYPE02', data: 'Motor BHA', type: 'Drilling' },
    { type_id: 'TYPE03', data: 'MARSS BHA', type: 'Drilling' },
    { type_id: 'TYPE04', data: 'Wellbore Cleanout', type: 'Non-Drilling' },
    { type_id: 'TYPE05', data: 'Logging BHA', type: 'Non-Drilling' },
    { type_id: 'TYPE06', data: 'Casing', type: 'Non-Drilling' },
    { type_id: 'TYPE07', data: 'Liner', type: 'Non-Drilling' },
    { type_id: 'TYPE08', data: 'Tubing', type: 'Non-Drilling' },
    { type_id: 'TYPE09', data: 'Riser', type: 'Non-Drilling' },
    { type_id: 'TYPE10', data: 'Cement Stinger', type: 'Non-Drilling' },
    { type_id: 'TYPE11', data: 'TCP BHA', type: 'Non-Drilling' },
    { type_id: 'TYPE12', data: 'Upper Completion', type: 'Non-Drilling' },
    { type_id: 'TYPE13', data: 'Lower Completion', type: 'Non-Drilling' },
    { type_id: 'TYPE14', data: 'Scraper BHA', type: 'Non-Drilling' },
    { type_id: 'TYPE15', data: 'ESTMZ Completion System', type: 'Non-Drilling' },
    { type_id: 'TYPE16', data: 'Fishing Assembly', type: 'Non-Drilling' },
    { type_id: 'TYPE17', data: 'Wireline', type: 'Non-Drilling' },
    { type_id: 'TYPE18', data: 'Rotary BHA', type: 'Drilling' },
    { type_id: 'TYPE19', data: 'Milling BHA', type: 'Drilling' },
    { type_id: 'TYPE20', data: 'Whipstock BHA', type: 'Non-Drilling' },
    { type_id: 'TYPE21', data: 'Whipstock System', type: 'Drilling' },
    { type_id: 'TYPE22', data: 'Coring BHA', type: 'Drilling' },
  ];

  export const CONTACT_METHOD = [
    {
      label: 'Phone',
      data: 'Phone',
      styleClass: 'parent_', // Remove _ to open checkbox
      id: 'phone',
      children: [
        {
          id: 'C1',
          label: 'C1 - Drilling Supervisor',
          data: 'Drilling Supervisor',
        },
        {
          id: 'C2',
          label: 'C2 - Drilling Superintendent',
          data: 'Drilling Superintendent',
        },
        {
          id: 'C3',
          label: 'C3 - Drilling Engineer',
          data: 'Drilling Engineer',
        },
        {
          id: 'E1',
          label: 'E1 - Project Manager',
          data: 'Project Manager',
        },
        {
          id: 'E2',
          label: 'E2 - Operations Manager',
          data: 'Operations Manager',
        },
        {
          id: 'E3',
          label: 'E3 - Region Manager',
          data: 'Region Manager',
        },
      ],
    },
    {
      label: 'Email',
      data: 'Email',
      styleClass: 'parent_',
      id: 'email',
    },
    {
      label: 'Chat',
      data: 'Chat',
      styleClass: 'parent_',
      id: 'chat',
    },
  ];

  export const CONTACT_METHOD_WITHOUT_PHONE = [
    {
      label: 'Email',
      data: 'Email',
      styleClass: 'parent_',
      id: 'email',
    },
    {
      label: 'Chat',
      data: 'Chat',
      styleClass: 'parent_',
      id: 'chat',
    },
  ];

  export const PHONE_COUNTRIES = [
    {
      name: 'Afghanistan',
      dial_code: '+93',
      code: 'AF',
    },
    {
      name: 'Aland Islands',
      dial_code: '+358',
      code: 'AX',
    },
    {
      name: 'Albania',
      dial_code: '+355',
      code: 'AL',
    },
    {
      name: 'Algeria',
      dial_code: '+213',
      code: 'DZ',
    },
    {
      name: 'AmericanSamoa',
      dial_code: '+1684',
      code: 'AS',
    },
    {
      name: 'Andorra',
      dial_code: '+376',
      code: 'AD',
    },
    {
      name: 'Angola',
      dial_code: '+244',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      dial_code: '+1264',
      code: 'AI',
    },
    {
      name: 'Antigua and Barbuda',
      dial_code: '+1268',
      code: 'AG',
    },
    {
      name: 'Argentina',
      dial_code: '+54',
      code: 'AR',
    },
    {
      name: 'Armenia',
      dial_code: '+374',
      code: 'AM',
    },
    {
      name: 'Aruba',
      dial_code: '+297',
      code: 'AW',
    },
    {
      name: 'Australia',
      dial_code: '+61',
      code: 'AU',
    },
    {
      name: 'Austria',
      dial_code: '+43',
      code: 'AT',
    },
    {
      name: 'Azerbaijan',
      dial_code: '+994',
      code: 'AZ',
    },
    {
      name: 'Bahamas',
      dial_code: '+1242',
      code: 'BS',
    },
    {
      name: 'Bahrain',
      dial_code: '+973',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      dial_code: '+880',
      code: 'BD',
    },
    {
      name: 'Barbados',
      dial_code: '+1246',
      code: 'BB',
    },
    {
      name: 'Belarus',
      dial_code: '+375',
      code: 'BY',
    },
    {
      name: 'Belgium',
      dial_code: '+32',
      code: 'BE',
    },
    {
      name: 'Belize',
      dial_code: '+501',
      code: 'BZ',
    },
    {
      name: 'Benin',
      dial_code: '+229',
      code: 'BJ',
    },
    {
      name: 'Bermuda',
      dial_code: '+1441',
      code: 'BM',
    },
    {
      name: 'Bhutan',
      dial_code: '+975',
      code: 'BT',
    },
    {
      name: 'Bolivia, Plurinational State of',
      dial_code: '+591',
      code: 'BO',
    },
    {
      name: 'Bosnia and Herzegovina',
      dial_code: '+387',
      code: 'BA',
    },
    {
      name: 'Botswana',
      dial_code: '+267',
      code: 'BW',
    },
    {
      name: 'Brazil',
      dial_code: '+55',
      code: 'BR',
    },
    {
      name: 'British Indian Ocean Territory',
      dial_code: '+246',
      code: 'IO',
    },
    {
      name: 'Brunei Darussalam',
      dial_code: '+673',
      code: 'BN',
    },
    {
      name: 'Bulgaria',
      dial_code: '+359',
      code: 'BG',
    },
    {
      name: 'Burkina Faso',
      dial_code: '+226',
      code: 'BF',
    },
    {
      name: 'Burundi',
      dial_code: '+257',
      code: 'BI',
    },
    {
      name: 'Cambodia',
      dial_code: '+855',
      code: 'KH',
    },
    {
      name: 'Cameroon',
      dial_code: '+237',
      code: 'CM',
    },
    {
      name: 'Canada',
      dial_code: '+11',
      replace_dial_code: '+1', // Actual code is  +1 But need cheet code to dont duplicate with dial USA's dial code
      code: 'CA',
    },
    {
      name: 'Cape Verde',
      dial_code: '+238',
      code: 'CV',
    },
    {
      name: 'Cayman Islands',
      dial_code: '+ 345',
      code: 'KY',
    },
    {
      name: 'Central African Republic',
      dial_code: '+236',
      code: 'CF',
    },
    {
      name: 'Chad',
      dial_code: '+235',
      code: 'TD',
    },
    {
      name: 'Chile',
      dial_code: '+56',
      code: 'CL',
    },
    {
      name: 'China',
      dial_code: '+86',
      code: 'CN',
    },
    {
      name: 'Christmas Island',
      dial_code: '+61',
      code: 'CX',
    },
    {
      name: 'Cocos (Keeling) Islands',
      dial_code: '+61',
      code: 'CC',
    },
    {
      name: 'Colombia',
      dial_code: '+57',
      code: 'CO',
    },
    {
      name: 'Comoros',
      dial_code: '+269',
      code: 'KM',
    },
    {
      name: 'Congo',
      dial_code: '+242',
      code: 'CG',
    },
    {
      name: 'Congo, The Democratic Republic of the Congo',
      dial_code: '+243',
      code: 'CD',
    },
    {
      name: 'Cook Islands',
      dial_code: '+682',
      code: 'CK',
    },
    {
      name: 'Costa Rica',
      dial_code: '+506',
      code: 'CR',
    },
    {
      name: "Cote d'Ivoire",
      dial_code: '+225',
      code: 'CI',
    },
    {
      name: 'Croatia',
      dial_code: '+385',
      code: 'HR',
    },
    {
      name: 'Cuba',
      dial_code: '+53',
      code: 'CU',
    },
    {
      name: 'Cyprus',
      dial_code: '+357',
      code: 'CY',
    },
    {
      name: 'Czech Republic',
      dial_code: '+420',
      code: 'CZ',
    },
    {
      name: 'Denmark',
      dial_code: '+45',
      code: 'DK',
    },
    {
      name: 'Djibouti',
      dial_code: '+253',
      code: 'DJ',
    },
    {
      name: 'Dominica',
      dial_code: '+1767',
      code: 'DM',
    },
    {
      name: 'Dominican Republic',
      dial_code: '+1849',
      code: 'DO',
    },
    {
      name: 'Ecuador',
      dial_code: '+593',
      code: 'EC',
    },
    {
      name: 'Egypt',
      dial_code: '+20',
      code: 'EG',
    },
    {
      name: 'El Salvador',
      dial_code: '+503',
      code: 'SV',
    },
    {
      name: 'Equatorial Guinea',
      dial_code: '+240',
      code: 'GQ',
    },
    {
      name: 'Eritrea',
      dial_code: '+291',
      code: 'ER',
    },
    {
      name: 'Estonia',
      dial_code: '+372',
      code: 'EE',
    },
    {
      name: 'Ethiopia',
      dial_code: '+251',
      code: 'ET',
    },
    {
      name: 'Falkland Islands (Malvinas)',
      dial_code: '+500',
      code: 'FK',
    },
    {
      name: 'Faroe Islands',
      dial_code: '+298',
      code: 'FO',
    },
    {
      name: 'Fiji',
      dial_code: '+679',
      code: 'FJ',
    },
    {
      name: 'Finland',
      dial_code: '+358',
      code: 'FI',
    },
    {
      name: 'France',
      dial_code: '+33',
      code: 'FR',
    },
    {
      name: 'French Guiana',
      dial_code: '+594',
      code: 'GF',
    },
    {
      name: 'French Polynesia',
      dial_code: '+689',
      code: 'PF',
    },
    {
      name: 'Gabon',
      dial_code: '+241',
      code: 'GA',
    },
    {
      name: 'Gambia',
      dial_code: '+220',
      code: 'GM',
    },
    {
      name: 'Georgia',
      dial_code: '+995',
      code: 'GE',
    },
    {
      name: 'Germany',
      dial_code: '+49',
      code: 'DE',
    },
    {
      name: 'Ghana',
      dial_code: '+233',
      code: 'GH',
    },
    {
      name: 'Gibraltar',
      dial_code: '+350',
      code: 'GI',
    },
    {
      name: 'Greece',
      dial_code: '+30',
      code: 'GR',
    },
    {
      name: 'Greenland',
      dial_code: '+299',
      code: 'GL',
    },
    {
      name: 'Grenada',
      dial_code: '+1473',
      code: 'GD',
    },
    {
      name: 'Guadeloupe',
      dial_code: '+590',
      code: 'GP',
    },
    {
      name: 'Guam',
      dial_code: '+1671',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      dial_code: '+502',
      code: 'GT',
    },
    // {
    //   name: 'Guernsey',
    //   dial_code: '+44',
    //   code: 'GG',
    // },
    {
      name: 'Guinea',
      dial_code: '+224',
      code: 'GN',
    },
    {
      name: 'Guinea-Bissau',
      dial_code: '+245',
      code: 'GW',
    },
    {
      name: 'Guyana',
      dial_code: '+595',
      code: 'GY',
    },
    {
      name: 'Haiti',
      dial_code: '+509',
      code: 'HT',
    },
    {
      name: 'Holy See (Vatican City State)',
      dial_code: '+379',
      code: 'VA',
    },
    {
      name: 'Honduras',
      dial_code: '+504',
      code: 'HN',
    },
    {
      name: 'Hong Kong',
      dial_code: '+852',
      code: 'HK',
    },
    {
      name: 'Hungary',
      dial_code: '+36',
      code: 'HU',
    },
    {
      name: 'Iceland',
      dial_code: '+354',
      code: 'IS',
    },
    {
      name: 'India',
      dial_code: '+91',
      code: 'IN',
    },
    {
      name: 'Indonesia',
      dial_code: '+62',
      code: 'ID',
    },
    {
      name: 'Iran, Islamic Republic of Persian Gulf',
      dial_code: '+98',
      code: 'IR',
    },
    {
      name: 'Iraq',
      dial_code: '+964',
      code: 'IQ',
    },
    {
      name: 'Ireland',
      dial_code: '+353',
      code: 'IE',
    },
    // {
    //   name: 'Isle of Man',
    //   dial_code: '+44',
    //   code: 'IM',
    // },
    {
      name: 'Israel',
      dial_code: '+972',
      code: 'IL',
    },
    {
      name: 'Italy',
      dial_code: '+39',
      code: 'IT',
    },
    {
      name: 'Jamaica',
      dial_code: '+1876',
      code: 'JM',
    },
    {
      name: 'Japan',
      dial_code: '+81',
      code: 'JP',
    },
    // {
    //   name: 'Jersey',
    //   dial_code: '+44',
    //   code: 'JE',
    // },
    {
      name: 'Jordan',
      dial_code: '+962',
      code: 'JO',
    },
    {
      name: 'Kazakhstan',
      dial_code: '+77',
      code: 'KZ',
    },
    {
      name: 'Kenya',
      dial_code: '+254',
      code: 'KE',
    },
    {
      name: 'Kiribati',
      dial_code: '+686',
      code: 'KI',
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: '+850',
      code: 'KP',
    },
    {
      name: 'Korea, Republic of South Korea',
      dial_code: '+82',
      code: 'KR',
    },
    {
      name: 'Kuwait',
      dial_code: '+965',
      code: 'KW',
    },
    {
      name: 'Kyrgyzstan',
      dial_code: '+996',
      code: 'KG',
    },
    {
      name: 'Laos',
      dial_code: '+856',
      code: 'LA',
    },
    {
      name: 'Latvia',
      dial_code: '+371',
      code: 'LV',
    },
    {
      name: 'Lebanon',
      dial_code: '+961',
      code: 'LB',
    },
    {
      name: 'Lesotho',
      dial_code: '+266',
      code: 'LS',
    },
    {
      name: 'Liberia',
      dial_code: '+231',
      code: 'LR',
    },
    {
      name: 'Libyan Arab Jamahiriya',
      dial_code: '+218',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      dial_code: '+423',
      code: 'LI',
    },
    {
      name: 'Lithuania',
      dial_code: '+370',
      code: 'LT',
    },
    {
      name: 'Luxembourg',
      dial_code: '+352',
      code: 'LU',
    },
    {
      name: 'Macao',
      dial_code: '+853',
      code: 'MO',
    },
    {
      name: 'Macedonia',
      dial_code: '+389',
      code: 'MK',
    },
    {
      name: 'Madagascar',
      dial_code: '+261',
      code: 'MG',
    },
    {
      name: 'Malawi',
      dial_code: '+265',
      code: 'MW',
    },
    {
      name: 'Malaysia',
      dial_code: '+60',
      code: 'MY',
    },
    {
      name: 'Maldives',
      dial_code: '+960',
      code: 'MV',
    },
    {
      name: 'Mali',
      dial_code: '+223',
      code: 'ML',
    },
    {
      name: 'Malta',
      dial_code: '+356',
      code: 'MT',
    },
    {
      name: 'Marshall Islands',
      dial_code: '+692',
      code: 'MH',
    },
    {
      name: 'Martinique',
      dial_code: '+596',
      code: 'MQ',
    },
    {
      name: 'Mauritania',
      dial_code: '+222',
      code: 'MR',
    },
    {
      name: 'Mauritius',
      dial_code: '+230',
      code: 'MU',
    },
    {
      name: 'Mayotte',
      dial_code: '+262',
      code: 'YT',
    },
    {
      name: 'Mexico',
      dial_code: '+52',
      code: 'MX',
    },
    {
      name: 'Micronesia, Federated States of Micronesia',
      dial_code: '+691',
      code: 'FM',
    },
    {
      name: 'Moldova',
      dial_code: '+373',
      code: 'MD',
    },
    {
      name: 'Monaco',
      dial_code: '+377',
      code: 'MC',
    },
    {
      name: 'Mongolia',
      dial_code: '+976',
      code: 'MN',
    },
    {
      name: 'Montenegro',
      dial_code: '+382',
      code: 'ME',
    },
    {
      name: 'Montserrat',
      dial_code: '+1664',
      code: 'MS',
    },
    {
      name: 'Morocco',
      dial_code: '+212',
      code: 'MA',
    },
    {
      name: 'Mozambique',
      dial_code: '+258',
      code: 'MZ',
    },
    {
      name: 'Myanmar',
      dial_code: '+95',
      code: 'MM',
    },
    {
      name: 'Namibia',
      dial_code: '+264',
      code: 'NA',
    },
    {
      name: 'Nauru',
      dial_code: '+674',
      code: 'NR',
    },
    {
      name: 'Nepal',
      dial_code: '+977',
      code: 'NP',
    },
    {
      name: 'Netherlands',
      dial_code: '+31',
      code: 'NL',
    },
    {
      name: 'Netherlands Antilles',
      dial_code: '+599',
      code: 'AN',
    },
    {
      name: 'New Caledonia',
      dial_code: '+687',
      code: 'NC',
    },
    {
      name: 'New Zealand',
      dial_code: '+64',
      code: 'NZ',
    },
    {
      name: 'Nicaragua',
      dial_code: '+505',
      code: 'NI',
    },
    {
      name: 'Niger',
      dial_code: '+227',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      dial_code: '+234',
      code: 'NG',
    },
    {
      name: 'Niue',
      dial_code: '+683',
      code: 'NU',
    },
    {
      name: 'Norfolk Island',
      dial_code: '+672',
      code: 'NF',
    },
    {
      name: 'Northern Mariana Islands',
      dial_code: '+1670',
      code: 'MP',
    },
    {
      name: 'Norway',
      dial_code: '+47',
      code: 'NO',
    },
    {
      name: 'Oman',
      dial_code: '+968',
      code: 'OM',
    },
    {
      name: 'Pakistan',
      dial_code: '+92',
      code: 'PK',
    },
    {
      name: 'Palau',
      dial_code: '+680',
      code: 'PW',
    },
    {
      name: 'Palestinian Territory, Occupied',
      dial_code: '+970',
      code: 'PS',
    },
    {
      name: 'Panama',
      dial_code: '+507',
      code: 'PA',
    },
    {
      name: 'Papua New Guinea',
      dial_code: '+675',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      dial_code: '+595',
      code: 'PY',
    },
    {
      name: 'Peru',
      dial_code: '+51',
      code: 'PE',
    },
    {
      name: 'Philippines',
      dial_code: '+63',
      code: 'PH',
    },
    {
      name: 'Pitcairn',
      dial_code: '+872',
      code: 'PN',
    },
    {
      name: 'Poland',
      dial_code: '+48',
      code: 'PL',
    },
    {
      name: 'Portugal',
      dial_code: '+351',
      code: 'PT',
    },
    {
      name: 'Puerto Rico',
      dial_code: '+1939',
      code: 'PR',
    },
    {
      name: 'Qatar',
      dial_code: '+974',
      code: 'QA',
    },
    {
      name: 'Romania',
      dial_code: '+40',
      code: 'RO',
    },
    {
      name: 'Russia',
      dial_code: '+7',
      code: 'RU',
    },
    {
      name: 'Rwanda',
      dial_code: '+250',
      code: 'RW',
    },
    {
      name: 'Reunion',
      dial_code: '+262',
      code: 'RE',
    },
    // {
    //   name: 'Saint Barthelemy',
    //   dial_code: '+590',
    //   code: 'BL',
    // },
    {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      dial_code: '+290',
      code: 'SH',
    },
    {
      name: 'Saint Kitts and Nevis',
      dial_code: '+1869',
      code: 'KN',
    },
    {
      name: 'Saint Lucia',
      dial_code: '+1758',
      code: 'LC',
    },
    // {
    //   name: 'Saint Martin',
    //   dial_code: '+590',
    //   code: 'MF',
    // },
    {
      name: 'Saint Pierre and Miquelon',
      dial_code: '+508',
      code: 'PM',
    },
    {
      name: 'Saint Vincent and the Grenadines',
      dial_code: '+1784',
      code: 'VC',
    },
    {
      name: 'Samoa',
      dial_code: '+685',
      code: 'WS',
    },
    {
      name: 'San Marino',
      dial_code: '+378',
      code: 'SM',
    },
    {
      name: 'Sao Tome and Principe',
      dial_code: '+239',
      code: 'ST',
    },
    {
      name: 'Saudi Arabia',
      dial_code: '+966',
      code: 'SA',
    },
    {
      name: 'Senegal',
      dial_code: '+221',
      code: 'SN',
    },
    {
      name: 'Serbia',
      dial_code: '+381',
      code: 'RS',
    },
    {
      name: 'Seychelles',
      dial_code: '+248',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      dial_code: '+232',
      code: 'SL',
    },
    {
      name: 'Singapore',
      dial_code: '+65',
      code: 'SG',
    },
    {
      name: 'Slovakia',
      dial_code: '+421',
      code: 'SK',
    },
    {
      name: 'Slovenia',
      dial_code: '+386',
      code: 'SI',
    },
    {
      name: 'Solomon Islands',
      dial_code: '+677',
      code: 'SB',
    },
    {
      name: 'Somalia',
      dial_code: '+252',
      code: 'SO',
    },
    {
      name: 'South Africa',
      dial_code: '+27',
      code: 'ZA',
    },
    // {
    //   name: 'South Sudan',
    //   dial_code: '+211',
    //   code: 'SS',
    // },
    {
      name: 'South Georgia and the South Sandwich Islands',
      dial_code: '+500',
      code: 'GS',
    },
    {
      name: 'Spain',
      dial_code: '+34',
      code: 'ES',
    },
    {
      name: 'Sri Lanka',
      dial_code: '+94',
      code: 'LK',
    },
    {
      name: 'Sudan',
      dial_code: '+249',
      code: 'SD',
    },
    {
      name: 'Suriname',
      dial_code: '+597',
      code: 'SR',
    },
    {
      name: 'Svalbard and Jan Mayen',
      dial_code: '+47',
      code: 'SJ',
    },
    {
      name: 'Swaziland',
      dial_code: '+268',
      code: 'SZ',
    },
    {
      name: 'Sweden',
      dial_code: '+46',
      code: 'SE',
    },
    {
      name: 'Switzerland',
      dial_code: '+41',
      code: 'CH',
    },
    {
      name: 'Syrian Arab Republic',
      dial_code: '+963',
      code: 'SY',
    },
    {
      name: 'Taiwan',
      dial_code: '+886',
      code: 'TW',
    },
    {
      name: 'Tajikistan',
      dial_code: '+992',
      code: 'TJ',
    },
    {
      name: 'Tanzania, United Republic of Tanzania',
      dial_code: '+255',
      code: 'TZ',
    },
    {
      name: 'Thailand',
      dial_code: '+66',
      code: 'TH',
    },
    {
      name: 'Timor-Leste',
      dial_code: '+670',
      code: 'TL',
    },
    {
      name: 'Togo',
      dial_code: '+228',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      dial_code: '+690',
      code: 'TK',
    },
    {
      name: 'Tonga',
      dial_code: '+676',
      code: 'TO',
    },
    {
      name: 'Trinidad and Tobago',
      dial_code: '+1868',
      code: 'TT',
    },
    {
      name: 'Tunisia',
      dial_code: '+216',
      code: 'TN',
    },
    {
      name: 'Turkey',
      dial_code: '+90',
      code: 'TR',
    },
    {
      name: 'Turkmenistan',
      dial_code: '+993',
      code: 'TM',
    },
    {
      name: 'Turks and Caicos Islands',
      dial_code: '+1649',
      code: 'TC',
    },
    {
      name: 'Tuvalu',
      dial_code: '+688',
      code: 'TV',
    },
    {
      name: 'Uganda',
      dial_code: '+256',
      code: 'UG',
    },
    {
      name: 'Ukraine',
      dial_code: '+380',
      code: 'UA',
    },
    {
      name: 'United Arab Emirates',
      dial_code: '+971',
      code: 'AE',
    },
    {
      name: 'United Kingdom',
      dial_code: '+44',
      code: 'GB',
    },
    {
      name: 'United States of America',
      dial_code: '+1',
      code: 'US',
    },
    {
      name: 'Uruguay',
      dial_code: '+598',
      code: 'UY',
    },
    {
      name: 'Uzbekistan',
      dial_code: '+998',
      code: 'UZ',
    },
    {
      name: 'Vanuatu',
      dial_code: '+678',
      code: 'VU',
    },
    {
      name: 'Venezuela, Bolivarian Republic of Venezuela',
      dial_code: '+58',
      code: 'VE',
    },
    {
      name: 'Vietnam',
      dial_code: '+84',
      code: 'VN',
    },
    {
      name: 'Virgin Islands, British',
      dial_code: '+1284',
      code: 'VG',
    },
    {
      name: 'Virgin Islands, U.S.',
      dial_code: '+1340',
      code: 'VI',
    },
    {
      name: 'Wallis and Futuna',
      dial_code: '+681',
      code: 'WF',
    },
    {
      name: 'Yemen',
      dial_code: '+967',
      code: 'YE',
    },
    {
      name: 'Zambia',
      dial_code: '+260',
      code: 'ZM',
    },
    {
      name: 'Zimbabwe',
      dial_code: '+263',
      code: 'ZW',
    },
  ];

  export const TIME_ZONES = [
    { name: 'UTC-12:00', code: '-12:00' },
    { name: 'UTC-11:00', code: '-11:00' },
    { name: 'UTC-10:00', code: '-10:00' },
    { name: 'UTC-09:30', code: '-09:30' },
    { name: 'UTC-09:00', code: '-09:00' },
    { name: 'UTC-08:00', code: '-08:00' },
    { name: 'UTC-07:00', code: '-07:00' },
    { name: 'UTC-06:00', code: '-06:00' },
    { name: 'UTC-05:00', code: '-05:00' },
    { name: 'UTC-04:30', code: '-04:30' },
    { name: 'UTC-04:00', code: '-04:00' },
    { name: 'UTC-03:30', code: '-03:30' },
    { name: 'UTC-03:00', code: '-03:00' },
    // { name: 'UTC-02:30', code: '-02:30' },
    { name: 'UTC-02:00', code: '-02:00' },
    { name: 'UTC-01:00', code: '-01:00' },
    { name: 'UTC±00:00 (GMT/UTC)', code: '+00:00' },
    { name: 'UTC+01:00', code: '+01:00' },
    { name: 'UTC+02:00', code: '+02:00' },
    { name: 'UTC+03:00', code: '+03:00' },
    { name: 'UTC+03:30', code: '+03:30' },
    { name: 'UTC+04:00', code: '+04:00' },
    { name: 'UTC+04:30', code: '+04:30' },
    { name: 'UTC+05:00', code: '+05:00' },
    { name: 'UTC+05:30', code: '+05:30' },
    { name: 'UTC+05:45', code: '+05:45' },
    { name: 'UTC+06:00', code: '+06:00' },
    { name: 'UTC+06:30', code: '+06:30' },
    { name: 'UTC+07:00', code: '+07:00' },
    { name: 'UTC+08:00', code: '+08:00' },
    { name: 'UTC+08:45', code: '+08:45' },
    { name: 'UTC+09:00', code: '+09:00' },
    { name: 'UTC+09:30', code: '+09:30' },
    { name: 'UTC+10:00', code: '+10:00' },
    { name: 'UTC+10:30', code: '+10:30' },
    { name: 'UTC+11:00', code: '+11:00' },
    { name: 'UTC+12:00', code: '+12:00' },
    { name: 'UTC+12:45', code: '+12:45' },
    { name: 'UTC+13:00', code: '+13:00' },
    // { name: 'UTC+13:45', code: '+13:45' },
    { name: 'UTC+14:00', code: '+14:00' },
  ];

  export const ROLES: any | Object = {
    ADMIN: {
      label: 'Admin',
      value: {
        label: 'Admin',
        data: 'Admin',
        styleClass: 'lowestChild',
        leaf: false,
      },
    },
    SUPPORT: {
      label: 'Live Support',
      value: {
        label: 'Live Support',
        data: 'Support',
        styleClass: 'lowestChild',
        leaf: false,
      },
    },
    MANAGER: {
      label: 'Manager',
      value: {
        label: 'Manager',
        data: 'Manager',
        styleClass: 'lowestChild',
        leaf: false,
      },
    },
    VIEWER: {
      label: 'Viewer',
      value: {
        label: 'Viewer',
        data: 'Viewer',
        styleClass: 'lowestChild',
        leaf: false,
        // selectable: false,
      },
    },
    ENGINEER: {
      label: 'Engineer',
    },
  };

  export const VRO_ROLES = [
    {
      label: 'Engineer',
      data: 'Engineer',
      // children: [],
      children: [
        {
          label: 'ADT',
          data: 'ADT',
          styleClass: 'lowestChild',
          leaf: false,
        },
      ],
    },
    {
      label: 'Manager',
      data: 'Manager',
      styleClass: 'lowestChild',
      leaf: false,
    },
    {
      label: 'Admin',
      data: 'Admin',
      styleClass: 'lowestChild',
      leaf: false,
    },
    {
      label: 'Viewer',
      data: 'Viewer',
      styleClass: 'lowestChild',
      leaf: false,
    },
    {
      label: 'Support',
      data: 'Support',
      styleClass: 'lowestChild',
      leaf: false,
    },
  ];

  export const ROLES_TEAM = [
    {
      label: 'Engineer',
      data: 'Engineer',
      children: [],
      // children: [
      //   {
      //     label: 'ADT',
      //     data: 'ADT',
      //     styleClass: 'lowestChild',
      //     leaf: false,
      //   },
    },
  ];

  export const HALLIBURTON_PSLs = [
    { name: 'Artificial Lift', code: 'PSL-01' },
    { name: 'Baroid', code: 'PSL-02' },
    { name: 'Cementing', code: 'PSL-03' },
    { name: 'Completion Tools', code: 'PSL-11' },
    { name: 'Consulting', code: 'PSL-04' },
    { name: 'Drill Bits and Services', code: 'PSL-05' },
    { name: 'Landmark', code: 'PSL-12' },
    { name: 'Multi-Chem', code: 'PSL-13' },
    { name: 'Pipeline and Process Services', code: 'PSL-14' },
    { name: 'Production Solutions', code: 'PSL-15' },
    { name: 'Project Management', code: 'PSL-06' },
    { name: 'Production Enhancement', code: 'PSL-07' },
    { name: 'Sperry Drilling', code: 'PSL-08' },
    { name: 'Testing and Subsea', code: 'PSL-09' },
    { name: 'Wireline and Perforating', code: 'PSL-10' },
  ];

  export const RIG_TYPES = [
    {
      label: 'Marine',
      data: 'Marine',
      children: [
        {
          label: 'Bottom Supported',
          data: 'Bottom Supported',
          children: [
            {
              label: 'Jackup',
              data: 'Jackup',
              children: [
                {
                  type_id: 'TYPE01',
                  label: 'Cantilevered',
                  data: 'Cantilevered',
                  defaultImage: 'Marine_Jackup_Cantilevered.jpg',
                },
                {
                  type_id: 'TYPE02',
                  label: 'Keyway',
                  data: 'Keyway',
                  defaultImage: 'Marine_Jackup_Keyway.jpg',
                },
              ],
            },
            {
              label: 'Submersible',
              data: 'Submersible',
              children: [
                {
                  type_id: 'TYPE03',
                  label: 'Posted Barge',
                  data: 'Posted Barge',
                  defaultImage: 'Marine_Submersible_Posted Barge.jpg',
                },
                {
                  type_id: 'TYPE04',
                  label: 'Inland Barge',
                  data: 'Inland Barge',
                  defaultImage: 'Marine_Submersible_Inland Barge.jpg',
                },
                {
                  type_id: 'TYPE05',
                  label: 'Bottle-Type',
                  data: 'Bottle-Type',
                  defaultImage: 'Marine_Submersible_Bottle-Type.jpg',
                },
                {
                  type_id: 'TYPE06',
                  label: 'Artic Special',
                  data: 'Artic Special',
                  defaultImage: 'Marine_Submersible_Arctic Special.jpg',
                },
              ],
            },
            {
              label: 'Platform',
              data: 'Platform',
              children: [
                {
                  type_id: 'TYPE07',
                  label: 'Tension-Leg',
                  data: 'Tension-Leg',
                  defaultImage: 'Marine_Platform_Tension-Leg.jpg',
                },
                {
                  type_id: 'TYPE08',
                  label: 'Spar',
                  data: 'Spar',
                  defaultImage: 'Marine_Platform_Spar.jpg',
                },
                {
                  type_id: 'TYPE09',
                  label: 'Fixed',
                  data: 'Fixed',
                  defaultImage: 'Marine_Platform_Fixed.jpg',
                },
                {
                  type_id: 'TYPE10',
                  label: 'Compliant Tower',
                  data: 'Compliant Tower',
                  defaultImage: 'Marine_Platform_Compliant Tower.jpg',
                },
                {
                  type_id: 'TYPE29',
                  label: 'Modular Drilling Rig',
                  data: 'Modular Drilling Rig',
                  defaultImage: 'GENERIC-RIG-IMAGE_MODULAR-DRILLING-RIG.png',
                },
              ],
            },
          ],
        },
        {
          label: 'Floating',
          data: 'Floating',
          children: [
            {
              label: 'Drillship',
              data: 'Drillship',
              children: [
                {
                  type_id: 'TYPE11',
                  label: 'Midwater',
                  data: 'Midwater',
                  defaultImage: 'Marine_Floating_Drillship.jpg',
                },
                {
                  type_id: 'TYPE12',
                  label: 'Deepwater',
                  data: 'Deepwater',
                  defaultImage: 'Marine_Floating_Drillship.jpg',
                },
                {
                  type_id: 'TYPE13',
                  label: 'Ultra-Deepwater',
                  data: 'Ultra-Deepwater',
                  defaultImage: 'Marine_Floating_Drillship.jpg',
                },
                {
                  type_id: 'TYPE14',
                  label: 'Harsh Environment',
                  data: 'Harsh Environment',
                  defaultImage: 'Marine_Floating_Drillship.jpg',
                },
              ],
            },
            {
              type_id: 'TYPE15',
              label: 'Semi-Submersible',
              data: 'Semi-Submersible',
              defaultImage: 'Marine_Semi-Submersible.jpg',
            },
            {
              label: 'Tender-Assisted',
              data: 'Tender-Assisted',
              children: [
                {
                  type_id: 'TYPE16',
                  label: 'Barge Tender',
                  data: 'Barge Tender',
                  defaultImage: 'Marine_Tender-Assist_Barge Tender.jpg',
                },
                {
                  type_id: 'TYPE17',
                  label: 'Semi-Tender',
                  data: 'Semi-Tender',
                  defaultImage: 'Marine_Tender-Assist_Semi-Tender.jpg',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'Land',
      data: 'Land',
      children: [
        {
          label: 'Conventional',
          data: 'Conventional',
          children: [
            {
              type_id: 'TYPE18',
              label: 'Box-on-Box',
              data: 'Box-on-Box',
              defaultImage: 'Land_Conventional.jpg',
            },
            {
              type_id: 'TYPE19',
              label: 'Slingshot',
              data: 'Slingshot',
              defaultImage: 'Land_Conventional.jpg',
            },
            {
              type_id: 'TYPE20',
              label: 'Swing-Lift',
              data: 'Swing-Lift',
              defaultImage: 'Land_Conventional.jpg',
            },
          ],
        },
        {
          type_id: 'TYPE21',
          label: 'Mobile Rig',
          data: 'Mobile Rig',
          defaultImage: 'Land_Mobile Rig.jpg',
        },
        {
          type_id: 'TYPE22',
          label: 'Helicopter Portable',
          data: 'Helicopter Portable',
          defaultImage: 'Land_Mobile_Helicopter Portable.jpg',
        },
      ],
    },
    {
      label: 'Rigless',
      data: 'Rigless',
      children: [
        {
          label: 'Support Vessel',
          data: 'Support Vessel',
          children: [
            {
              type_id: 'TYPE23',
              label: 'Well Intervention Vessel',
              data: 'Well Intervention Vessel',
              defaultImage:
                'Rigless_Support Vessel_Well Intervention Vessel.jpg',
            },
            {
              type_id: 'TYPE24',
              label: 'Stimulation Vessel',
              data: 'Stimulation Vessel',
              defaultImage: 'Rigless_Support Vessel_Stimulation Vessel.jpg',
            },
          ],
        },
        {
          type_id: 'TYPE25',
          label: 'Frac Spread',
          data: 'Frac Spread',
          defaultImage: 'Land_Rigless_Frac Spread.jpg',
        },
        {
          type_id: 'TYPE26',
          label: 'Hydraulic Workover Unit',
          data: 'Hydraulic Workover Unit',
          defaultImage: 'Rigless_Hydraulic Workover Unit.jpg',
        },
        {
          type_id: 'TYPE27',
          label: 'Coiled Tubing',
          data: 'Coiled Tubing',
          defaultImage: 'Land_Rigless_Coiled Tubing.jpg',
        },
        {
          type_id: 'TYPE28',
          label: 'Wireline',
          data: 'Wireline',
          defaultImage: 'Land_Rigless_Wireline.jpg',
        },
      ],
    },
    {
      type_id: 'Other',
      label: 'Other',
      data: 'Other',
      defaultImage: 'Other.jpg',
    },
  ];

  export const PRIORITIES = [
    { id: 0, name: 'Low' },
    { id: 1, name: 'Medium' },
    { id: 2, name: 'High' },
  ]

  export const MESSAGE_TYPE: IMessageTypeConst = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
  };

  export const OPTION_BUTTON: IOptionButtonConst = {
    YES: 'yes',
    NO: 'no',
    CANCEL: 'cancel',
    ADDITION: 'addition',
  };

  export const COMMON_TOASTER = {
    PERMISSION: 'User is not granted permission!',
  };

  export const STATUS_INTERACT: IStatusInteract = {
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete',
  };

  export const RESOLUTION_BREAKPOINT = {
    MOBILE: 480,
    TABLET: 768,
    LAPTOP: 1040,
    DESKTOP: 1200,
    HD_PLUS: 1366,
    TV: 1440,
  };

  // report Id for mappting with old data of intervention
  export const REPORT_EVENT_ID = ['EV94', 'EV95', 'EV96'];
  export const ROADMAP_EVENT_ID = ['EV97', 'EV98'];

  export const ALERT_TYPE = [{id: 'intervention', name:'Intervention'}, {id: 'report', name:'Report'}, {id: 'roadmap', name:'Roadmap'}];

  export const ALERT_TEMPLATE: IDropdownSelection[] = [{id: 'default', name:'Default', isSelected: true }, {id: 'roadmap', name:'Roadmap', isSelected: false}];
  export const LANGUAGE_TEMPLATE: IDropdownSelection[] = [
    { id: 'en', name: 'English', isSelected: true },
    // { id: 'pt', name: 'Portuguese', isSelected: false },
    { id: 'es', name: 'Spanish', isSelected: false },
  ];

  export const ALERT_STATE = {
    I_0_0: {
      key: "I_0_0",
      desciption: "Initiate, don't have further action, draft.",
    },
    I_0_1: {
      key: "I_0_1",
      desciption: "Initiate, don't have further action, completed.",
    },
    I_1_0: {
      key: "I_1_0",
      desciption: "Initiate, have further action, draft.",
    },
    I_1_1: {
      key: "I_1_1",
      desciption: "Initiate, have further action, completed.",
    },
    R_1_0: {
      key: "R_1_0",
      desciption: "Resolution, have further action, draft.",
    },
    R_1_1: {
      key: "R_1_1",
      desciption: "Resolution, have further action, completed",
    },
    R_R: {
      key: "R_1_1",
      desciption: "Resolution require.",
    },
  };

  // Default value of a common dialog
  export const DEFAULT_DIALOG: IConfirmDialog = {
    isVisible: false,
    header: '',
    byPassCheckbox: false,
    haveCheckbox: false,
    checkboxLabel: '',
    haveDialogMessage: false,
    dialogMessage: '',
    dialogMessageTemplate: '',
    dialogMessageLanguage: '',
    havePrimaryButton: false,
    primaryButtonLabel: '',
    haveSecondaryButton: false,
    secondaryButtonLabel: '',
    haveAdditionButton: false,
    additionButtonLabel: '',
    isValidPrimaryButton: false,
    disablePrimaryButton: false,
    disableSecondaryButton: false,
    haveBoxSelection: false,
    dataSelection: [],
    haveOption: false,
    dataOption: [],
    dataDropdownTemplate: [],
    dataDropdownLanguage: [],
    defaultTemplate: {id: 'default', name:'Default', isSelected: true},
    defaultLanguage: LANGUAGE_TEMPLATE[0],
    isDisableDropdown: false,
    haveSecondaryMessage: false,
    secondaryMessage: '',
    haveTertiaryMessage: false,
    tertiaryDialogMessage: '',
    alignCenterButton: false,
    styleDialog: 'dark-dialog',
    isHeaderIcon: false,
    headerIcon: '',
    isHaveTextarea: false,
    textarePlaceHolder: '',
    textareaSize: 6,
    buttonEvent: () => {},
  }

  export const STATUS_CONNECTION_MSTEAMS = {
    CONNECTED: 'connectedMsTeam' as StatusMSTeamsType,
    NOT_CONNECT: 'notConnectMsTeam' as StatusMSTeamsType,
    IS_NOT_MEMBER: 'isNotMember' as StatusMSTeamsType,
    NOT_EXIT: 'notExist' as StatusMSTeamsType,
  }

  export type Step = 'REPORT' | 'LIVE' | 'SENT' | 'SENT_AGENT' | null;
  export type ChatStep = 'CHAT' | 'WAIT' | 'RETRY';
  export interface TimeSeriesData {
    isToday?: boolean;
    isTodayLastItem?: boolean;
    isPast?: boolean;
    isPastFirstItem?: boolean;
  }

  export interface Message {
    id: number;
    supportCaseId: number;
    type: MessageTypes;
    content: string | any;
    senderType: AppConstant.SenderTypes;
    userId: string;
    chatId: string;
    userName: string;
    avatar: string;
    title: string;
    timestamp: Date;
    attachments: any[];   
    isInterationDisable: boolean;
  }

  export type ExtendedMessage = Message & TimeSeriesData;

  export enum MessageTypes {
    TEXT = 'text',
    IMAGE = 'image',
    ATTACHMENT = 'attachment',        
    ACTION_BUTTON = 'action_button',
    ACTION_BUTTON__RESOLUTION = 'action_button__resolution',
  }

  export enum SenderTypes
  {
    USER = 'user',
    LIVE_AGENT = 'live_agent',        
    BOT = 'bot',
    SYSTEM = 'system',
  } 

  export enum SupportCaseEvents
  {
    USER__LEFT_CHAT = 'user__left_chat',
    LIVE_AGENT__ENTERED_CHAT = 'live_agent__entered_chat',
    USER__TRIGGER_GREETING = 'user__trigger_greeting',
  }

  export enum SupportCaseStatuses
  {
      NEW = 0,
      OPENED = 1,
      IN_PROGRESS = 2,
      RESOLVED = 3,
  }
}
