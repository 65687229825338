import { AppConstant } from "../utilities/app.constant";

import * as Joi from 'joi';

const runTypeLabels = AppConstant.TYPES.map((x) => x.data);

export const isValidRunType = (value: any) => {
  return runTypeLabels.includes(value);
}

export const RunValidator = Joi.object({
    type: Joi.any().required().label('Type').custom((value: any, helpers: any) => {      
      const type = value?.data || value;
      if (!isValidRunType(type)) {
        return helpers.error('any.type');
      }
      return value;
    }),
    runNo: Joi.number().integer().label('RunNo').required().min(0).max(9999999999),
    description: Joi.string().label('Description').required().max(50).pattern(/^[^~@#$%^&*_+={}[\]|\\<>]*$/, { name: 'no-special-characters' }).messages({
      'string.pattern.name': '{{#label}} must not contain special characters: ~@#$%^&*_+=}{[]|\\<>',
    }),
    bitSize: Joi.number().label('BitSize').min(0).max(999999999.999)
      .when(Joi.ref('$unit'), {
        is: 'mm',
        then: Joi.number().integer().min(0).max(999999999).label('BitSize (mm)'),
      })
      .when(Joi.ref('$unit'), {
        is: 'in',
        then: Joi.number().min(0).max(999999999.999).label('BitSize (in)')
          .custom((value, helpers) => {
            if (value.toString().split('.')[1]?.length > 3) {
              return helpers.error('number.maxDecimals', { limit: 3 });
            }
            return value;
          }).messages({
            'number.maxDecimals': '{{#label}} must have at most {{#limit}} decimal places',
          })      
      }),
    casingSize: Joi.number().label('CasingSize').min(0).max(999999999.999)
      .when(Joi.ref('$unit'), {
        is: 'mm',
        then: Joi.number().integer().min(0).max(999999999).label('CasingSize (mm)'),
      })
      .when(Joi.ref('$unit'), {
        is: 'in',
        then: Joi.number().min(0).max(999999999.999).label('CasingSize (in)')
          .custom((value, helpers) => {
            if (value.toString().split('.')[1]?.length > 3) {
              return helpers.error('number.maxDecimals', { limit: 3 });
            }
            return value;
          }).messages({
            'number.maxDecimals': '{{#label}} must have at most {{#limit}} decimal places',
          })      
      }),    
}).messages({
  'any.type': 'Invalid type found'
}).unknown(true);


export const RunCasingSizeValidator = Joi.object({
  casingSize: Joi.number().label('CasingSize').min(0).max(999999999.999)
    .when(Joi.ref('$unit'), {
      is: 'mm',
      then: Joi.number().integer().min(0).max(999999999).label('CasingSize (mm)'),
    })
    .when(Joi.ref('$unit'), {
      is: 'in',
      then: Joi.number().min(0).max(999999999.999).label('CasingSize (in)')
        .custom((value, helpers) => {
          if (value.toString().split('.')[1]?.length > 3) {
            return helpers.error('number.maxDecimals', { limit: 3 });
          }
          return value;
        }).messages({
          'number.maxDecimals': '{{#label}} must have at most {{#limit}} decimal places',
        })
    }),
}).unknown(true);