import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { JoiErrorFormater } from 'src/app/shared/validators/joi-error-formater';
import { isValidRunType, RunValidator } from 'src/app/shared/validators/run.validator';

@Component({
  selector: 'app-editable-run-table',
  templateUrl: './editable-run-table.component.html',
  styleUrls: ['./editable-run-table.component.scss']
})
export class EditableRunTableComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef) { }
  @Input() datas: any;
  @Input() header: string ='Loading Header';
  @Input() projectUnits: any = {};
  @Output() onAllItemSelect = new EventEmitter<any>();
  @Output() onAllItemUnselect = new EventEmitter<any>();
  @Output() onItemSelect = new EventEmitter<any>();
  @Output() onItemUnselect = new EventEmitter<any>();
  @Output() onDataTableChange = new EventEmitter<any>();
  @ViewChild('dt', { static: false }) table: Table

  shouldDisableResetButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  originalDatas: any[] = [];

  clonedItems: { [s: string]: any } = {};
  hasInvalidTypeFound: boolean = false;  
  
  columns: any = [
    { field: 'checkbox', header: '' },
    { field: 'runNo', header: 'Run Number' },
    { field: 'description', header: 'Description' },
    { field: 'casingSize', header: 'Size' },
    { field: 'type', header: 'Type' },
    { field: 'features', header: '' },
  ]

  runTypeList: any[] = [];
  selectedItems: any;

  ngOnInit() {
    this.runTypeList = AppHelper.UtileFunctions.sortArrayBykey(
      AppConstant.TYPES,
      'data'
    );
  }

  ngOnChanges(changes: any) {    
    if (changes.datas && changes.datas.currentValue) {
      this.datas = changes.datas.currentValue;
      this.datas.forEach((item: any) => {
        item.size = item.casingSize ?? item.bitSize;
        item.validationError = JoiErrorFormater.format(RunValidator.validate(item, { abortEarly: false, context: { unit: this.projectUnits?.Diameter } })?.error);
      });      
      this.selectedItems = this.datas.filter((x: any) => x.isSelected);
      this.checkIfInvalidTypeFound();

      if (!changes.datas.previousValue?.length && !changes.datas.firstChange) {
        this.storeOriginalDatas();
      }
    }
  }
   
  onSubmit(event: any) {
    console.log(this.selectedItems);
  }
  showContextMenu(event: any, x: any) { }
  redirectToRig(event: any) { }
  handleRowClick(event: any, x: any) { }

  ngOnDestroy(): void {
  }

  onRowEditInit(item: any) {    
  }

  isDrillingType(item: any) {
    const type = item.type?.data || item.type;
    const matchedOption = AppConstant.TYPES.find((x) => x.data === type);
    if (!matchedOption) return;
    return matchedOption.type === 'Drilling';
  }

  onRowEditSave(item: any, htmlTableRowElement: any) {
    const type = item.type?.data || item.type;
    const matchedOption = AppConstant.TYPES.find((x) => x.data === type);
    if (!matchedOption) return;
    
    if (matchedOption.type !== 'Drilling') {
      item.casingSize = item.size;
      item.bitSize = undefined;            
    } else {
      item.bitSize = item.size;
      item.casingSize = undefined;
    }

    item.validationError = JoiErrorFormater.format(RunValidator.validate(item, { abortEarly: false, context: { unit: this.projectUnits?.Diameter } })?.error);
    console.log(item.validationError);
    this.checkIfInvalidTypeFound();
    if (item.validationError) return;    
    item.isModified = this.isItemModified(item);
    this.table.saveRowEdit(item, htmlTableRowElement)    
    this.onDataTableChange?.emit(this.datas);
    this.handleResetState();
  }

  onRowSelect(event: any) {
    event.data.isSelected = true;
    this.onItemSelect?.emit(event.data);
    this.handleResetState();
  }

  onRowUnselect(event: any) {
    event.data.isSelected = false;
    this.onItemUnselect?.emit(event.data);
    this.handleResetState();
  }

  onHeaderCheckboxToggle(event: any) {
    if (event.checked) {
      this.selectedItems = [...this.datas];
      this.datas.forEach((item: any) => {
        item.isSelected = true;
      });
      this.onAllItemSelect?.emit();
    } else {
      this.selectedItems = [];
      this.datas.forEach((item: any) => {
        item.isSelected = false;
      });
      this.onAllItemUnselect?.emit();
    }
    this.handleResetState();
  }

  checkIfInvalidTypeFound() {
    for (const run of this.datas) {
      const type = run?.type?.data || run?.type;
      if (!isValidRunType(type)) {
        this.hasInvalidTypeFound = true;
        return;
      }      
    }
    this.hasInvalidTypeFound = false;
  }

  onInputBlur(event: any, fieldname: string, item: any) {
    const inputFields = ['description'];
    if (inputFields.includes(fieldname)) {      
      item[fieldname] = event?.target?.value.trim();
      this.cdr.detectChanges();
    }
  }

  handleResetState() {
    const hasModifiedSelectedItem = this.datas.some((item: any) => item.isSelected && item.isModified);
    this.shouldDisableResetButton$.next(!hasModifiedSelectedItem);
  }

  storeOriginalDatas() {
    this.originalDatas = JSON.parse(JSON.stringify(this.datas)); // Store a deep copy of the original data
  }

  handleReset() {
    const originalDataMap = new Map(this.originalDatas.map(item => [item.edmRunId, item]));

    this.datas = this.datas.map((item: any) => {
      const originalItem = originalDataMap.get(item.edmRunId);
      if (originalItem && item.isSelected) {
        return { ...originalItem, isSelected: item.isSelected, isModified: false };
      }
      return item;
    });

    this.selectedItems = this.datas.filter((x: any) => x.isSelected);  
    this.onDataTableChange?.emit(this.datas);
    this.handleResetState();
  }

  isItemModified(item: any): boolean {
    const originalItem = this.originalDatas.find((orig: any) => orig.edmRunId === item.edmRunId);
    return originalItem && JSON.stringify(item) !== JSON.stringify(originalItem);
  }
}
