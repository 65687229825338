import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { countBy, filter, isEmpty, sortBy } from 'lodash';
import { BehaviorSubject, catchError, finalize, forkJoin, Subject, takeUntil } from 'rxjs';
import { MODULE_NAME, USER_PERMISSION } from 'src/app/shared/enum';
import { HomeService } from 'src/app/shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { Interval, IntervalAndRunRes, WellboreService, ImportResponse } from 'src/app/shared/services/wellbore.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { UNIT_SYSTEM } from 'src/app/shared/utilities/app.helper.data';
import { IntervalValidator } from 'src/app/shared/validators/interval.validator';
import { JoiErrorFormater } from 'src/app/shared/validators/joi-error-formater';

@Component({
  selector: 'app-interval-popup',
  templateUrl: './interval-popup.component.html',
  styleUrls: ['./interval-popup.component.scss']
})
export class IntervalPopupComponent implements OnInit {
  private intervalsSubject: BehaviorSubject<Interval[]> = new BehaviorSubject<Interval[]>([]);
  public shouldDisableSaveButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private destroy$ = new Subject<void>();
  
  intervals$ = this.intervalsSubject.asObservable();
  
  isLoading: boolean = false;
  projectUnits: any = {};
  canAdd: boolean = false;
  canEdit: boolean = false;
  
  @Input() wellboreId!: string;
  @Input() projectId!: string;
  @Input() rigId!: string;

  @Input() existedIntervals: any[] = [];

  @Output() onClickCancel = new EventEmitter<void>();
  @Output() onClickSave = new EventEmitter<any>();
  @Output() onReload = new EventEmitter<any>();
  
  constructor(
    private homeService: HomeService,    
    private wellboreService: WellboreService,
    private notificationService: NotificationService,
    private userInfoService: UserInfoService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {    
    this.canAdd = this.userInfoService.hasPermission(
      MODULE_NAME.INTERVAL,
      USER_PERMISSION.ADD
    );

    this.canEdit = this.userInfoService.hasPermission(
      MODULE_NAME.INTERVAL,
      USER_PERMISSION.EDIT
    );

    
    if (this.canAdd && this.canEdit)
      this.fetchIntervals(this.wellboreId, this.projectId);
  }

  shouldDisableSaveButton(intervals: Interval[]): boolean {
    if (intervals.length === 0) return true;
    const totalSelectedIntervals = filter(intervals, e => e.isSelected).length;
    if (totalSelectedIntervals === 0) return true;
    
    for (const interval of intervals) {
      if (!interval.validationError) {
        interval.validationError = JoiErrorFormater.format(IntervalValidator.validate(interval, { abortEarly: false })?.error);
      }
      if (interval.isSelected && interval.validationError) return true;
    }
    return false;
  }

  fetchIntervals(wellboreId: string, projectId: string): void {
    if (!wellboreId || !projectId) {
      this.notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.ERROR,
        header: 'Import Intervals',
        content: 'Wellbore ID or Project ID is missing.'
      })
      return;
    }

    this.isLoading = true;
    forkJoin([
      this.wellboreService.getIntervalByWellboreId(this.wellboreId),
      this.homeService.getProjectById(this.projectId)
    ]).pipe(
      catchError(AppHelper.UtileFunctions.handleError),
      takeUntil(this.destroy$),
      finalize(() => {
        this.isLoading = false,
        this.cdr.detectChanges();
      })
    ).subscribe({
      next: ([intervalAndRunResponse, projectResponse]: [{intervals: Interval[]}, any]) => {
         
        // Interval Response Handle
        intervalAndRunResponse.intervals = intervalAndRunResponse.intervals.filter(interval => 
          !this.existedIntervals.some(existedInterval => existedInterval.edmIntervalId === interval.edmIntervalId)
        );

        const intervals = sortBy(intervalAndRunResponse.intervals, 'intervalName');
        
        this.intervalsSubject.next(intervals);

        // Project Response Handle
        const curUnit = projectResponse?.data?.curUnit;
        if (curUnit) {
          this.projectUnits = AppHelper.MathFunctions.findUnit(curUnit, UNIT_SYSTEM.unit);
        } 

      },
      error: (error) => {        
        error && console.error('Error fetching intervals or project by id', error);
      },   
    });
  }

  handleCancel(): void {
    this.onClickCancel?.emit();
  }

  handleSave(): void {
    if (this.shouldDisableSaveButton$.getValue()) {
      return;
    }

    const intervals = this.intervalsSubject.getValue();
    
    const seletedIntervals = filter(intervals, e => e.isSelected);
    const intervalNameCount = countBy(seletedIntervals, 'intervalName');

    const duplicatedIntervalNames = Object.keys(intervalNameCount).filter(intervalName => intervalNameCount[intervalName] > 1);
    if (!isEmpty(duplicatedIntervalNames)) {
      const messages = [];
      if (!isEmpty(duplicatedIntervalNames)) {
        messages.push(`Duplicate interval names: ${duplicatedIntervalNames.join(', ')}`);
      }
      for (const message of messages) {
        this.notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.ERROR,
          header: 'Import Interval',
          content: message,
        })
      }      
      return;
    }

    // Handle save
    if (seletedIntervals.length > 0) {
      this.isLoading = true;

      const payload = {
        projectId: this.projectId,
        wellboreId: this.wellboreId,
        rigId: this.rigId,
        intervals: seletedIntervals
      }

      this.homeService
        .addIntervals(payload)
        .pipe(
          catchError(AppHelper.UtileFunctions.handleError),
          takeUntil(this.destroy$),
          finalize(() => this.isLoading = false)
        )
        .subscribe({
          next: (response: ImportResponse) => {
            const isComplete: boolean = this.handleResponse(response);
            if (isComplete) {
              this.notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Add Intervals',
                content: 'Intervals was imported successfully!',
              });

              this.onClickSave.emit(true);
              this.onReload.emit(true);
            }
          },
          error: (error) => {
            console.error(error);
            this.notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Add Intervals',
              content: error?.message || error,
            });
          },
        });
    }
  }

  handleResponse(response: ImportResponse): boolean {
    if (response.warningMessages.length > 0) {
      for (const message of response.warningMessages) {
        this.notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Add Intervals',
          content: message.message,
        });
      }
      return false;
    }
    return true;
  }

  handleIntervalItemSelect(interval: Interval): void {
    if (!interval.edmIntervalId) return;
    this.handleRow();
  }

  handleIntervalItemUnselect(interval: Interval): void {
    if (!interval.edmIntervalId) return;
    this.handleRow();
  }

  handleIntervalAllUnselect(): void {
    this.handleRow();
  }

  handleIntervalAllSelect(): void {
    this.handleRow();
  }

  handleIntervalTableChange(event: any): void {        
    this.intervalsSubject.next(event);
    this.handleRow();
  }

  handleRow(): void {
    const intervals = this.intervalsSubject.getValue();
    this.shouldDisableSaveButton$.next(this.shouldDisableSaveButton(intervals));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
