<div [ngClass]="{'for-image': forImage}" class="message-time">
  <span *ngIf="message.isToday && !message.isTodayLastItem">
    {{ message.timestamp | date: 'shortTime' }}
  </span>

  <span *ngIf="message.isToday && message.isTodayLastItem">
    Today, {{ message.timestamp | date: 'shortTime' }}
  </span>

  <span *ngIf="!message.isToday && message.isPast">
    {{ message.timestamp | date: 'dd/MM/yyyy' }}, {{ message.timestamp | date: 'shortTime' }}
  </span>
</div>
