import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { tap } from 'rxjs';
import { UserInfoService } from '../services/user-info.service';
import { AppConstant } from '../utilities/app.constant';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _userInfoService: UserInfoService,
    private _msalService: MsalService  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let loginID = this._msalService.instance.getActiveAccount()?.localAccountId;
    const currentRole = this._userInfoService.userSubject.getValue()?.role;
    switch (route?.routeConfig?.path) {
      case 'mailbox/mailbox-management':
      case 'rig/rig-management':
        if (currentRole) {
          return [
            AppConstant.ROLES.ADMIN.label,
            AppConstant.ROLES.SUPPORT.value.data,
            AppConstant.ROLES.VIEWER.label,
          ].includes(currentRole);
        }
        return this._userInfoService.apiAuthGuardAdmin(loginID).pipe(
          tap(allowed => {
            if (!allowed) {
              this._router.navigate(['/home']);
            }
          })
        );
      case 'team-management':
      case 'team-management/:userId':
        if (currentRole) {
          return ![AppConstant.ROLES.ENGINEER.label].includes(currentRole);
        }
        return this._userInfoService.apiAuthGuardAdminOrManager(loginID).pipe(
          tap(allowed => {
            if (!allowed) this._router.navigate(['/home']);
          })
        );
      case 'live-support':        
        if (currentRole) {          
          return [AppConstant.ROLES.SUPPORT.value.data, AppConstant.ROLES.ADMIN.value.data].includes(currentRole);
        }
        return this._userInfoService.apiAuthGuardSupport(loginID).pipe(
          tap(allowed => {
            if (!allowed) this._router.navigate(['/home']);
          })
        );
      default:
        if (currentRole) {
          return ![AppConstant.ROLES.ENGINEER.label].includes(currentRole);
        }
        return this._userInfoService.apiAuthGuardAdminOrManager(loginID).pipe(
          tap(allowed => {
            if (!allowed) this._router.navigate(['/home']);
          })
        );
    }
  }

}
