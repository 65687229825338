import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})

export class ChatMessageComponent implements OnInit {
  @Input() message: AppConstant.Message;
  @Input() previousMessage: AppConstant.Message | undefined;

  @Input() isMine: boolean;

  @Input() isTyping: boolean = false;
  @Input() template: 'CONTENT' | 'LOADING' = 'CONTENT';
  
  MESSAGE_TYPES = AppConstant.MessageTypes;
  SENDER_TYPES = AppConstant.SenderTypes;

  isContent: boolean = false;
  isEventSystem :boolean = false;
  isEventButton:boolean = false;

  constructor() { }
  
  ngOnInit(): void {
    this. isContent = this.isMessageType(this.MESSAGE_TYPES.TEXT, this.MESSAGE_TYPES.IMAGE, this.MESSAGE_TYPES.ATTACHMENT)
                        && this.message.senderType !== this.SENDER_TYPES.SYSTEM;
    this. isEventSystem = this.isMessageType(this.MESSAGE_TYPES.TEXT, this.MESSAGE_TYPES.IMAGE, this.MESSAGE_TYPES.ATTACHMENT)
                        && this.message.senderType === this.SENDER_TYPES.SYSTEM;
    this. isEventButton = this.isMessageType(this.MESSAGE_TYPES.ACTION_BUTTON__RESOLUTION, this.MESSAGE_TYPES.ACTION_BUTTON);
  }

  isMessageType(...types: AppConstant.MessageTypes[]): boolean {
    return types.includes(this.message.type as AppConstant.MessageTypes);
  }

}

