import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { BehaviorSubject } from 'rxjs';
import { IntervalValidator } from 'src/app/shared/validators/interval.validator';
import { JoiErrorFormater } from 'src/app/shared/validators/joi-error-formater';

@Component({
  selector: 'app-editable-interval-table',
  templateUrl: './editable-interval-table.component.html',
  styleUrls: ['./editable-interval-table.component.scss']
})
export class EditableIntervalTableComponent implements OnInit {

  @Input() datas: any;
  @Input() header: string ='Loading Header';
  @Input() projectUnits: any = {};
  @Output() onAllItemSelect = new EventEmitter<any>();
  @Output() onAllItemUnselect = new EventEmitter<any>();
  @Output() onItemSelect = new EventEmitter<any>();
  @Output() onItemUnselect = new EventEmitter<any>();
  @Output() onDataTableChange = new EventEmitter<any>();
  @ViewChild('dt', { static: false }) table: Table

  shouldDisableResetButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  originalDatas: any[] = [];

  clonedItems: { [s: string]: any } = {};
  
  columns: any = [
    { field: 'checkbox', header: '' },
    { field: 'intervalName', header: 'Interval' },
    { field: 'openHoleDiameter', header: 'Hole Size' },
    { field: 'operation', header: 'Operation' },
    { field: 'features', header: '' },
  ]  

  operations: any = ['Drilling', 'Completion', 'Intervention'];
  selectedItems: any;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() { }

  ngOnChanges(changes: any) {
    if (changes.datas && changes.datas.currentValue) {
      this.datas = changes.datas.currentValue;
      this.datas.forEach((item: any) => {
        item.validationError = JoiErrorFormater.format(IntervalValidator.validate(item, { abortEarly: false, context: { unit: this.projectUnits?.Diameter } })?.error);
      });      
      this.selectedItems = this.datas.filter((x: any) => x.isSelected);

      if (!changes.datas.previousValue?.length && !changes.datas.firstChange) {
        this.storeOriginalDatas();
      }
    }
  }

  onInputBlur(event: any, fieldname: string, item: any) {
    const inputFields = ['intervalName'];
    if (inputFields.includes(fieldname)) {      
      item[fieldname] = event?.target?.value.trim();
      this.cdr.detectChanges();
    }
  }
   
  onSubmit(event: any) { }
  showContextMenu(event: any, x: any) { }
  redirectToRig(event: any) { }
  handleRowClick(event: any, x: any) { }

  ngOnDestroy(): void {
  }

  onRowEditInit(item: any) {
    // this.clonedItems[item.edmIntervalId as string] = { ...item };
  }

  onRowEditSave(item: any, htmlTableRowElement: any) {
    item.validationError = JoiErrorFormater.format(IntervalValidator.validate(item, { abortEarly: false, context: { unit: this.projectUnits?.Diameter } })?.error);
    if (item.validationError) return;
    item.isModified = this.isItemModified(item);
    this.table.saveRowEdit(item, htmlTableRowElement)
    this.onDataTableChange?.emit(this.datas);
    this.handleResetState();
  }

  onRowSelect(event: any) {
    event.data.isSelected = true;
    this.onItemSelect?.emit(event.data);
    this.handleResetState();
  }

  onRowUnselect(event: any) {
    event.data.isSelected = false;
    this.onItemUnselect?.emit(event.data);
    this.handleResetState();
  }

  onHeaderCheckboxToggle(event: any) {
    if (event.checked) {
      this.selectedItems = [...this.datas];
      this.datas.forEach((item: any) => {
        item.isSelected = true;
      });
      this.onAllItemSelect?.emit();
    } else {
      this.selectedItems = [];
      this.datas.forEach((item: any) => {
        item.isSelected = false;
      });
      this.onAllItemUnselect?.emit();
    }
    this.handleResetState();
  }

  handleResetState() {
    const hasModifiedSelectedItem = this.datas.some((item: any) => item.isSelected && item.isModified);
    this.shouldDisableResetButton$.next(!hasModifiedSelectedItem);
  }

  storeOriginalDatas() {
    this.originalDatas = JSON.parse(JSON.stringify(this.datas)); // Store a deep copy of the original data
  }

  handleReset() {
    const originalDataMap = new Map(this.originalDatas.map(item => [item.edmIntervalId, item]));

    this.datas = this.datas.map((item: any) => {
      const originalItem = originalDataMap.get(item.edmIntervalId);
      if (originalItem && item.isSelected) {
        return { ...originalItem, isSelected: item.isSelected, isModified: false };
      }
      return item;
    });

    this.selectedItems = this.datas.filter((x: any) => x.isSelected);  

    this.onDataTableChange?.emit(this.datas);
    this.handleResetState();
  }

  isItemModified(item: any): boolean {
    const originalItem = this.originalDatas.find((orig: any) => orig.edmIntervalId === item.edmIntervalId);
    return originalItem && JSON.stringify(item) !== JSON.stringify(originalItem);
  }
}
