import { Component, Input, OnInit, Output } from '@angular/core';
import { ChatService } from 'src/app/shared/services/chat.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-chat-message-confirm',
  templateUrl: './chat-message-confirm.component.html',
  styleUrls: ['./chat-message-confirm.component.scss']
})
export class ChatMessageConfirmComponent implements OnInit {
  @Input() message: AppConstant.Message;

  isInteractionDisabled: boolean = false;

  constructor(private chatService: ChatService) { }
  
  ngOnInit(): void {
    for (const button of this.message?.content?.buttons || []) {
      if (button.selected) {
        this.isInteractionDisabled = true;
        return;
      }
    }    
  }

  onButtonClick(index: number) {
    if (this.isInteractionDisabled) return;
    this.isInteractionDisabled = true;
    const updatedContent = this.message.content;
    updatedContent.buttons[index].selected = true;
    this.chatService.updateInteractionMesssage(this.message.id, updatedContent).subscribe(() => {});
  }
}
