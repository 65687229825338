<app-chat-message-text
  *ngIf="isContent"
  [message]="message"
  [isMine]="isMine"
  [previousMessage]="previousMessage"
  [template]="template"
></app-chat-message-text>
<app-chat-message-event
  *ngIf="isEventSystem"
  [message]="message"
  [isMine]="isMine"
  [previousMessage]="previousMessage"
></app-chat-message-event>
<app-chat-message-confirm
    *ngIf="isEventButton"
    [message]="message"
></app-chat-message-confirm>