<div class="table-header d-flex justify-content-between align-items-center mb-2">
  <div class="d-flex">
    <div>{{ header }}</div>
    <div *ngIf="hasInvalidTypeFound" class="invalid-type-found d-flex">
      <div class="warning-icon"></div><span>Invalid type(s) found</span>
    </div>
  </div>
  <div>
    <p-button
        [pTooltip]="!!(shouldDisableResetButton$ | async) ? '' : 'By clicking you reset all changes and go back to the original value from EDM in the table'" 
        tooltipPosition="bottom"
        [attr.data-cy]="'reset-button'"
        label="Reset"
        [disabled]="!!(shouldDisableResetButton$ | async)"      
        (click)="handleReset()"
        class="custom-p-button custom-p-button--secondary"
      ></p-button>
  </div>
</div>

<p-table #dt class="run-table" [value]="datas" editMode="row" [columns]="columns" [scrollable]="true"
  [resizableColumns]="true" [(selection)]="selectedItems" (onRowSelect)="onRowSelect($event)"
  (onRowUnselect)="onRowUnselect($event)" (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)" dataKey="edmRunId"
  styleClass="p-datatable-striped custom-table custom-table--fit">
  <ng-template pTemplate="header" let-columns>
    <tr style="height: 40px" pEditableRow>
      <th *ngFor="let col of columns; first as isFirst; last as isLast; let i = index" [pSortableColumn]="col.field"
        pResizableColumn
        [ngClass]="isFirst ? 'th-last justify-content-center' : (isLast ? 'th-last justify-content-center' : 'th-default')">
        <ng-container *ngIf="isFirst">
          <p-tableHeaderCheckbox class="custom-p-checkbox"></p-tableHeaderCheckbox>
        </ng-container>

        <ng-container *ngIf="isLast">
        </ng-container>

        <ng-container *ngIf="!isLast && !isFirst">
          <div [pTooltip]="col.header" tooltipPosition="bottom" class="th-content">
            {{ col.header }}
          </div>

          <div class="header-sort-icon">
            <i class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"></i>

            <i class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"></i>
          </div>
        </ng-container>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item let-rowIndex="rowIndex" let-editing="editing">
    <tr (click)="handleRowClick($event, item)" [pEditableRow]="item" #htmlTableRowElement>
      <td class="d-flex align-items-center td-option justify-content-center">
        <p-tableCheckbox class="custom-p-checkbox" [value]="item"></p-tableCheckbox>
      </td>

      <td class="d-flex align-items-center td-default" [ngClass]="item?.validationError?.runNo ? 'has-error' : ''">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [(ngModel)]="item.runNo" pTooltip="{{ item?.validationError?.runNo?.join('; ') }}"
              tooltipPosition="top" [tooltipDisabled]="!item?.validationError?.runNo" />
          </ng-template>
          <ng-template pTemplate="output">
            <span>
              {{ item['runNo'] !== null && item['runNo'] !== undefined ? item['runNo'] : "N/A" }}
            </span>
          </ng-template>
        </p-cellEditor>
      </td>


      <td class="d-flex align-items-center td-default" 
        [ngClass]="item?.validationError?.description ? 'has-error' : ''"
        pTooltip="{{ item?.validationError?.description?.join('; ') }}" tooltipPosition="top"
        [tooltipDisabled]="!item?.validationError?.description">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [(ngModel)]="item.description" (blur)="onInputBlur($event, 'description', item)"
              pTooltip="{{ item?.validationError?.description?.join('; ') }}" tooltipPosition="top"
              [tooltipDisabled]="!item?.validationError?.description" />
          </ng-template>
          <ng-template pTemplate="output">
            <span class="description" [pTooltip]="item['description'] || 'N/A'" tooltipPosition="bottom">
              {{ item['description'] || "N/A" }}
            </span>
          </ng-template>
        </p-cellEditor>
      </td>

      <td class="d-flex align-items-center td-default"
        [ngClass]="item?.validationError?.bitSize || item?.validationError?.casingSize ? 'has-error' : ''"
        pTooltip="{{ !isDrillingType(item) ? item?.validationError?.casingSize?.join('; ') : item?.validationError?.bitSize?.join('; ') }}"
        tooltipPosition="top" [tooltipDisabled]="!item?.validationError?.casingSize && !item?.validationError?.bitSize">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="number" [(ngModel)]="item.size" tooltipPosition="top" />
          </ng-template>
          <ng-template pTemplate="output">
            <span class="text-value" [pTooltip]="item['size'] ?? 'N/A'">
              {{ item['size'] ?? "N/A" }} {{ projectUnits?.Diameter || "N/A" }}
            </span>
          </ng-template>
        </p-cellEditor>
      </td>

      <td class="d-flex align-items-center td-default" [ngClass]="item?.validationError?.type ? 'has-error' : ''"
        pTooltip="{{ item?.validationError?.type?.join('; ') }}" tooltipPosition="top"
        [tooltipDisabled]="!item?.validationError?.type">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown [options]="runTypeList" appendTo="body" [(ngModel)]="item.type" optionLabel="data" optionValue="data" 
              [style]="{'width':'100%'}">
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            <span class="text-value">
              {{ item['type']?.data || item?.type || "N/A" }}
            </span>
          </ng-template>
        </p-cellEditor>

      </td>

      <td class="d-flex align-items-center td-option justify-content-center">
        <div class="more-option-table">
          <svg-icon *ngIf="!editing" pInitEditableRow (click)="onRowEditInit(item)" key="edit" size="md"></svg-icon>
          <i *ngIf="editing" (click)="onRowEditSave(item, htmlTableRowElement)" class="pi pi-check"></i>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        Data not found
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- Loading -->
<app-loading [isLoading]="false"></app-loading>