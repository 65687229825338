<app-layout [isCompleteSettingToken]="isCompleteSettingToken">
  <router-outlet></router-outlet>
</app-layout>

<app-confirm-dialog
  [visible]="confirmDialog.isVisible"
  [header]="confirmDialog.header"
  [haveCheckbox]="confirmDialog.haveCheckbox"
  [byPassCheckbox]="confirmDialog.byPassCheckbox || false"
  [checkboxLabel]="confirmDialog.checkboxLabel"
  [haveDialogMessage]="confirmDialog.haveDialogMessage"
  [dialogMessage]="confirmDialog.dialogMessage"
  [havePrimaryButton]="confirmDialog.havePrimaryButton"
  [primaryButtonLabel]="confirmDialog.primaryButtonLabel"
  [haveSecondaryButton]="confirmDialog.haveSecondaryButton"
  [secondaryButtonLabel]="confirmDialog.secondaryButtonLabel"
  
  [haveAdditionButton]="confirmDialog.haveAdditionButton"
  [additionButtonLabel]="confirmDialog.additionButtonLabel"

  [isValidPrimaryButton]="confirmDialog.isValidPrimaryButton"
  [disablePrimaryButton]="confirmDialog.disablePrimaryButton"
  [disableSecondaryButton]="confirmDialog.disableSecondaryButton"
  [haveBoxSelection]="confirmDialog.haveBoxSelection"
  [dataSelection]="confirmDialog.dataSelection"
  [haveOption]="confirmDialog.haveOption"
  [dataOption]="confirmDialog.dataOption"
  [haveSecondaryMessage]="confirmDialog.haveSecondaryMessage"
  [secondaryMessage]="confirmDialog.secondaryMessage"
  [haveTertiaryMessage]="confirmDialog.haveTertiaryMessage"
  [tertiaryDialogMessage]="confirmDialog.tertiaryDialogMessage"
  [alignCenterButton]="confirmDialog.alignCenterButton"
  [styleDialog]="confirmDialog.styleDialog"
  [isHeaderIcon]="confirmDialog.isHeaderIcon"
  [headerIcon]="confirmDialog.headerIcon"
  [isHaveTextarea]="confirmDialog.isHaveTextarea"
  [textarePlaceHolder]="confirmDialog.textarePlaceHolder"
  [textareaSize]="confirmDialog.textareaSize"
  [dialogMessageTemplate]="confirmDialog.dialogMessageTemplate"
  [dataDropdownTemplate]="confirmDialog.dataDropdownTemplate"
  [dialogMessageLanguage]="confirmDialog.dialogMessageLanguage"
  [dataDropdownLanguage]="confirmDialog.dataDropdownLanguage"

  [isDisableDropdown]="confirmDialog.isDisableDropdown"
  [defaultTemplate]="confirmDialog.defaultTemplate"
  [defaultLanguage]="confirmDialog.defaultLanguage"

  (clickButtonEvent)="confirmDialog.buttonEvent($event)"
></app-confirm-dialog>
