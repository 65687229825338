import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-multi-level-dropdown',
  templateUrl: './multi-level-dropdown.component.html',
  styleUrls: ['./multi-level-dropdown.component.scss'],
})
export class MultiLevelDropdownComponent implements OnInit {
  @Input()
  sourceData: any;

  @Input()
  placeholder: string = 'Select Type';

  @Input()
  showClear: boolean = true;

  @Input()
  scrollHeight: string = '200px';

  @Input()
  selectAllWhenChooseParent: boolean = true;

  @Input()
  isCustomTemplate: boolean = false;

  @Input()
  customLabelString: string = 'Select Type';
  customLabel: string = '';

  @Input()
  seperateMark: string = '/';

  @Input()
  isLabel: boolean = true;

  @Input()
  label: string = 'Label';

  @Input()
  isRequired: boolean = true;

  @Input()
  isDisabled: boolean = false;

  @Input()
  isCounterSelected: boolean = false;

  counterSelected: number = 0;

  @Input()
  customClass: string = '';

  // IMPORTANT --> Decide type of this component
  @Input()
  multiType: string = 'any-level';

  @Input()
  formGroup!: FormGroup;

  @Input()
  formControlNamePass: string = '';

  @Input() messageErrors: any;

  // Control Event
  @Output() eventChange: EventEmitter<void> = new EventEmitter<void>();
  
  constructor() {}
  ngOnInit() {
    this.customLabel = this.customLabelString;
  }
  ngOnChanges() {
    const typeId = this.formGroup.value[this.formControlNamePass]?.type_id;
    if (typeId) {
      this.customLabel = this.customLabelString;
      let rigTypeData = this.findTypeById(
        this.formGroup.value[this.formControlNamePass].type_id
      );
      if (
        this.formGroup.get(this.formControlNamePass) &&
        this.customLabelString !== 'Select Type'
      ) {
        this.formGroup.get(this.formControlNamePass)?.setValue(rigTypeData);
      }
    }
  }

  onOptionsSelected(event: any, seperateMark: string) {
    let labelDisplay = event?.node?.label;
    let currentObjLevel = event?.node;

    if (currentObjLevel) {
      if (seperateMark === "()") {
        if (currentObjLevel.parent) {
          labelDisplay = `${currentObjLevel.parent.label} (${labelDisplay})`;
        } else {
          labelDisplay = `${currentObjLevel.label}`;
        }
      } else {
        let finalAncestor = false;
        while (!finalAncestor) {
          if (
            currentObjLevel.hasOwnProperty('parent') &&
            currentObjLevel.parent !== undefined
          ) {
            //
            currentObjLevel = currentObjLevel.parent;
            labelDisplay = `${currentObjLevel.label} ${seperateMark} ${labelDisplay}`;
          } else {
            finalAncestor = true;
          }
        }
      }
    }

    this.customLabel = labelDisplay || this.placeholder;
    this.formGroup.get(this.formControlNamePass)?.setValue(event?.node || null);
    this.updateCounter(this.isCounterSelected);
    this.eventChange.emit(event);
  }

  onNodeExpand(event: any) {
    // Prevent the arrow from expanding when this is the last child element
    if (event.node.children === undefined) event.node.expanded = false;
  }

  onClear(event: any) {
    this.updateCounter(this.isCounterSelected);
    this.customLabel = 'Select Type';
    this.placeholder = 'Select Type';
    this.customLabel = this.customLabelString;
    this.eventChange.emit(event);
  }

  updateCounter(isCounterSelected: boolean) {
    if (isCounterSelected) {
      let control = this.formGroup.get(this.formControlNamePass)!.value;

      this.counterSelected = control ? 1 : 0;
    }
  }

  // this function using for normal errors messages
  public isInvalidControl(controlName: string): boolean {
    const control = this.formGroup.get(controlName);
    if (!control) {
      return false;
    }
    return control.invalid && (control.touched || control.dirty);
  }

  public getErrorByField(controlName: string): string[] {
    const errorObj = this.formGroup.get(controlName)?.errors;
    if (!errorObj) {
      return [];
    }
    const errorKeys = Object.keys(errorObj || {});
    if (errorKeys.length === 0) {
      return [];
    }
    const listMsg = errorKeys.reduce((res: string[], key: string) => {
      const msg = this.messageErrors[key];
      res.push(msg);
      return res;
    }, []);
    return listMsg;
  }

  findTypeById(typeId: string): any {
    // Search for the type with the specified type_id
    for (const type of AppConstant.RIG_TYPES) {
      const result = this.searchChildren(type, typeId);
      if (result !== null) {
        return result;
      }
    }
    return null;
  }

  searchChildren(type: any, typeId: string): any {
    if (type.type_id === typeId) {
      return type;
    } else if (type.children) {
      for (const child of type.children) {
        const result = this.searchChildren(child, typeId);
        if (result !== null) {
          return result;
        }
      }
    }
    return null;
  }
}
