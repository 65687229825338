import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cloneDeep, countBy, filter, isEmpty, sortBy } from 'lodash';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { HomeService } from 'src/app/shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Interval, IntervalAndRunRes, Run, WellboreService } from 'src/app/shared/services/wellbore.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { UNIT_SYSTEM } from 'src/app/shared/utilities/app.helper.data';

@Component({
  selector: 'app-interval-and-run-popup',
  templateUrl: './interval-and-run-popup.component.html',
  styleUrls: ['./interval-and-run-popup.component.scss']
})
export class IntervalAndRunPopupComponent implements OnInit {
  private intervalsSubject: BehaviorSubject<Interval[]> = new BehaviorSubject<Interval[]>([]);
  private runsSubject: BehaviorSubject<Run[]> = new BehaviorSubject<Run[]>([]);
  
  intervals$ = this.intervalsSubject.asObservable();
  runs$ = this.runsSubject.asObservable();

  shouldDisableSaveButton$ = combineLatest([this.intervals$, this.runs$]).pipe(
    map(([intervals, runs]) => this.shouldDisableSaveButton(intervals, runs))
  );

  isLoading: boolean = false;
  projectUnits: any = {};
  
  @Input() configuredUnitSystem!: string;
  @Input() wellboreId!: string;
  @Input() projectId!: string;
  @Input() cachedIntervals: any[] = [];
  @Input() cachedRuns: any[] = [];
  @Output() onClickCancel = new EventEmitter<void>();
  @Output() onClickSave = new EventEmitter<any>();  

  constructor(
    private wellboreService: WellboreService,
    private homeService: HomeService,
    private _notificationService: NotificationService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {    
    if (!this.wellboreId) return;
    if (isEmpty(this.cachedIntervals) && isEmpty(this.cachedRuns)) {          
      this.fetchIntervalsAndRuns();
    } else {
      const intervals = sortBy(cloneDeep(this.cachedIntervals), 'intervalName');
      this.intervalsSubject.next(intervals);
      const runs = sortBy(cloneDeep(this.cachedRuns), 'runNo');
      this.runsSubject.next(runs);
    }

    // Get project id    
    if (this.projectId) {
      if (this.configuredUnitSystem) {
        this.projectUnits = AppHelper.MathFunctions.findUnit(this.configuredUnitSystem, UNIT_SYSTEM.unit);
      } else {
        this.homeService.getProjectById(this.projectId).subscribe({
          next: (response) => {  
            const curUnit = response?.data?.curUnit;
            if (curUnit) {
              this.projectUnits = AppHelper.MathFunctions.findUnit(curUnit, UNIT_SYSTEM.unit);
            }          
          },
          error: (error) => {
            error && console.error('Error fetching project by id', error);
          }
        });
      }      
    }
  }

  shouldDisableSaveButton(intervals: Interval[], runs: Run[]): boolean {    
    if (intervals.length === 0 && runs.length) return true;
    const totalSelectedIntervals = filter(intervals, e => e.isSelected).length;
    if (totalSelectedIntervals === 0) return true;
    
    for (const interval of intervals) {    
      if (interval.isSelected && interval.validationError) return true;
    }

    for (const run of runs) {      
      if (run.isSelected && run.validationError) return true;
    }
    return false;
  }

  fetchIntervalsAndRuns(): void {
    this.isLoading = true;
    this.wellboreService.getIntervalAndRunByWellboreId(this.wellboreId).subscribe({
      next: (response: IntervalAndRunRes) => {
        this.isLoading = false;
        const intervals = sortBy(response.intervals, 'intervalName');
        const runs = sortBy(response.runs, 'runNo');     
        this.intervalsSubject.next(intervals);
        this.runsSubject.next(runs);
        this.cdr.detectChanges();   
      },
      error: (error) => {        
        this.isLoading = false;
        error && console.error('Error fetching intervals and runs', error);
      },      
    });
  }

  handleCancel(): void {
    this.onClickCancel?.emit();
  }

  handleSave(): void {
    const intervals = this.intervalsSubject.getValue();
    const runs = this.runsSubject.getValue();
    if (this.shouldDisableSaveButton(intervals, runs)) return;
    const seletedIntervals = filter(intervals, e => e.isSelected);
    const selectedRuns = filter(runs, e => e.isSelected);
    const intervalNameCount = countBy(seletedIntervals, 'intervalName');
    const runNoCount = countBy(selectedRuns, 'runNo');

    const duplicatedIntervalNames = Object.keys(intervalNameCount).filter(intervalName => intervalNameCount[intervalName] > 1);
    const duplicatedRunNos = Object.keys(runNoCount).filter(runNo => runNoCount[runNo] > 1);
    if (!isEmpty(duplicatedIntervalNames) || !isEmpty(duplicatedRunNos)) {
      const messages = [];
      if (!isEmpty(duplicatedIntervalNames)) {
        messages.push(`Duplicate interval names: ${duplicatedIntervalNames.join(', ')}`);
      }
      if (!isEmpty(duplicatedRunNos)) {
        messages.push(`Duplicate run numbers: ${duplicatedRunNos.join(', ')}`);
      }
      for (const message of messages) {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.ERROR,
          header: 'Add Wellbore',
          content: message,
        })
      }      
      return;
    }
    this.onClickSave?.emit({
      intervals,
      runs
    });
  }

  handleIntervalItemSelect(interval: Interval): void {
    if (!interval.edmIntervalId) return;
  }

  handleRunItemSelect(run: Run): void {
    if (!run.edmRunId) return;
    const intervals = this.intervalsSubject.getValue().map(interval => {
      if (interval.edmIntervalId === run.edmIntervalId) {
        interval.isSelected = true;
      }
      return interval;
    });
    this.intervalsSubject.next(intervals);
  }

  handleRunAllSelect(): void {   
    const relevantIntervals = this.runsSubject.getValue().map(run => run.edmIntervalId  );
    const intervals = this.intervalsSubject.getValue().map(interval => {
      if (relevantIntervals.includes(interval.edmIntervalId)) {
        interval.isSelected = true;
      }
      return interval;
    });
    this.intervalsSubject.next(intervals);
  }

  handleIntervalItemUnselect(interval: Interval): void {
    if (!interval.edmIntervalId) return;

    const runs = this.runsSubject.getValue().map(run => {
      if (run.edmIntervalId === interval.edmIntervalId) {
        run.isSelected = false;
      }
      return run;
    });
    this.runsSubject.next(runs);
  }

  handleIntervalAllUnselect(): void {
    const runs = this.runsSubject.getValue().map(run => {
      run.isSelected = false;
      return run;
    });
    this.runsSubject.next(runs);
  }

  handleIntervalAllSelect(): void {
  }

  handleIntervalTableChange(event: any): void {        
    this.intervalsSubject.next(event);
  }

  handleRunTableChange(event: any): void {        
    this.runsSubject.next(event);
  }
}
