import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CustomJoiError } from '../validators/joi-error-formater';

export type Interval = {
  wellboreId: string;
  rigId?: string;
  edmIntervalId: string;
  intervalName: string;
  operation?: string;
  casedHoleId?: string;
  openHoleDiameter: number;
  unit?: string;
  lastShoeMeasuredDepth?: number;
  isNaCaseHoleLastShoe: boolean;
  isNaOpenHole: boolean;
  isSelected?: boolean;
  validationError?: CustomJoiError | null;
}

export type Run = {
  edmIntervalId: string,
  edmRunId: string,
  description: string,
  type: string,
  runNo: number,
  bitSize?: string,
  reamerSize?: string,
  unit?: string,
  casingSize?: number,
  startMeasuredDepth?: number,
  tdMeasuredDepth?: number,
  belowRotaryTimestamp?: number,
  runTdTimestamp?: number,
  aboveRotaryTimestamp?: number,
  isSelected?: boolean;
  validationError?: CustomJoiError | null;
}

export type IntervalAndRunRes = {
  intervals: Interval[];
  runs: Run[];
}

export type ImportResponse = { 
  data: any[], 
  message: string, 
  warningMessages: { message: string }[] 
}

@Injectable({ providedIn: 'root' })
export class WellboreService extends BaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  // public getIntervalAndRunByWellboreId(wellboreId: string): Observable<IntervalAndRunRes> {
  //   // const uri = `wellbore/${wellboreId}/edm-intervals-and-runs`;
  //   // return me.get<IntervalAndRunRes>(uri).pipe();
  //   const mockResponse: IntervalAndRunRes = {
  //     intervals: [
  //       {
  //         wellboreId: '5400005226',
  //         edmIntervalId: 'L3IjE',
  //         intervalName: 'SURFACE',
  //         openHoleDiameter: 26,
  //         isNaCaseHoleLastShoe: true,
  //         isNaOpenHole: true
  //       },
  //       {
  //         wellboreId: '5400005226',
  //         edmIntervalId: 'R4x5X',
  //         intervalName: 'CONDUCTOR',
  //         openHoleDiameter: 36,
  //         isNaCaseHoleLastShoe: true,
  //         isNaOpenHole: true
  //       },
  //       {
  //         wellboreId: '5400005226',
  //         edmIntervalId: 'gFQSx',
  //         intervalName: 'INTERMEDIATE 2',
  //         openHoleDiameter: 14,
  //         isNaCaseHoleLastShoe: true,
  //         isNaOpenHole: true
  //       },
  //       {
  //         wellboreId: '5400005226',
  //         edmIntervalId: 'KFp16',
  //         intervalName: 'PRODUCTION 1',
  //         openHoleDiameter: 8.5,
  //         isNaCaseHoleLastShoe: true,
  //         isNaOpenHole: true
  //       },
  //       {
  //         wellboreId: '5400005226',
  //         edmIntervalId: '9i8Fl',
  //         intervalName: 'INTERMEDIATE 1',
  //         openHoleDiameter: 17.5,
  //         isNaCaseHoleLastShoe: true,
  //         isNaOpenHole: true
  //       }
  //     ],
  //     runs: [
  //       {
  //         edmIntervalId: 'L3IjE',
  //         edmRunId: 'dDji0',
  //         description: 'SURFACE CASING',
  //         runNo: 100,
  //         type: 'Casing',
  //         casingSize: 20
  //       },
  //       {
  //         edmIntervalId: 'R4x5X',
  //         edmRunId: 'w40fp',
  //         description: 'CONDUCTOR',
  //         runNo: 0,
  //         type: 'Casing',
  //         casingSize: 30
  //       },
  //       {
  //         edmIntervalId: 'gFQSx',
  //         edmRunId: 'E434Y',
  //         description: 'PRODUCTION CASING 1',
  //         runNo: 300,
  //         type: 'Casing',
  //         casingSize: 9.625
  //       },
  //       {
  //         edmIntervalId: 'KFp16',
  //         edmRunId: 'hwrap',
  //         description: 'PRODUCTION CASING 2',
  //         runNo: 400,
  //         type: 'Casing',
  //         casingSize: 7
  //       },
  //       {
  //         edmIntervalId: '9i8Fl',
  //         edmRunId: 'YNuH8',
  //         description: 'INTERMEDIATE CASING 1',
  //         runNo: 200,
  //         type: 'Casing',
  //         casingSize: 13.375
  //       }
  //     ]
  //   };

  //   return of(mockResponse).pipe(delay(1000));
  // }

  public getIntervalAndRunByWellboreId(wellboreId: string): Observable<IntervalAndRunRes> {
    const uri = `wellbore/${wellboreId}/edm-intervals-and-runs`;
    return this.get<any>(uri).pipe(
      map(res => res?.data as IntervalAndRunRes)
    );    
  }

  public getIntervalByWellboreId(wellboreId: string): Observable<{ intervals: Interval[] }> {
    const uri = `interval/${wellboreId}/edm-intervals`;
    return this.get<any>(uri).pipe(
      map(res => res?.data as { intervals: Interval[] })
    );    
  }

  public getRunByWellboreId(wellboreId: string): Observable<{ runs: Run[] }> {
    const uri = `run/${wellboreId}/edm-runs`;
    return this.get<any>(uri).pipe(
      map(res => res?.data as { runs: Run[] })
    );    
  }

  public getIntervalByEdmId(wellboreId: string, intevalEdmId: string): Observable<Interval> {
    const uri = `wellbore/${wellboreId}/edm-intervals-and-runs?intervalId=${intevalEdmId}`;
    return this.get<{data: Pick<IntervalAndRunRes, 'intervals'>}>(uri).pipe(
      map(res => {
        return res?.data?.intervals[0] || null;
      })
    );    
  }

  public getRunByEdmId(wellboreId: string, intevalEdmId: string, runEdmId: string): Observable<Run> {
    const uri = `wellbore/${wellboreId}/edm-intervals-and-runs?intervalId=${intevalEdmId}&runId=${runEdmId}`;
    return this.get<{data: Pick<IntervalAndRunRes, 'runs'>}>(uri).pipe(
      map(res => {
        return res?.data?.runs[0] || null;
      })
    );    
  }
}
