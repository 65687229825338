import Joi from "joi";

export type CustomJoiError = { [key: string]: string[] };
export class JoiErrorFormater {
  public static format(error: Joi.ValidationError | undefined | null): CustomJoiError | null | undefined { 
    if (!error || error.details?.length == 0) return null;
    const errors: CustomJoiError = {};
    for (const detail of error.details) {
      const key = detail.path.join('.');
      if (!errors[key]) errors[key] = [];
      errors[key].push(detail.message);
    }    
    return errors;
  }
}