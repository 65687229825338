<p-dialog
  *ngIf="!isShowIntervalRunSelectDialog"
  [(visible)]="displayAddWellboreDialog"
  [modal]="true"
  [style]="{ 'max-width': '480px', 'line-height': '12px' }"
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="false"
  maskStyleClass="maskStyleDialog"
  styleClass="style-class-dialog"
  [closable]="false"
  [baseZIndex]="0"
>
  <ng-template pTemplate="header">
    <div>Add Wellbore</div>
    <div class="custom-close-icon" (click)="removeTheDraft()">
      <i class="pi pi-times"></i>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <div
      class="container add-input edit-form"
      *ngIf="!!formAddWellboreGroup"
      [formGroup]="formAddWellboreGroup"
    >
      <div class="row">
        <div class="col-12 mb-4">
          <app-multi-level-dropdown
            #multiDropdown
            [sourceData]="sourcesUpdate"
            [placeholder]="'Select data source'"
            [showClear]="true"
            scrollHeight="200px"
            [selectAllWhenChooseParent]="true"
            [isCustomTemplate]="true"
            seperateMark="()"
            [isCounterSelected]="false"
            label="Data Source"
            [isRequired]="true"
            [isDisabled]="formAddWellboreGroup.get('source')!.disabled"
            [multiType]="MULTI_LEVEL.SINGLE_SELECT.LOWEST_LEVEL.LABEL"
            [formGroup]="formAddWellboreGroup"
            [formControlNamePass]="fieldNameKeys.source"
            [messageErrors]="messageErrors"
            [customLabelString]="customString || 'Select data source'"
            [customClass]="'custom-p-drop'"
            (eventChange)="selectItem($event, 'source')"
          ></app-multi-level-dropdown>
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('company')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            Company
            <span
              [ngClass]="
                formAddWellboreGroup.get('company')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="companies"
              optionLabel="policyName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select company"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.company"
              (onChange)="selectItem($event, 'company')"
              [innerMsg]="messageErrors"
            >
            </p-dropdown>
          </div>
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('project')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            Project
            <span
              [ngClass]="
                formAddWellboreGroup.get('project')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="projects"
              optionLabel="projectName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select project"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.project"
              (onChange)="selectItem($event, 'project')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('site')!.disabled ? 'label-disabled' : ''
            "
          >
            Site
            <span
              [ngClass]="
                formAddWellboreGroup.get('site')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="sites"
              optionLabel="siteName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select site"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.site"
              (onChange)="selectItem($event, 'site')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('well')!.disabled ? 'label-disabled' : ''
            "
          >
            Well
            <span
              [ngClass]="
                formAddWellboreGroup.get('well')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="wells"
              optionLabel="wellName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select well"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.well"
              (onChange)="selectItem($event, 'well')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('wellbore')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            Wellbore
            <span
              [ngClass]="
                formAddWellboreGroup.get('wellbore')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="wellbores"
              optionLabel="wellboreName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select wellbore"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.wellbore"
              (onChange)="selectItem($event, 'wellbore')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>


        <!-- Option for loading intevals and runs -->
        <div class="col-12 mb-4">
          <div *ngIf="isSelectedProjectHasUnitSystem()" class="flex justify-content-between">
            <div class="d-flex align-items-center">
              <p-checkbox
                class="custom-p-checkbox"
                [binary]="true"
                inputId="checkboxId"
                [disabled]="formAddWellboreGroup.get('importIntervalRunObjects')!.disabled"
                [formControlName]="fieldNameKeys.importIntervalRunObjects"                
              ></p-checkbox>
              <label class="ml-2" 
                for="checkboxId"
                [ngClass]="
                  formAddWellboreGroup.get('importIntervalRunObjects')!.disabled
                    ? 'label-disabled'
                    : ''
                  ">
                Import Interval and Run Objects
              </label>
            </div>
                  
            <div>              
              <p-button
                [attr.data-cy]="'import-interval-run-objects'"
                label="Select"
                class="custom-p-button custom-p-button--secondary"      
                [disabled]="formAddWellboreGroup.get('importIntervalRunObjects')!.disabled || !formAddWellboreGroup.get('importIntervalRunObjects')!.value"
                (onClick)="onClickIntervalRunSelectButton()"
              ></p-button>
            </div>   
          </div>  
          
          <div *ngIf="!isSelectedProjectHasUnitSystem()" class="flex-column">
            <div class="d-flex align-items-center">
              <p-checkbox
                class="custom-p-checkbox"
                [binary]="true"
                inputId="checkboxId"
                [disabled]="formAddWellboreGroup.get('importIntervalRunObjects')!.disabled"
                [formControlName]="fieldNameKeys.importIntervalRunObjects"
                (onChange)="handleImportIntervalRunObjects($event)"
              ></p-checkbox>
              <label class="ml-2" 
                for="checkboxId"
                [ngClass]="
                  formAddWellboreGroup.get('importIntervalRunObjects')!.disabled
                    ? 'label-disabled'
                    : ''
                  ">
                Import Interval and Run Objects
              </label>
            </div>

            <div class="import-interval-run__vertical-container">        
              <div class="col-12 mb-34">
                <div
                  class="add-input-title"
                  [ngClass]="
                    formAddWellboreGroup.get('importIntervalRunObjects')!.disabled || !formAddWellboreGroup.get('importIntervalRunObjects')!.value
                      ? 'label-disabled'
                      : ''
                  "
                >
                  Unit System
                  <span
                    [ngClass]="
                      formAddWellboreGroup.get('importIntervalRunObjects')!.disabled || !formAddWellboreGroup.get('importIntervalRunObjects')!.value
                        ? 'label-disabled'
                        : 'p-error'
                    "
                  >
                    *</span
                  >
                </div>
                <div class="w-100 mb-3">
                  <p-dropdown
                    [resetFilterOnHide]="true"
                    [options]="units"
                    optionLabel="name"
                    [filter]="true"
                    [showClear]="true"
                    placeholder="Select units"
                    class="custom-p-drop"
                    scrollHeight="160px"
                    [formControlName]="fieldNameKeys.unit"
                    (onChange)="selectItem($event, 'unit')"
                    [innerMsg]="messageErrors"

                  ></p-dropdown>
                </div>
              </div>      
              <div>              
                <p-button
                  [attr.data-cy]="'import-interval-run-objects'"
                  label="Select"
                  class="custom-p-button custom-p-button--secondary"      
                  [disabled]="formAddWellboreGroup.get('importIntervalRunObjects')!.disabled || !formAddWellboreGroup.get('importIntervalRunObjects')!.value || !formAddWellboreGroup.get('unit')!.value"
                  (onClick)="onClickIntervalRunSelectButton()"
                ></p-button>
              </div>   
            </div>            
          </div>

          <div *ngIf="hasSelectedIntervalRun()" class="interval-run-summary-container">
            <div class="interval-run-total">{{ getIntervalRunSummary() }}</div>
            <div class="interval-run-list">
              <div *ngFor="let interval of getSelectedIntervals()" class="interval-run-item">       
                <div class="interval-run-item__name">{{ interval.intervalName }}</div>         
                <div class="interval-run-item__control" (click)="handleIntervalRunUnselect(interval, 'interval')"><i class="pi pi-times"></i></div>         
              </div>    
              
              <div *ngFor="let run of getSelectedRuns()" class="interval-run-item">       
                <div class="interval-run-item__name">Run {{ run.runNo }}</div>         
                <div class="interval-run-item__control" (click)="handleIntervalRunUnselect(run, 'run')"><i class="pi pi-times"></i></div>         
              </div>  
            </div>
          </div>            
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('contractor')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            Rig Contractor
            <span
              [ngClass]="
                formAddWellboreGroup.get('contractor')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="contractors"
              optionLabel="contractorName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select contractor"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.contractor"
              (onChange)="selectItem($event, 'contractor')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>

        <div class="col-12">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('assignRig')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            Assign Rig
            <span
              [ngClass]="
                formAddWellboreGroup.get('assignRig')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="assignRigs"
              optionLabel="rigName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select rig"
              class="custom-p-drop custom-rig-dropdown"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.assignRig"
              (onChange)="selectItem($event, 'assignRig')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end gap-2">
      <p-button
        [attr.data-cy]="'cancel-button'"
        label="Cancel"
        (onClick)="removeTheDraft()"
        class="custom-p-button custom-p-button--secondary"
        ></p-button>
      <p-button
        [attr.data-cy]="'add-button'"
        label="Add"
        class="custom-p-button custom-p-button--primary"
        (onClick)="addNewWellbore($event)"
        [disabled]="!formAddWellboreGroup.valid || isViewer"
        [ngClass]="
          formAddWellboreGroup.status === 'INVALID' || isViewer
          ? 'custom-p-button custom-p-button--disable'
          : ''
          "
      ></p-button>
    </div>
  </ng-template>

  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>  
</p-dialog>

<!-- Interval and Run Popup -->
<!-- <app-interval-and-run-popup 
  *ngIf="isShowIntervalRunSelectDialog" 
  (onClickCancel)="onCancelIntervalRunPopup($event)"
  (onClickSave)="onSaveIntervalRunPopup($event)" 
  [cachedIntervals]="intervals"
  [cachedRuns]="runs"
  [wellboreId]="'F76CB12B-5588-4931-892B-FCF4D6F455BE'"
  [projectId]="selectedProject?.projectId"
  ></app-interval-and-run-popup> -->
<app-interval-and-run-popup 
  *ngIf="isShowIntervalRunSelectDialog" 
  (onClickCancel)="onCancelIntervalRunPopup($event)"
  (onClickSave)="onSaveIntervalRunPopup($event)" 
  [cachedIntervals]="intervals"
  [cachedRuns]="runs"
  [wellboreId]="selectedWellboreId"
  [projectId]="selectedProject?.projectId"
  [configuredUnitSystem]="formAddWellboreGroup.value?.unit?.code"
  ></app-interval-and-run-popup>  