import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { countBy, filter, isEmpty, sortBy } from 'lodash';
import { BehaviorSubject, catchError, finalize, forkJoin, Subject, takeUntil } from 'rxjs';
import { MODULE_NAME, USER_PERMISSION } from 'src/app/shared/enum';
import { HomeService } from 'src/app/shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { ImportResponse, IntervalAndRunRes, Run, WellboreService } from 'src/app/shared/services/wellbore.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { UNIT_SYSTEM } from 'src/app/shared/utilities/app.helper.data';
import { JoiErrorFormater } from 'src/app/shared/validators/joi-error-formater';
import { RunValidator } from 'src/app/shared/validators/run.validator';

@Component({
  selector: 'app-run-popup',
  templateUrl: './run-popup.component.html',
  styleUrls: ['./run-popup.component.scss']
})
export class RunPopupComponent implements OnInit {
  private runsSubject: BehaviorSubject<Run[]> = new BehaviorSubject<Run[]>([]);
  public shouldDisableSaveButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private destroy$ = new Subject<void>();
  
  runs$ = this.runsSubject.asObservable();
  runTypeList: any[] = [];

  isLoading: boolean = false;
  projectUnits: any = {};
  canAdd: boolean = false;
  canEdit: boolean = false;

  @Input() wellboreId!: string;
  @Input() projectId!: string;
  @Input() rigId!: string;
  @Input() intervalId!: string;
  @Input() edmIntervalId: string = '';

  @Input() existedRuns: any[] = [];


  @Output() onClickCancel = new EventEmitter<void>();
  @Output() onClickSave = new EventEmitter<any>();
  @Output() onReload = new EventEmitter<any>();
  
  constructor(
    private homeService: HomeService,    
    private wellboreService: WellboreService,
    private notificationService: NotificationService,
    private userInfoService: UserInfoService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {    
    this.runTypeList = AppConstant.TYPES;


    this.canAdd = this.userInfoService.hasPermission(
      MODULE_NAME.RUN,
      USER_PERMISSION.ADD
    );

    this.canEdit = this.userInfoService.hasPermission(
      MODULE_NAME.RUN,
      USER_PERMISSION.EDIT
    );

    
    if (this.canAdd && this.canEdit)
      this.fetchRuns(this.wellboreId, this.projectId);
  }

  shouldDisableSaveButton(runs: Run[]): boolean {
    if (runs.length === 0) return true;
    const totalSelectedRuns = filter(runs, e => e.isSelected).length;
    if (totalSelectedRuns === 0) return true;
    
    for (const run of runs) {
      if (!run.validationError) {
        run.validationError = JoiErrorFormater.format(RunValidator.validate(run, { abortEarly: false })?.error);
      }
      if (run.isSelected && run.validationError) return true;
    }
    return false;
  }

  fetchRuns(wellboreId: string, projectId: string): void {
    if (!wellboreId || !projectId) {
      this.notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.ERROR,
        header: 'Import Runs',
        content: 'Wellbore ID or Project ID is missing.'
      })
      return;
    }
    
    this.isLoading = true;
    forkJoin([
      this.wellboreService.getRunByWellboreId(this.wellboreId),
      this.homeService.getProjectById(this.projectId)
    ]).pipe(
      catchError(AppHelper.UtileFunctions.handleError),
      takeUntil(this.destroy$),
      finalize(() => {
        this.isLoading = false,
        this.cdr.detectChanges();
      })
    ).subscribe({
      next: ([intervalAndRunResponse, projectResponse]: [{ runs: Run[] }, any]) => {
        // Run Response Handle
        intervalAndRunResponse.runs = intervalAndRunResponse.runs.filter(run => 
          !this.existedRuns.some(existedRun => existedRun.edmRunId === run.edmRunId || existedRun.runNo.toString() === run.runNo.toString())
        );

        const runs = sortBy(intervalAndRunResponse.runs.filter(run => run.edmIntervalId === this.edmIntervalId), 'runNo');

        this.runsSubject.next(runs);

        // Project Response Handle
        const curUnit = projectResponse?.data?.curUnit;
        if (curUnit) {
          this.projectUnits = AppHelper.MathFunctions.findUnit(curUnit, UNIT_SYSTEM.unit);
        } 

      },
      error: (error) => {        
        error && console.error('Error fetching runs or project by id', error);
      },   
    });
  }

  handleCancel(): void {
    this.onClickCancel?.emit();
  }

  handleSave(): void {
    if (this.shouldDisableSaveButton$.getValue()) {
      return;
    }

    const runs = this.runsSubject.getValue();
    
    let seletedRuns = filter(runs, e => e.isSelected);
    const runNoCount = countBy(seletedRuns, 'runNo');

    const duplicatedRunNos = Object.keys(runNoCount).filter(runNo => runNoCount[runNo] > 1);
    if (!isEmpty(duplicatedRunNos)) {
      const messages = [];
      if (!isEmpty(duplicatedRunNos)) {
        messages.push(`Duplicate run names: ${duplicatedRunNos.join(', ')}`);
      }
      for (const message of messages) {
        this.notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.ERROR,
          header: 'Import Run',
          content: message,
        })
      }      
      return;
    }

    // Handle save
    if (seletedRuns.length > 0) {
      this.isLoading = true;

      // Convert type of run to type_id
      seletedRuns = seletedRuns.map((run: any) => {
        return {
          ...run,
          type: this.runTypeList.find(type => type.data === run.type)?.type_id
        }
      });

      const payload = {
        projectId: this.projectId,
        wellboreId: this.wellboreId,
        rigId: this.rigId,
        intervalId: this.intervalId,
        runs: seletedRuns
      }

      this.homeService
        .addRuns(payload)
        .pipe(
          catchError(AppHelper.UtileFunctions.handleError),
          takeUntil(this.destroy$),
          finalize(() => this.isLoading = false)
        )
        .subscribe({
          next: (response: ImportResponse) => {
            const isComplete: boolean = this.handleResponse(response);
            if (isComplete) {
              this.notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Add Runs',
                content: 'Runs was imported successfully!',
              });

              this.onClickSave.emit(true);
              this.onReload.emit(true);
            }
          },
          error: (error) => {
            console.error(error);
            this.notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Add Runs',
              content: error?.message || error,
            });
          },
        });
    }
  }

  handleResponse(response: ImportResponse): boolean {
    if (response.warningMessages.length > 0) {
      for (const message of response.warningMessages) {
        this.notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Add Runs',
          content: message.message,
        });
      }
      return false;
    }
    return true;
  }

  handleRunItemSelect(run: Run): void {
    if (!run.edmRunId) return;
    this.handleRow();
  }

  handleRunItemUnselect(run: Run): void {
    if (!run.edmRunId) return;
    this.handleRow();
  }

  handleRunAllUnselect(): void {
    this.handleRow();
  }

  handleRunAllSelect(): void {
    this.handleRow();
  }

  handleRunTableChange(event: any): void {        
    this.runsSubject.next(event);
    this.handleRow();
  }

  handleRow(): void {
    const runs = this.runsSubject.getValue();
    this.shouldDisableSaveButton$.next(this.shouldDisableSaveButton(runs));
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
