import { ExtendInfoComponent } from './../pages/notification/extendInfo/extendInfo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../pages/error/error.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeUserInformationComponent } from './../pages/home/home-user-information/home-user-information.component';
import { IconsModule } from '../../assets/images/icons/icons.module';
import { InnerMsgDirective } from '../shared/directives/inner-msg.directive';
import { BlobImageDirective, BlobFileDirective } from '../shared/directives/blob.directive';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { NumberDegree } from './directives/number-degree.directive';
import { NumbersOnly } from './directives/number-only.directive';
import { TextInputValidate } from './directives/text-input-validate.directive';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { blockSpecialCharacter } from './directives/block-special-character.directive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DragDirective } from './directives/drag-drop.directive';
import { FormControlModule } from './components/form-control/form-control.module';
import { ModuleHeaderComponent } from './components/module-header/module-header.component';
import { PhotoCropperComponent } from './components/photo-cropper/photo-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LoadingModule } from './components/loading/loading.module';
import { HomeDistributionListComponent } from '../pages/home/home-distribution-list/home-distribution-list.component';
import { HomeEditProjectComponent } from '../pages/home/home-edit-project/home-edit-project.component';
import { HomeFormRigComponent } from '../pages/home/home-form-rig/home-form-rig.component';
import { HomeAddWellboreComponent } from '../pages/home/home-add-wellbore/home-add-wellbore.component';
import { NotificationCenterComponent } from './components/notification-center/notification-center.component';

import {
  DatePipeModule,
  TimePipeModule,
  StringPipeModule,
  MathPipeModule,
  UtilePipeModule,
} from './pipes';
import { primeNgModules } from './import/primeNG';
import { ExpandableContentDirective } from './directives/expandable-content.directive';
import { RandomAvatarDirective } from './directives/random-value/random-avatar.directive';
import { CustomExpandDialogComponent } from './components/custom-expand-dialog/custom-expand-dialog.component';
import { AppMenuComponent } from '../pages/home/app-menu/app-menu.component';
import { PositiveDecimalInputDirective } from './directives/positiveDecimalInput.directive';
import { CustomizableButtonComponent } from './components/customizable-button/customizable-button.component';
import { CustomExpandInforComponent } from './components/custom-expand-infor/custom-expand-infor.component';
import { TutorialCenterComponent } from './components/tutorial-center/tutorial-center.component';
import { TutorialDetailComponent } from './components/tutorial-detail/tutorial-detail.component';
import { ExpandImgComponent } from './components/expand-img/expand-img.component';
import { ExportSettingComponent } from './components/export-setting/export-setting.component';
import { NotificationSettingComponent } from './components/notification-setting/notification-setting.component';
import { AlertService } from './services/alert.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConnectMsTeamComponent } from './components/connect-ms-team/connect-ms-team.component';
import { IntervalAndRunPopupComponent } from '../pages/home/home-add-wellbore/components/interval-and-run-popup/interval-and-run-popup.component';
import { AppTableEditComponent } from '../pages/home/app-table-edit/app-table-edit.component';
import { EditableIntervalTableComponent } from '../pages/home/home-add-wellbore/components/editable-interval-table/editable-interval-table.component';
import { EditableRunTableComponent } from '../pages/home/home-add-wellbore/components/editable-run-table/editable-run-table.component';
import { IntervalPopupComponent } from '../pages/home/home-add-wellbore/components/interval-popup/interval-popup.component';
import { RunPopupComponent } from '../pages/home/home-add-wellbore/components/run-popup/run-popup.component';

// Add shared primeNG modules here (ex: ButtonModule ...)

const declarations: any[] = [
  HeaderComponent,
  SidebarComponent,
  FooterComponent,
  ErrorComponent,
  ModuleHeaderComponent,
  InnerMsgDirective,
  BlobImageDirective,
  BlobFileDirective,
  NumbersOnly,
  TextInputValidate,
  ExpandableContentDirective,
  NumberDegree,
  blockSpecialCharacter,
  HomeUserInformationComponent,
  ExtendInfoComponent,
  ConfirmDialogComponent,
  DragDirective,
  PhotoCropperComponent,
  HomeDistributionListComponent,
  HomeEditProjectComponent,
  HomeFormRigComponent,
  IntervalAndRunPopupComponent,
  HomeAddWellboreComponent,
  RandomAvatarDirective,
  CustomExpandDialogComponent,
  AppMenuComponent,
  PositiveDecimalInputDirective,
  NotificationCenterComponent,
  CustomizableButtonComponent,
  CustomExpandInforComponent,
  TutorialCenterComponent,
  TutorialDetailComponent,
  ExpandImgComponent,
  ExportSettingComponent,
  NotificationSettingComponent,
  ConnectMsTeamComponent,  
  AppTableEditComponent,
  EditableIntervalTableComponent,
  EditableRunTableComponent,
  IntervalPopupComponent,
  RunPopupComponent,
];

const imports = [
  CommonModule,
  IconsModule,
  FormsModule,
  ReactiveFormsModule,
  MatIconModule,
  ImageCropperModule,
  FormControlModule,
  LoadingModule,

  ...primeNgModules,

  DatePipeModule,
  TimePipeModule,
  StringPipeModule,
  MathPipeModule,
  UtilePipeModule,
];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  exports: [...imports, ...declarations],
  providers: [AlertService, DialogService]
})
export class ShareModule {}
