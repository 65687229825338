import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket, connect } from 'socket.io-client'
import { environment } from 'src/environments/environment';
import { ConfirmDialogService } from './confirm-dialog.service';
import { OptionButtonType } from '../type';
import { AppConstant } from '../utilities/app.constant';


@Injectable({
  providedIn: 'root'
})
export class WebSocketService implements OnDestroy {
  socket: Socket;
  retryCount: number = 0;
  maxRetries: number = 5;
  timerRetry: number = 10000;
  timeOutRetry: any;
  isRestored: boolean = false;

  handlers: { [messageType: string]: Function[] } = {};
  eventListeners: { [eventName: string]: Function[] } = {};

  constructor(
    public _confirmService: ConfirmDialogService,
  ) {
  }

  ngOnDestroy(): void {
    if (this.socket?.connected)
      this.socket?.disconnect();
  }

  connectSocket() {
    const token = localStorage.getItem('token');
    if (!token) return;
    console.log("Connecting WebSocket server: ", token);
    if (this.socket && this.socket.connected) return;

    this.socket = connect(environment.apiUrl, {
      auth: { token },
      reconnection: true,
    });

    this.socket.on('connect', () => {
      clearTimeout(this.timeOutRetry);
      console.log("Connect success WebSocket server");
    });

    this.socket.on('connect_error', () => {
      console.log('Connect_error WebSocket server');
      this.handleRetryConnectSocket();
    });
    
    this.socket.on('connect_timeout', () => {
      console.log('Connect_timeout WebSocket server');
      this.handleRetryConnectSocket();
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
      this.handleRetryConnectSocket();
    });
  }

  handleRetryConnectSocket() {
    if (this.socket.connected) return;
    if (this.retryCount >= this.maxRetries) {
      clearTimeout(this.timeOutRetry);
      this.handleMaxRetriesExceeded();
      return;
    }
    console.log("retry connect Socket", this.retryCount + 1);

    this.retryConnect();
    this.timeOutRetry = setTimeout(() => this.handleRetryConnectSocket(), this.timerRetry);
  }

  retryConnect() {
    this.socket.connect();
    this.retryCount++;
  }

  handleMaxRetriesExceeded() {
    const onButtonEvent = (option: OptionButtonType) => {
      switch (option) {
        case AppConstant.OPTION_BUTTON.YES:
          window.location.reload();
          break;
        case AppConstant.OPTION_BUTTON.CANCEL:
        case AppConstant.OPTION_BUTTON.NO:
        default:
          this._confirmService.clearDialog();
          break;
      }
    };
    
    if (this._confirmService.dataSubject.value?.isVisible) return;

    if (!this._confirmService.dataSubject.value?.isVisible)
      this._confirmService.setDialog({
        isVisible: true,
        header: `Warning`,
        haveCheckbox: false,
        haveDialogMessage: true,
        dialogMessage:
          'Inactive page in long time, Please reload page to refresh page!',
        havePrimaryButton: true,
        primaryButtonLabel: 'Confirm',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: false,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          onButtonEvent(event),
     });
  }

  listen(eventName: string): Observable<any> {
    return new Observable((subscribe: any) => {
      // listen after socket object initialized
      if (!this.socket) {
        if (!this.eventListeners[eventName]) {
          this.eventListeners[eventName] = [];
        }
        this.eventListeners[eventName].push(subscribe);

        // intervally check if socket is initialized, then init
        const interval = setInterval(() => {
          if (this.socket) {
            clearInterval(interval);
            this.socket.on(eventName, (data: any) => {
              this.eventListeners[eventName].forEach((sub: any) => {
                sub.next(data);
              });
            });
          }
        }, 1000);
      } else {
        this.socket.on(eventName, (data: any) => {
          subscribe.next(data);
        });
      }      
      
    })
  }

  unlisten(eventName: string): Observable<any> {
    return new Observable((subcribe) => {
      // temporarily fix the issue with socket.off
      // console.log(`Unlistening tco event: ${eventName}`);
      this.socket.removeAllListeners(eventName);
    })
  }

  emit(eventName: string, data: any): any {
    this.socket.emit(eventName, data)
  }

  registerMessageHanlder(messageType: WS_MESSAGE_TYPE, handler: Function): void {
    if (!this.handlers[messageType]) {
      this.handlers[messageType] = [];
    }
    this.handlers[messageType].push(handler);
    // console.log(`after registerMessageHanlder: ${messageType} - totalHandlers: ${this.handlers[messageType].length}`);
  }

  unregisterMessageHanlder(messageType: WS_MESSAGE_TYPE, handler: Function): void {
    // console.log(`before unregisterMessageHanlder: ${messageType}`);
    if (this.handlers[messageType]) {
      this.handlers[messageType] = this.handlers[messageType].filter((h) => h !== handler);
      // console.log(`after unregisterMessageHanlder: ${messageType} - totalHandlers: ${this.handlers[messageType].length}`);
    }
  }

  handleMessage(message: WebSocketMessage): void {
    const { type: messageType, data } = message;
    if (this.handlers[messageType]) {
      this.handlers[messageType].forEach((handler) => {
        handler(data);
      });
    }
  }
}

export enum WS_MESSAGE_TYPE {
  LIVE_SUPPORT_AGENT__NEW_MESSAGE = 'liveSupportAgent/newMessage',
  LIVE_SUPPORT_AGENT__NEW_SUPPORT_CASE = 'liveSupportAgent/newSupportCase',
  LIVE_SUPPORT_AGENT__UPDATE_SUPPORT_CASE = 'liveSupportAgent/updateSupportCase',
  LIVE_SUPPORT_AGENT__READ_ALL = 'liveSupportAgent/readall',
  LIVE_SUPPORT_AGENT__TYPING = 'liveSupportAgent/typing',

  USER__NEW_MESSAGE = 'user/newMessage',
  USER__UPDATE_UNREAD_MESSAGE = 'user/updateUnreadMessage',
  USER__READ_ALL = 'user/readall',
  USER__TYPING = 'user/typing',

  CHATBOT__TYPING = 'chatbot/typing',
}


export interface WebSocketMessage {
  type: WS_MESSAGE_TYPE;
  data: any;
}
